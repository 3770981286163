import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgrxFormsModule } from 'ngrx-forms';
import { NgxEchartsModule } from 'ngx-echarts';
import { WebcamModule } from 'ngx-webcam';
import { CommonMaterialModule } from '../../../common-material/src/lib/common-material.module';
import { InfoBarComponent } from '../core-lib/components/info-bar/info-bar.component';
import { CoreLibModule } from '../core-lib/core-lib.module';
import { environment } from '../environments/environment';
import { FormsLibModule } from '../forms-lib/forms-lib.module';
import { SessionModule } from '../session/session.module';
import { AccountDeputiesComponent } from './components/account-deputies/account-deputies.component';
import { CateringTableComponent } from './components/catering-table/catering-table.component';
import { CircleRadarChartComponent } from './components/circle-radar-chart/circle-radar-chart.component';
import { CommonAppComponent } from './components/common-app/common-app.component';
import { IndexComponent } from './components/index/index.component';
import { MainScreenComponent } from './components/main-screen/main-screen.component';
import { PageBreadCrumbsNavComponent } from './components/page-bread-crumbs-nav/page-bread-crumbs-nav.component';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { PageHeaderGreetingComponent } from './components/page-header-greeting/page-header-greeting.component';
import { PageHeaderLogoComponent } from './components/page-header-logo/page-header-logo.component';
import { PageHeaderQuickLinksComponent } from './components/page-header-quick-links/page-header-quick-links.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageNavbarItemComponent } from './components/page-navbar-item/page-navbar-item.component';
import { PageNavbarComponent } from './components/page-navbar/page-navbar.component';
import { PageSidebarComponent } from './components/page-sidebar/page-sidebar.component';
import { ProposalTableComponent } from './components/proposal-table/proposal-table.component';
import { RouteAccountDataComponent } from './components/route-account-data/route-account-data.component';
import { RouteApprovalOverviewComponent } from './components/route-approval-overview/route-approval-overview.component';
import { RouteCateringOverviewComponent } from './components/route-catering-overview/route-catering-overview.component';
import { RouteConfigArticleListComponent } from './components/route-config-article-list/route-config-article-list.component';
import { RouteConfigCategoryInfoComponent } from './components/route-config-category-info/route-config-category-info.component';
import {
  RouteConfigCateringOrderDataComponent,
} from './components/route-config-catering-order-data/route-config-catering-order-data.component';
import { RouteConfigCompaniesComponent } from './components/route-config-companies/route-config-companies.component';
import { RouteConfigCompanyComponent } from './components/route-config-company/route-config-company.component';
import { RouteConfigDashboardComponent } from './components/route-config-dashboard/route-config-dashboard.component';
import { RouteConfigDeputiesComponent } from './components/route-config-deputies/route-config-deputies.component';
import { RouteConfigFaqComponent } from './components/route-config-faq/route-config-faq.component';
import {
  RouteConfigFormPropertiesContainerComponent,
} from './components/route-config-form-properties-container/route-config-form-properties-container.component';
import { RouteConfigFormPropertyComponent } from './components/route-config-form-property/route-config-form-property.component';
import { RouteConfigFormComponent } from './components/route-config-form/route-config-form.component';
import { RouteConfigFormsAddComponent } from './components/route-config-forms-add/route-config-forms-add.component';
import { RouteConfigFormsComponent } from './components/route-config-forms/route-config-forms.component';
import {
  RouteConfigLedgerAccountListComponent,
} from './components/route-config-ledger-account-list/route-config-ledger-account-list.component';
import {
  RouteConfigLocationSelectionComponent,
} from './components/route-config-location-selection/route-config-location-selection.component';
import {
  RouteConfigMassDataProcessingComponent,
} from './components/route-config-mass-data-processing/route-config-mass-data-processing.component';
import { RouteConfigQueueComponent } from './components/route-config-queue/route-config-queue.component';
import { RouteConfigRoomComponent } from './components/route-config-room/route-config-room.component';
import { RouteConfigSalutationsComponent } from './components/route-config-salutations/route-config-salutations.component';
import { RouteConfigServiceCenterComponent } from './components/route-config-service-center/route-config-service-center.component';
import { RouteConfigSystemMessageComponent } from './components/route-config-system-message/route-config-system-message.component';
import { RouteConfigTaxRatesComponent } from './components/route-config-tax-rates/route-config-tax-rates.component';
import { RouteConfigComponent } from './components/route-config/route-config.component';
import { RouteFormOverviewComponent } from './components/route-form-overview/route-form-overview.component';
import { RouteFormsComponent } from './components/route-forms/route-forms.component';
import { RouteIndexComponent } from './components/route-index/route-index.component';
import { RouteOcrComponent } from './components/route-ocr/route-ocr.component';
import { RouteTemplateDeputyComponent } from './components/route-template-deputy/route-template-deputy.component';
import { RouteTemplateEditorComponent } from './components/route-template-editor/route-template-editor.component';
import { UserDeputiesComponent } from './components/user-deputies/user-deputies.component';
import { AppConfigGuard } from './guards/app-config.guard';
import { GeneralAdminGuard } from './guards/general-admin.guard';
import { LoginGuard } from './guards/login.guard';
import { NoDirectAccessGuard } from './guards/no-direct-access.guard';
import { RoleBasedGuard } from './guards/role-based.guard';
import { ValidIdGuard } from './guards/valid-id.guard';
import { CoreEffectsBundle } from './ngrx/effects/core-effects-bundle';
import { RouterEffects } from './ngrx/effects/router.effects';
import * as fromCore from './ngrx/reducers/core.store';
import { AppRoutingModule } from './routes/app-routing.module';
import { AppConfigService, appConfigServiceFactory } from './services/app-config.service';
import { actionSanitizer, stateSanitizer } from './utils/state-sanitizer';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    IndexComponent,
    RouteIndexComponent,
    RouteFormOverviewComponent,
    RouteTemplateEditorComponent,
    PageHeaderComponent,
    PageFooterComponent,
    PageHeaderGreetingComponent,
    PageHeaderLogoComponent,
    PageNavbarComponent,
    PageHeaderQuickLinksComponent,
    PageNavbarItemComponent,
    MainScreenComponent,
    InfoBarComponent,
    RouteConfigComponent,
    RouteApprovalOverviewComponent,
    RouteAccountDataComponent,
    RouteConfigCompanyComponent,
    RouteConfigCompaniesComponent,
    RouteConfigTaxRatesComponent,
    RouteConfigLedgerAccountListComponent,
    RouteConfigSalutationsComponent,
    RouteFormsComponent,
    RouteConfigFormsComponent,
    RouteConfigFormComponent,
    RouteConfigFormsAddComponent,
    PageSidebarComponent,
    PageBreadCrumbsNavComponent,
    ProposalTableComponent,
    RouteConfigFormPropertyComponent,
    RouteConfigFormPropertiesContainerComponent,
    AccountDeputiesComponent,
    UserDeputiesComponent,
    RouteConfigDeputiesComponent,
    RouteConfigSystemMessageComponent,
    RouteConfigLocationSelectionComponent,
    RouteConfigRoomComponent,
    RouteConfigArticleListComponent,
    RouteCateringOverviewComponent,
    CateringTableComponent,
    RouteConfigCateringOrderDataComponent,
    CommonAppComponent,
    RouteConfigFaqComponent,
    RouteConfigMassDataProcessingComponent,
    RouteConfigServiceCenterComponent,
    RouteConfigCategoryInfoComponent,
    RouteOcrComponent,
    RouteTemplateDeputyComponent,
    RouteConfigDashboardComponent,
    CircleRadarChartComponent,
    RouteConfigQueueComponent,
  ],
  imports: [
    CoreLibModule,
    StoreRouterConnectingModule.forRoot({serializer: FullRouterStateSerializer}),
    StoreModule.forRoot({router: routerReducer}, {runtimeChecks: {strictStateImmutability: false, strictActionImmutability: false}}),
    EffectsModule.forRoot([]),
    environment.production ? [] : StoreDevtoolsModule.instrument({
      logOnly: true,
      maxAge: 2,
      name: 'easy',
      actionSanitizer: actionSanitizer,
      stateSanitizer: stateSanitizer,
    }),
    NgrxFormsModule,
    StoreModule.forFeature('core', fromCore.reducer),
    EffectsModule.forFeature([RouterEffects, ...CoreEffectsBundle]),
    AppRoutingModule,
    SessionModule,
    CommonMaterialModule,
    NgxDatatableModule,
    FormsLibModule,
    WebcamModule,
    LayoutModule,
    NgxEchartsModule,
  ],
  exports: [
    IndexComponent,
    CommonAppComponent,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    LoginGuard,
    GeneralAdminGuard,
    RoleBasedGuard,
    NoDirectAccessGuard,
    AppConfigGuard,
    ValidIdGuard,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigServiceFactory,
      multi: true,
      deps: [AppConfigService],
    },
  ],
})
export class CoreModule {
}
