import { Component, Input } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Boxed, FormControlState } from 'ngrx-forms';
import { ConfigItemTextInputConfigurationDtoModel } from '../../../core-lib/models/config-item-text-input-configuration-dto.model';
import { SetValueTraceableAction } from '../../../core-lib/models/set-value-traceable-action';
import { AccountFormMappingModel } from '../../../core/models/account-form-mapping.model';
import { NetRegionModel } from '../../../core/models/net-region.model';
import { ServiceCenterModel } from '../../../core/models/service-center.model';
import { formBaseSelectors } from '../../../forms/form-base/ngrx/selectors';
import { SapInfoModel } from '../../models/sap-info.model';

@Component({
  selector: 'lib-common-region-service-center-selector',
  templateUrl: './region-service-center-selector.component.html',
  styleUrls: ['./region-service-center-selector.component.scss'],
})
export class RegionServiceCenterSelectorComponent {
  private netRegions: NetRegionModel<AccountFormMappingModel>[] = [];
  private _ngrxNetRegion: FormControlState<string>;

  @Input()
  get options(): NetRegionModel<AccountFormMappingModel>[] {
    return this.netRegions;
  }

  set options(value: NetRegionModel<AccountFormMappingModel>[]) {
    this.netRegions = value;

    if (!this.netRegions || this.netRegions.length === 0) {
      if (this.ngrxNetRegion && this.ngrxServiceCenter) {
        this.store$.dispatch(new SetValueTraceableAction(this.ngrxServiceCenter?.id, undefined));
        this.store$.dispatch(new SetValueTraceableAction(this.ngrxNetRegion?.id, undefined));
      }
      return;
    }

    this.serviceCenters = this.netRegions?.find(o => o.name === this.ngrxNetRegion?.value)?.serviceCenters;

    if (!this.serviceCenters) {
      if (this.ngrxServiceCenter) {
        this.store$.dispatch(new SetValueTraceableAction(this.ngrxServiceCenter?.id, undefined));
      }

      return;
    }

    this.store$.dispatch(new SetValueTraceableAction(
      this.ngrxAccount?.id,
      this.serviceCenters.find(sc => sc.name === this.ngrxServiceCenter?.value)
        ?.accountFormMappings
        ?.find(afm => afm.formIdentifier === this.formIdentifier)?.account,
    ));
  }

  @Input()
  get ngrxNetRegion(): FormControlState<string> {
    return this._ngrxNetRegion;
  }

  set ngrxNetRegion(value: FormControlState<string>) {
    this._ngrxNetRegion = value;
    this.serviceCenters = this.netRegions?.find(o => o.name === this.ngrxNetRegion?.value)?.serviceCenters;
  }

  @Input() ngrxServiceCenter: FormControlState<string>;
  @Input() ngrxAccount: FormControlState<Boxed<SapInfoModel>>;
  @Input() netRegionEasyFieldConfig: string;
  @Input() serviceCenterEasyFieldConfig: string;
  @Input() formIdentifier: string = this.route.snapshot.data.formIdentifier;
  @Input() featureName: string = this.route.snapshot.data.featureName;

  netRegionConfig: ConfigItemTextInputConfigurationDtoModel;
  serviceCenterConfig: ConfigItemTextInputConfigurationDtoModel;
  serviceCenters: ServiceCenterModel<AccountFormMappingModel>[];
  selectedCompany$ = this.store$.select(formBaseSelectors.getFormBaseStep1CompanyResolvedState, {featureName: this.featureName});

  constructor(
    protected store$: Store<unknown>,
    protected route: ActivatedRoute,
  ) {
  }

  selectNetRegion($event: MatSelectChange) {
    this.serviceCenters = this.netRegions.find(o => o.name === $event.value)?.serviceCenters;
  }

  updateNetRegionConfig(config: ConfigItemTextInputConfigurationDtoModel) {
    setTimeout(() => {
      this.netRegionConfig = config;
    });
  }

  updateServiceCenterConfig(config: ConfigItemTextInputConfigurationDtoModel) {
    setTimeout(() => {
      this.serviceCenterConfig = config;
    });
  }

  emitAccount($event: MatSelectChange) {
    this.store$.dispatch(new SetValueTraceableAction(
      this.ngrxAccount?.id,
      this.serviceCenters.find(sc => sc.name === $event.value)
        ?.accountFormMappings
        ?.find(afm => afm.formIdentifier === this.formIdentifier)?.account,
    ));
  }

}
