import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Dictionary } from '@ngrx/entity';
import { FormControlState, NgrxValueConverter, ValidationErrors } from 'ngrx-forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormCompaniesDialogDataAtomModel } from '../form-companies-dialog/form-companies-dialog-data-atom.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent implements OnInit, AfterViewInit {
  private _selectState: FormControlState<any>;

  get selectState(): FormControlState<any> {
    return this._selectState;
  }

  @Input()
  set selectState(value: FormControlState<any>) {
    this._selectState = value;
    if (value) {
      this.stateErrors = value.errors;
      this.stateErrorKeys = Object.keys(this.stateErrors);
    }
  }

  elementFilterCtrl = new FormControl();
  filteredElements = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  currentOptions: any[] = [];

  stateErrors: Dictionary<string> = {};
  stateErrorKeys: string[] = [];

  @ViewChild('singleSelect')
  singleSelect: MatSelect;

  @Input() required: boolean;
  @Input() placeholderI18nKey: string;
  @Input() flex: string;
  @Input() searching = false;
  @Input() configName: string;
  @Input() errors: string[] = [];
  @Input() valueConverter = {
    convertViewToStateValue: viewValue => this.getElementId(viewValue),
    convertStateToViewValue: stateValue => this.currentOptions.find(o => this.getElementId(o) === stateValue),
  } as NgrxValueConverter<{ id: string }, string>;

  errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean => {
      return this._selectState && this._selectState.isInvalid && this._selectState.isTouched;
    },
  };
  model: any;

  @Input() disabledOverride: boolean;
  @Input() getElementId: (e: any) => string = (e) => e ? e.id : '';
  @Input() getDisplayText: (e: any) => any = (e) => e ? (e.name + ' - ' + e.companyId) : '';
  @Input() trackById = (element) => element.id;

  @Input() set options(options: any[]) {
    if (Array.isArray(options) && options.length > 0) {
      this.currentOptions = options;
      this.filterElements(); // Filter elements in case a filter is set when new options are set
    }
  }

  ngOnInit() {
    // load the initial option list
    this.filteredElements.next(this.currentOptions);

    // listen for search field value changes
    this.elementFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterElements();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  /**
   * Sets the initial value after the filteredElements are loaded initially
   */
  protected setInitialValue() {
    this.filteredElements
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredElements are loaded initially
        // and after the mat-option elements are available
        if (this.hasOptions() && this.singleSelect) {
          this.singleSelect.compareWith = (a: FormCompaniesDialogDataAtomModel, b: FormCompaniesDialogDataAtomModel) =>
            a && b && this.getElementId(a) === this.getElementId(b);
        }
      });
  }

  protected filterElements() {
    if (!this.currentOptions) {
      return;
    }
    // get the search keyword
    const search = this.elementFilterCtrl.value;
    if (!search) {
      this.filteredElements.next(this.currentOptions.slice());
      return;
    }
    // filter the elements
    this.filteredElements.next(
      this.currentOptions.filter(e => this.getDisplayText(e).toLowerCase().indexOf(search.toLowerCase()) > -1),
    );
  }

  hasOptions() {
    return Array.isArray(this.currentOptions) && this.currentOptions.length > 0;
  }

}
