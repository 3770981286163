import { FormGroupState, StateUpdateFns, updateArray, updateGroup, validate, ValidationErrors, ValidationFn } from 'ngrx-forms';
import { UserSearchModel } from '../../core-lib/models/user-search.model';
import { safeUnbox } from '../../core-lib/utils/safe-unbox';
import { ArrangerIdModel } from '../../forms/all-forms/models/arranger-dto.model';
import { FormBaseState } from '../models/base-state.model';
import { FormParticipantModel } from '../models/form-participant.model';

export function createApproverIsParticipantValidator(participants: FormParticipantModel[], hasParticipants: boolean):
  (string) => ValidationErrors {
  return value => setError(
    hasParticipants && participants.find(p => p.isEmployee && safeUnbox(p.user)?.userId === value) === undefined,
    'noParticipant',
    'no',
  );
}

export const defaultApproverValidators = <T extends FormBaseState>(validateFormField, form: FormGroupState<T>) => [
  validateFormField('approver'),
  notUser(safeUnbox(form.value.step2.recipient), 'true', 'recipient'),
  notIncludedIn(form.value.step3.inspector, 'inspector'),
  notIncludedIn(form.value.authorId, 'true', 'includesAuthor'),
  checkUniqueInList(form.value.step3.approver, (a, b) => a.id === b),
];

export const defaultInspectorValidators = <T extends FormBaseState>(validateFormField: (
  fieldName: string,
  validationProperty?: string,
) => ValidationFn<string>, form: FormGroupState<T>) => [
  validateFormField('inspector'),
  notUser(safeUnbox(form.value.step2.recipient), 'true', 'recipient'),
  notIncludedIn(form.value.step3.approver, 'approver'),
  notIncludedIn(form.value.authorId, 'true', 'includesAuthor'),
  checkUniqueInList(form.value.step3.inspector, (a, b) => a.id === b),
];

export function checkUniqueInList<T>(arrayToCheck: T[], isEqual = (a: T, b) => a === b, errorAttr = 'notUnique') {
  return (currentValue: any) => {
    const doubles = arrayToCheck.filter(a => isEqual(a, currentValue));
    return setError(doubles.length > 1, errorAttr, true);
  };
}

export function createParticipantsValidator(
  validateFormField: <T>(fieldName: string, validationProperty?: string) => ValidationFn<T>,
  hasParticipants: boolean | undefined,
)
  : StateUpdateFns<{ participants: FormParticipantModel[] }> {
  return hasParticipants ? {
    participants: updateArray<FormParticipantModel>((t =>
      updateGroup<FormParticipantModel>({
        ...generateValidators<FormParticipantModel>(validateFormField, t.value.isEmployee ? {
          name: {
            configName: 'valid', // Konfigurationen die es nicht gibt sind valide
            validators: [],
          },
          companyName: {
            configName: 'valid', // Konfigurationen die es nicht gibt sind valide
            validators: [],
          },
          company: {
            configName: 'participantCompany',
            validators: [],
          },
          user: {
            configName: 'participantUser',
            validators: [],
            validationProperty: 'userId',
          },
        } : {
          name: {
            configName: 'participantName',
            validators: [],
          },
          companyName: {
            configName: 'participantCompanyName',
            validators: [],
          },
          company: {
            configName: 'valid', // Konfigurationen die es nicht gibt sind valide
            validators: [],
          },
          user: {
            configName: 'valid', // Konfigurationen die es nicht gibt sind valide
            validators: [],
          },
        }),
      })(t)),
    ),
  } : {};
}

/**
 * Wendet einen Validator auf alle in staticValidators angegebenen keys an. Zusätzlich können Feld-spezifische Validatoren angegeben werden.
 *  Wenn kein spezifischer Validator nötig ist, einfach [] nutzen, um den generellen Validator setzen zu lassen.
 *
 * @param validateFormField der Validator-Generator, der auf alle Felder angewandt werden soll.
 * Wenn im staticValidators child ein Array ist, wird der key als configName verwendet. Wenn der configName vom key abweichen soll, kann ein
 * Objekt zur Konfiguration übergeben werden.
 * @param staticValidators enthält die Feldnamen jeweils mit einem Array mit spezifischen Validatoren (nur für dieses Feld)
 */
export const generateValidators = <TValue>(
  validateFormField: (key: string, prop?: string) => ValidationFn<TValue>,
  staticValidators: {
    [key in keyof Partial<TValue>]:
    (ValidationFn<TValue[key]>[] | { configName: string, validationProperty?: string, validators: ValidationFn<TValue[key]>[] })
  },
): StateUpdateFns<TValue> => Object.keys(staticValidators).reduce((acc, cur) => (
  {
    ...acc,
    [cur]: validate([
      Array.isArray(staticValidators[cur])
        ? validateFormField(cur)
        : validateFormField(staticValidators[cur].configName, staticValidators[cur].validationProperty),
      ...Array.isArray(staticValidators[cur]) ? staticValidators[cur] : staticValidators[cur].validators,
    ]),
  }
), {});

export function notIncludedIn(
  approver: string | ArrangerIdModel | ArrangerIdModel[],
  message: string,
  errorAttr = 'includedIn',
): ValidationFn<string> {
  return (currentValue: string) => {
    if (!approver || !currentValue) {
      return {};
    } else if (Array.isArray(approver)) {
      return setError(approver.filter(a => a.id === currentValue).length > 0, errorAttr, message);
    } else if (typeof approver === 'object') {
      return setError(approver.id === currentValue, errorAttr, message);
    }
    return setError(approver === currentValue, errorAttr, message);
  };
}

export function includedIn(
  valid: string | any[],
  message: string,
  errorAttr = 'notIncludedIn',
  prop = 'id',
): ValidationFn<string> {
  return (currentValue: string) => {
    if (!valid || !currentValue) {
      return {};
    } else if (Array.isArray(valid)) {
      return setError(
        valid.filter(a => typeof a === 'object' ? a[prop] === currentValue : a === currentValue).length === 0,
        errorAttr,
        message,
      );
    } else if (typeof valid === 'object' && valid) {
      return setError(valid[prop] !== currentValue, errorAttr, message);
    }
    return setError(valid !== currentValue, errorAttr, message);
  };
}

export function notUser(user: UserSearchModel, message: string, errorAttr = 'includedIn'): ValidationFn<string> {
  return (currentValue: string) => {
    if (!user || !currentValue) {
      return {};
    }
    return setError(user.userId === currentValue, errorAttr, message);
  };
}

export function setError(hasError, propName, value): ValidationErrors {
  if (hasError) {
    return {
      [propName]: value,
    };
  } else {
    return {};
  }
}
