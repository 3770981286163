import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { I18nPipe } from '../../../core-lib/pipes/i18n.pipe';
import { ApiService } from '../../../core-lib/services/api.service';
import { AppConfigService } from '../../../core/services/app-config.service';
import { SapInfoModel } from '../../models/sap-info.model';
import { TableColumnModel } from '../../models/table-columns.model';
import { SearchModalBase, SearchModalBaseData } from '../../utils/search-modal-base';


@Component({
  selector: 'lib-common-form-creditor-modal',
  templateUrl: './form-accounting-modal.component.html',
  styleUrls: ['./form-accounting-modal.component.scss'],
})
export class FormAccountingModalComponent extends SearchModalBase<SapInfoModel> {
  searchParams: { searchTerm: string, type: string } = {
    searchTerm: '',
    type: 'UNKNOWN',
  };

  @ViewChild('table', {static: true}) private table: DatatableComponent;

  isLoading = false;
  showTable = false;

  defaultColumnConfig: TableColumn = {
    width: 1,
    canAutoResize: true,
    sortable: true,
  };

  columns: TableColumnModel = [
    {
      prop: 'id',
      i18nName: 'accountingNumber',
      ...this.defaultColumnConfig,
      maxWidth: 180,
    },
    {
      prop: 'description',
      i18nName: 'name',
      ...this.defaultColumnConfig,
    },
  ];

  constructor(
    protected api: ApiService,
    protected appConfigService: AppConfigService,
    protected dialogRef: MatDialogRef<FormAccountingModalComponent, SapInfoModel>,
    protected i18nPipe: I18nPipe,
    @Inject(MAT_DIALOG_DATA) public data: SearchModalBaseData,
  ) {
    super(i18nPipe, data, dialogRef);
    this.setUpColumns(this.columns);
  }

  search() {
    this.showTable = true;
    if (!this.isLoading) {
      this.isLoading = true;
      this.api.postSearchAccounting(this.selectedCompany, this.searchParams).pipe(
        catchError(() => of<SapInfoModel[]>([])),
        tap(() => this.isLoading = false),
      ).subscribe((content) => this.content$.next(content));
    }
  }

  getSupportedAccountingTypes() {
    return this.appConfigService.config.supportedAccountingTypes;
  }
}
