import { AfterContentInit, Directive, Host, Input, OnChanges, OnInit, Optional } from '@angular/core';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { AbstractControlState, DisableAction, EnableAction } from 'ngrx-forms';
import { ApproverListComponent } from '../../forms-lib/components/approver-list/approver-list.component';
import { FormAccountingInputComponent } from '../../forms-lib/components/form-accounting-input/form-accounting-input.component';
import { FormFieldCheckboxComponent } from '../../forms-lib/components/form-field-checkbox/form-field-checkbox.component';
import { FormSapAutocompleteInputComponent } from '../../forms-lib/components/form-sap-autocomplete/form-sap-autocomplete-input.component';
import { CurrencyInputComponent } from '../components/currency-input/currency-input.component';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { UserSearchInputComponent } from '../components/user-search-input/user-search-input.component';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[disableWhen]',
})
export class DisableWhenDirective<TValue> implements OnInit, AfterContentInit {
  constructor(
    private store$: Store<any>,
    @Host() @Optional() private input: MatInput,
    @Host() @Optional() private momentInput: MatDatepickerInput<moment.Moment>,
    @Host() @Optional() private select: MatSelect,
    @Host() @Optional() private currencyInput: CurrencyInputComponent,
    @Host() @Optional() private fileUpload: FileUploadComponent,
    @Host() @Optional() private userSearchInput: ApproverListComponent,
    @Host() @Optional() private accountingInfoInput: FormAccountingInputComponent,
    @Host() @Optional() private sapAutocompleteInput: FormSapAutocompleteInputComponent,
    @Host() @Optional() private checkBox: FormFieldCheckboxComponent,
    @Host() @Optional() private userSearchInputComponent: UserSearchInputComponent,
  ) {
  }

  element: MatInput
    | MatSelect
    | CurrencyInputComponent
    | FileUploadComponent
    | ApproverListComponent
    | UserSearchInputComponent
    | FormSapAutocompleteInputComponent
    | FormFieldCheckboxComponent;

  elementWithChangeDetection: OnChanges;


  @Input() ngrxFormControlStateValue: AbstractControlState<any>;

  @Input() controlState: AbstractControlState<any>;

  @Input() ngrxFormControlState: AbstractControlState<any>;

  private ngrxState = undefined;

  private previouslyDisabled = undefined;

  private latestInput: AbstractControlState<boolean> | boolean = undefined;

  @Input()
  set disableWhen(input: AbstractControlState<boolean> | boolean) {
    this.latestInput = input;
    let value = false;

    if (input instanceof Object) {
      value = input.value;
    } else {
      value = input;
    }

    if (!this.ngrxState || value === undefined) {
      return;
    }

    if (value) {
      if (this.previouslyDisabled === undefined) {
        this.previouslyDisabled = this.ngrxState.isDisabled;
      }

      this.store$.dispatch(new DisableAction(this.ngrxState.id));
    } else {
      if (this.previouslyDisabled) {
        this.store$.dispatch(new DisableAction(this.ngrxState.id));
      } else {
        this.store$.dispatch(new EnableAction(this.ngrxState.id));
      }
    }

  }

  get disableWhen() {
    return undefined;
  }

  ngOnInit(): void {
    this.element =
      this.input
      || this.checkBox
      || this.select
      || this.currencyInput
      || this.userSearchInput
      || this.userSearchInputComponent
      || this.sapAutocompleteInput;

    this.ngrxState = this.ngrxFormControlStateValue || this.controlState || this.ngrxFormControlState;

    this.elementWithChangeDetection =
      this.input
      || this.select;
    if (!this.element) {
      console.warn(`DisableWhenDirective: element is ${this.element}`);
    }
  }

  ngAfterContentInit() {
    if (this.latestInput !== undefined) {
      this.disableWhen = this.latestInput;
    }
  }

}
