import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { take } from 'rxjs/operators';
import { QueryPromptMessageReturnEventTypes } from '../../models/query-prompt-message-return-event-types.model';

@Component({
  selector: 'lib-common-form-preconfirm-dialog',
  templateUrl: './form-preconfirm-dialog.component.html',
  styleUrls: ['./form-preconfirm-dialog.component.scss']
})
export class FormPreconfirmDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<unknown>;
  @Input() autoOpen = true;
  dialogRef: MatDialogRef<unknown>;
  eventTypes = QueryPromptMessageReturnEventTypes;

  @Input() title = 'k1Title';
  @Input() declineLabel = 'declineLabel2';
  @Input() acceptLabel = 'acceptLabel2';
  @Input() alignActions = 'center';
  @Input() showAcceptLabel = true;
  @Input() config: MatDialogConfig;

  @Output() closed = new EventEmitter<QueryPromptMessageReturnEventTypes>();

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnDestroy() {
    this.close(undefined);
  }

  ngAfterViewInit(): void {
    if (this.autoOpen) {
      this.open();
    }
  }

  open() {
    this.close(undefined);
    this.dialogRef = this.dialog.open(this.dialogTemplate, {
      minWidth: '350px',
      ...this.config,
    });
    this.dialogRef.afterClosed().pipe(
      take(1)
    ).subscribe(result => {
      this.closed.emit(result);
    });
  }

  close(event: string): void {
    this.dialogRef?.close?.(event);
  }
}
