import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { rxRoute } from '../../../core-lib/utils/reducer-utils';
import { accountDeputiesActions } from '../actions/core-account-deputies.actions';
import { ApiErrorAction, ConcreteApiSuccessAction, CoreActionInit } from '../actions/core.actions';
import { getAccountDeputiesAsDto } from '../reducers/account-deputies.reducer';
import { CoreFeatureState } from '../reducers/core.store';

@Injectable()
export class CoreAccountDeputiesEffects {

  initAccountDeputiesOnCoreInit$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActionInit.TYPE),
    map(() => accountDeputiesActions.AccountDeputiesLoadAction({userId: undefined})),
  ), defaultEffectOptions());

  initAccountDeputiesOnRoute$ = createEffect(() => this.actions$.pipe(
    rxRoute('account'),
    map(() => accountDeputiesActions.AccountDeputiesLoadAction({userId: undefined})),
  ), defaultEffectOptions());

  loadAccountDeputies$ = createEffect(() => this.actions$.pipe(
    ofType(accountDeputiesActions.AccountDeputiesLoadAction),
    switchMap(({userId}) => this.api.getDeputy(userId).pipe(
      map((result) => accountDeputiesActions.AccountDeputiesLoadedAction({result})),
      catchError((e) => of(new ApiErrorAction(e, 'accountDeputiesLoadedActionError'))),
    )),
  ), defaultEffectOptions());

  saveAccountDeputies$ = createEffect(() => this.actions$.pipe(
    ofType(accountDeputiesActions.AccountDeputiesSaveAction),
    withLatestFrom(this.store$.select(getAccountDeputiesAsDto)),
    switchMap(([{userId}, dataFn]) => this.api.postDeputy(dataFn(userId), userId).pipe(
      map((data) => data),
      switchMap((result) => [
        accountDeputiesActions.AccountDeputiesSaveSuccessAction({result}),
        new ConcreteApiSuccessAction('accountDeputiesSaveSuccessAction'),
      ]),
      catchError((e) => {
        console.error(e);
        return of(new ApiErrorAction(e, 'accountDeputiesSaveActionError'));
      }),
    )),
  ), defaultEffectOptions());

  constructor(
    private actions$: Actions,
    private store$: Store<CoreFeatureState>,
    private api: ApiService,
  ) {
  }
}
