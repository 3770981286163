import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { box, Boxed, FormControlState, FormGroupState, MarkAsTouchedAction, unbox } from 'ngrx-forms';
import { NgrxValueConverter } from 'ngrx-forms/src/control/value-converter';
import { Subject } from 'rxjs';
import { filter, map, shareReplay, takeUntil, withLatestFrom } from 'rxjs/operators';
import { QueryPromptComponent } from '../../../core-lib/components/query-prompt/query-prompt.component';
import { countries } from '../../../core-lib/components/select-country/countries';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import { CostCenterToCompany } from '../../../core-lib/models/cost-center-to-company.model';
import { QueryPromptData } from '../../../core-lib/models/query-prompt-data.model';
import { SetValueTraceableAction } from '../../../core-lib/models/set-value-traceable-action';
import { UserDataFormModel } from '../../../core-lib/models/user-data-form.model';
import { UserSearchModel } from '../../../core-lib/models/user-search.model';
import { rxGetFormControl } from '../../../core-lib/utils/reducer-utils';
import { SelectAsyncBoxedIdNgrxValueConverter } from '../../../core-lib/utils/select-boxed-id-ngrx-value-converter';
import { ArrangerDtoModel } from '../../../forms/all-forms/models/arranger-dto.model';
import { IdDtoModel } from '../../../forms/all-forms/models/id-dto.model';
import { ConfigListModel } from '../../models/config-list.model';
import {
  CoreAccountDeleteCostCenterAction,
  CoreAccountDeleteDefaultArrangerAction,
  CoreAccountFormActionSave,
} from '../../ngrx/actions/core-account.actions';
import { FORM_ID } from '../../ngrx/reducers/account.store';

import {
  CoreFeatureState,
  getAccountCostCenterControlsArrayState,
  getAccountDefaultArrangersControls,
  getAccountDefaultCompanyControlValue,
  getAccountFeatureState, getAccountNetRegions,
  getCompaniesState,
  getConfigSalutationsState,
} from '../../ngrx/reducers/core.store';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-account-data',
  templateUrl: './route-account-data.component.html',
  styleUrls: ['./route-account-data.component.scss'],
})
export class RouteAccountDataComponent implements OnDestroy {
  componentDestroyed$ = new Subject<void>();

  countries = countries;

  accountState$ = this.store$.select(getAccountFeatureState);
  salutationState$ = this.store$.select(getConfigSalutationsState);
  costCenterState$ = this.store$.select(getAccountCostCenterControlsArrayState);
  companiesState$ = this.store$.select(getCompaniesState);
  netRegions$ = this.store$.select(getAccountNetRegions);
  defaultCompany$ = this.store$.select(getAccountDefaultCompanyControlValue).pipe(
    map(unbox),
  );
  enableNetRegion = this.appConfigService.config.enableNetRegion;
  defaultArrangerState$ = this.store$.select(getAccountDefaultArrangersControls);

  accountData: FormGroupState<UserDataFormModel>;

  formSalutationControl$ = this.salutationState$.pipe(
    map(salutation => salutation.value),
    map(salutation => [...salutation]),
  );

  salutationsLoaded$ = this.formSalutationControl$.pipe(
    map((salutations) => salutations && salutations.length > 0),
  );

  companiesLoaded$ = this.companiesState$.pipe(
    map((companies) => companies && companies.length > 0),
  );

  salutationValueConverter = new SelectAsyncBoxedIdNgrxValueConverter<ConfigListModel>(this.formSalutationControl$);

  approverValueConverter = <NgrxValueConverter<Partial<UserSearchModel & { id: string }>, Boxed<Partial<ArrangerDtoModel>>>>{
    convertViewToStateValue: (value) => box({
      userId: value && value.userId,
      id: value && value.id,
    }),
    convertStateToViewValue: (value) => ({
      userId: value && unbox(value).userId,
      id: value && unbox(value).id,
    }),
  };

  constructor(
    private store$: Store<CoreFeatureState>,
    private dialog: MatDialog,
    private appConfigService: AppConfigService,
  ) {
    this.accountState$.pipe(
      takeUntil(this.componentDestroyed$),
    ).subscribe(accountState => this.accountData = accountState);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  getControlId = (index: number, t: FormGroupState<[]>) => t.id;

  getFormControlState$(name) {
    return this.accountState$.pipe(rxGetFormControl<UserDataFormModel>(name));
  }

  sendData() {
    this.store$.dispatch(new CoreAccountFormActionSave());
  }

  addCostCenter() {
    if (this.accountData) {
      const newValue = {
        ...this.accountData.value,
        costCenterToCompanies: [
          ...this.accountData.value.costCenterToCompanies,
          {id: null, company: '', costCenter: ''},
        ],
      };
      this.store$.dispatch(new SetValueTraceableAction(FORM_ID, newValue));
    }
  }

  addDefaultArranger() {
    if (this.accountData.value.defaultArrangers.length >= 2) {
      return;
    }

    if (this.accountData) {
      const newApprover = {
        ...this.accountData.value,
        defaultArrangers: [
          ...this.accountData.value.defaultArrangers,
          <IdDtoModel>{
            id: undefined,
          },
        ],
      };
      this.store$.dispatch(new SetValueTraceableAction(FORM_ID, newApprover));
    }
  }

  getCompanyValues(currentCostCenter: FormGroupState<CostCenterToCompany>) {
    const stream = this.store$.select(getCompaniesState).pipe(
      withLatestFrom(this.costCenterState$),
      map(([companies, costCenters]: [CompanyDataDtoModel[], FormGroupState<CostCenterToCompany>[]])
        : [CompanyDataDtoModel[], CompanyDataDtoModel[]] =>
        [costCenters.reduce((acc, cur) => acc.filter((acc1) => acc1.id !== cur.controls.company.value), companies), companies]),
      shareReplay(),
    );

    if (currentCostCenter.value && currentCostCenter.value.company && currentCostCenter.value.company.length > 0) {
      return stream.pipe(
        map(([filteredCompanies, companies]) =>
          ([companies.find((company) => company.id === currentCostCenter.value.company), ...filteredCompanies])),
      );
    }

    return stream.pipe(map(([filteredCompanies, companies]) => filteredCompanies));
  }

  removeCostCenter(costCenterToDelete: FormGroupState<CostCenterToCompany>) {
    if (costCenterToDelete.value.id && costCenterToDelete.value.id.length > 0) {
      this.dialog.open(QueryPromptComponent, {
        data: <QueryPromptData>{
          text: 'removeCostCenterText',
          title: 'removeCostCenterTitle',
        },
      }).afterClosed().pipe(
        filter(event => event === 'accepted'),
      ).subscribe(() => this.triggerDeleteCostCenter(costCenterToDelete));
    } else {
      this.triggerDeleteCostCenter(costCenterToDelete);
    }
  }

  private triggerDeleteCostCenter(costCenterToDelete: FormGroupState<CostCenterToCompany>) {
    const newValue = {
      ...this.accountData.value,
      costCenterToCompanies: [
        ...(this.accountData.controls.costCenterToCompanies.controls
          .filter(costCenter => costCenter.value.company !== costCenterToDelete.value.company)
          .map(control => control.value)),
      ],
    };
    this.store$.dispatch(new SetValueTraceableAction(FORM_ID, newValue));
    if (costCenterToDelete.value.id && costCenterToDelete.value.id.length > 0) {
      this.store$.dispatch(new CoreAccountDeleteCostCenterAction(costCenterToDelete.value.id));
    }
  }

  removeDefaultArranger(arrangerToDelete: FormGroupState<IdDtoModel>) {
    if (arrangerToDelete.value.id) {
      this.dialog.open(QueryPromptComponent, {
        data: <QueryPromptData>{
          text: 'removeArrangerText',
          title: 'removeArrangerTitle',
        },
      }).afterClosed().pipe(
        filter(event => event === 'accepted'),
      ).subscribe(() => this.triggerDeleteDefaultArranger(arrangerToDelete));
    } else {
      this.triggerDeleteDefaultArranger(arrangerToDelete);
    }
  }

  private triggerDeleteDefaultArranger(arrangerToDelete: FormGroupState<IdDtoModel>) {
    const defaultArrangersValue = this.accountData.controls.defaultArrangers.value;
    const newValue = {
      ...this.accountData.value,
      defaultArrangers: [
        ...defaultArrangersValue.filter(arranger => arranger !== arrangerToDelete.value),
      ],
    };
    this.store$.dispatch(new SetValueTraceableAction(FORM_ID, newValue));
    if (arrangerToDelete.value.id) {
      this.store$.dispatch(new CoreAccountDeleteDefaultArrangerAction(arrangerToDelete.value.id));
    }
    this.store$.dispatch(new MarkAsTouchedAction(FORM_ID));
  }

  arrangerHasValue(arranger: FormControlState<Boxed<ArrangerDtoModel>>) {
    return unbox(arranger).value.userId;
  }
}
