import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { QueryPromptData } from '../../models/query-prompt-data.model';
import { QueryPromptMessageReturnEventTypes } from '../../models/query-prompt-message-return-event-types.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-query-prompt',
  templateUrl: './query-prompt.component.html',
  styleUrls: ['./query-prompt.component.scss'],
})
export class QueryPromptComponent {
  title: string;
  text: string;
  replacements: { [key: string]: string | number };
  declineLabel: string;
  acceptLabel: string;
  showAcceptLabel: boolean;

  eventTypes = QueryPromptMessageReturnEventTypes;

  constructor(
    public dialogRef: MatDialogRef<QueryPromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QueryPromptData,
  ) {
    this.title = data.title || '';
    this.text = data.text || '';
    this.replacements = data.replacements || {};
    this.declineLabel = data.declineLabel || 'declineLabel';
    this.acceptLabel = data.acceptLabel || 'acceptLabel';
    this.showAcceptLabel = !data.hideAcceptLabel || !!data.acceptLabel;
  }

  close(event: string): void {
    this.dialogRef.close(event);
  }

}
