import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { UserSearchModel } from '../../models/user-search.model';
import { ApiService } from '../../services/api.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-user-search-modal',
  templateUrl: './user-search-modal.component.html',
  styleUrls: ['./user-search-modal.component.scss'],
})
export class UserSearchModalComponent implements OnInit {
  @ViewChild('model', {static: true})
  model: NgModel;

  search = '';
  debounce = 100;
  minLength = 3;
  label = '';

  users$: Observable<UserSearchModel[]> = of([]);

  constructor(private api: ApiService,
              public dialogRef: MatDialogRef<UserSearchModalComponent,
                UserSearchModel>,
              @Inject(MAT_DIALOG_DATA) public data: { debounce: number, minLength: number, label: string }) {
    this.debounce = data.debounce || 100;
    this.minLength = data.minLength || 3;
    this.label = data.label;
  }

  ngOnInit() {
    this.users$ = this.model.update.pipe(
      debounceTime(this.debounce),
      switchMap((query) => query.toString().length >= this.minLength ? this.api.getUserFind(query.toString()) : of([])),
    );
  }

  selectUser(user: UserSearchModel) {
    this.dialogRef.close(user);
  }

  getOrigin() {
    return window.location.origin;
  }

}
