import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { almostElasticMatch } from '../../../core/utils/almost-elastic-search';
import { FormDtoModel } from '../../../forms/all-forms/models/form-dto.model';

@Component({
  selector: 'lib-common-template-selector-dialog',
  templateUrl: './template-selector-dialog.component.html',
  styleUrls: ['./template-selector-dialog.component.scss']
})
export class TemplateSelectorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TemplateSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TemplateSelectorDialogData,
  ) {
  }

  hasTemplates$ = this.data.templates$.pipe(
    map(t => Array.isArray(t) && t.length > 0)
  );

  filteredTemplates$ = this.data.templates$.pipe(
    map((templates) => (searchText) => templates?.filter((t) => almostElasticMatch(searchText, t.templateName)))
  );
  searchText: string;

  close() {
    this.dialogRef.close();
  }

  getLink(template: FormDtoModel) {
    return `/forms/${template.form.category.name}/${template.form.identifier}/template/${template.templateName}`;
  }
}


export class TemplateSelectorDialogData {
  templates$: Observable<FormDtoModel[]>;
}
