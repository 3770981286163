import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { SnackBarComponent } from '../../../core-lib/components/snack-bar/snack-bar.component';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { ApiErrorAction, ApiSuccessAction, ApiWarnAction } from '../actions/core.actions';

@Injectable()
export class ResponseHandlerEffects {

  public constructor(private snackbar: MatSnackBar, private actions$: Actions) {
  }

  error$ = createEffect(() => this.actions$.pipe(
    filter((action: ApiErrorAction) =>  action.type.startsWith(ApiErrorAction.TYPE) && !!(action.isError && action.message)),
    tap((action: ApiErrorAction) => this.snackbar.openFromComponent(SnackBarComponent, {
      panelClass: 'error-panel',
      duration: action.snackBarDuration,
      data: action.message,
    })),
    tap((action: ApiErrorAction) => {
      console.error('ApiErrorAction occured', action, action.e);
    }),
    catchError(e => of(console.error('error while handling error', e))),
  ), defaultEffectOptions({dispatch: false}));

  success$ = createEffect(() => this.actions$.pipe(
    filter((action: ApiSuccessAction) => action.type.startsWith(ApiSuccessAction.TYPE) && action.isSuccess),
    tap((action: ApiSuccessAction) => this.snackbar.openFromComponent(SnackBarComponent, {
      panelClass: 'success-panel',
      duration: action.snackBarDuration,
      data: action.messageKey,
    })),
  ), defaultEffectOptions({dispatch: false}));

  warn$ = createEffect(() => this.actions$.pipe(
    filter((action: ApiWarnAction) => action.type === ApiWarnAction.TYPE),
    tap((action: ApiWarnAction) => this.snackbar.openFromComponent(SnackBarComponent, {
      panelClass: 'warn-panel',
      duration: action.snackBarDuration,
      data: action.messageKey,
    })),
  ), defaultEffectOptions({dispatch: false}));

}
