import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CompanyDataDtoModel } from '../../core-lib/models/company-data-dto.model';
import { I18nPipe } from '../../core-lib/pipes/i18n.pipe';
import { TableColumnModel } from '../models/table-columns.model';


export interface SearchModalBaseData {
  debounce: number;
  minLength: number;
  selectedCompany: CompanyDataDtoModel;
}

export class SearchModalBase<T> implements SearchModalBaseData {
  public debounce: number;
  public minLength: number;
  public selectedCompany: CompanyDataDtoModel;
  protected columns: TableColumnModel;
  public messages = {emptyMessage: 'No data to display', totalMessage: 'total', selectedMessage: 'selected'};
  public selectedRow: T;

  protected contentInitialValue: Partial<T>[] = [];

  protected content$ = new BehaviorSubject(this.contentInitialValue);
  public tableFilter$ = new BehaviorSubject('');

  public filteredContent$ = combineLatest([
    this.content$,
    this.tableFilter$,
  ]).pipe(
    // to object and default values
    map(([content, tableFilter]) => ({
      content: content || [],
      tableFilter: tableFilter.split(' '),
    })),
    // filter
    map(({content, tableFilter}) => content.filter(
      (o) => tableFilter.every(
        (filterString) => Object.keys(o).some(
          (k) => o[k].toLowerCase().includes(filterString.toLowerCase()),
        ),
      ),
    )),
  );

  constructor(
    protected i18nPipe: I18nPipe,
    protected data: any,
    protected dialogRef: MatDialogRef<any, T>,
  ) {
    for (const dataName in data) {
      if (Object.prototype.hasOwnProperty.call(data, dataName)) {
        this[dataName] = data[dataName];
      }
    }
    this.debounce = data.debounce || 100;
    this.minLength = data.minLength || 3;

    this.i18nPipe.transform('noData').pipe(take(1)).subscribe((nodata) => this.messages.emptyMessage = nodata);
    this.i18nPipe.transform('totalCount').pipe(take(1)).subscribe((totalCount) => this.messages.totalMessage = totalCount);
    this.i18nPipe.transform('selected').pipe(take(1)).subscribe((selected) => this.messages.selectedMessage = selected);

  }

  protected setUpColumns(columns: TableColumnModel) {
    this.columns = columns;
    this.columns.forEach((value, index) => {
      this.i18nPipe.transform(value.i18nName).subscribe((translatedValue => {
        this.columns[index].name = translatedValue;
      }));
    });
  }

  rowSelected($event) {
    this.selectedRow = $event.selected[0];
  }

  applyDialog() {
    this.dialogRef.close(this.selectedRow);
  }

  abortDialog() {
    this.dialogRef.close();
  }

}
