import { EffectConfig } from '@ngrx/effects';


export function defaultEffectOptions<T extends EffectConfig & {
  functional?: false;
} >(nondefault?: T | {}): T & {functional: false};
export function defaultEffectOptions<T extends EffectConfig & {
    functional: true;
    dispatch: false;
}>(nondefault?: T | {}): T & {functional: false};
export function defaultEffectOptions<T extends EffectConfig & {
  functional: true;
  dispatch?: true;
}>(nondefault: T | {} = {}): T & {functional: false} {
  return <T & { functional: false }> <unknown>{
    dispatch: true,
    resubscribeOnError: true,
    ...nondefault,
    functional: false,
  };
}
