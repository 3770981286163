import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FormControlState } from 'ngrx-forms';
import { QueryPromptData } from '../../models/query-prompt-data.model';
import { QueryPromptComponent } from '../query-prompt/query-prompt.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-wrapper',
  templateUrl: './input-property-wrapper.component.html',
  styleUrls: ['./input-property-wrapper.component.scss'],
})
export class InputPropertyWrapperComponent {
  @Input() header: string;
  @Input() icon: string;
  @Input() typeNameI18n: string;
  @Input() type: string;
  @Input() readonly: boolean;
  @Input() ngrxOverwrite: FormControlState<boolean>;

  @Output() changeReadonly = new EventEmitter<boolean>();

  constructor(
    public dialog: MatDialog,
  ) {
  }

  onChangeReadonly(event) {
    this.changeReadonly.emit(event.checked);
  }

  openDialog($event: Event, header): void {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(QueryPromptComponent, {
      data: <QueryPromptData>{
        title: 'fkInfoTitle' + this.header,
        text: 'fkInfoMessage' + this.header,
        declineLabel: 'back',
        hideAcceptLabel: true,
      },
    });
  }


}
