import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FormBaseState } from '../../forms-lib/models/base-state.model';
import { getSessionUserRoles } from '../../session/reducers/session.reducer';
import { UserRole } from '../models/user-roles.model';

export class CateringRoleBasedRedirectGuardConfig {
  formKey: string;
}

/**
 * Prüft, ob Nutzer caterer ist. (rolle=CATERER, aber nicht ADMIN oder CATERER_ADMIN)
 * Wenn ja, wird er nach /catering weitergeleitet, da er hier nix verloren hat.
 * Sonst passiert nichts und der Aufruf wird erlaubt.
 */
@Injectable({
  providedIn: 'root'
})
export class CateringRoleBasedRedirectGuard  {
  constructor(
    private router: Router,
    protected store$: Store<FormBaseState>,
  ) { }

  static CONFIG_NAME = 'cateringRoleBasedRedirectGuardConfig';

  userRoles$ = this.store$.select(getSessionUserRoles);

  static createRouteDataConfig(cateringRoleBasedRedirectGuardConfig: CateringRoleBasedRedirectGuardConfig) {
    return {
      [CateringRoleBasedRedirectGuard.CONFIG_NAME]: cateringRoleBasedRedirectGuardConfig,
    };
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const config: CateringRoleBasedRedirectGuardConfig = route.data[CateringRoleBasedRedirectGuard.CONFIG_NAME];

    // auch der caterer darf in diese forms...
    if (['IB1', 'IB1c', 'M1', 'M1c'].includes(config?.formKey)) {
      return true;
    }

    return this.userRoles$.pipe(
      filter(roles => !!roles),
      map((roles) => (
        !!roles.includes(UserRole.CATERER)
        && !(roles.includes(UserRole.CATERER_ADMIN) || roles.includes(UserRole.ADMIN))
      )),
      map((isCaterer) => {
        if (isCaterer) {
          this.router.navigate(['catering']);
          return false;
        }
        return true;
      }),
    );
  }
}
