import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { FormControlState } from 'ngrx-forms';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-form-field-checkbox',
  templateUrl: './form-field-checkbox.component.html',
  styleUrls: ['./form-field-checkbox.component.scss'],
})
export class FormFieldCheckboxComponent {
  @Input() ngrxFormControlStateValue: FormControlState<boolean>;
  @Output() change = new EventEmitter<MatCheckboxChange>();
  @Input() configName: string;

  placeholder: string;
  required: boolean;
  disabled: boolean;

  isRequired() {
    return this.required || (this.ngrxFormControlStateValue
      && this.ngrxFormControlStateValue.errors
      && this.ngrxFormControlStateValue.errors.required);
  }
}
