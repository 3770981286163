import { i9e, TokenParseFnType } from '../../core-lib/pipes/i9e';

type TranslationContent = string | TokenParseFnType;
/**
 * Dieser Typ stellt sicher, dass genau die keys von T (default: de) in der aktuellen Language enthalten sind.
 */
type TranslationLanguage<T extends string = TranslationKeys> = {
  [key in T]: TranslationContent;
};


const de = {
  UNKNOWN: 'Ein Fehler ist aufgetreten.',
  COMPANY_INACTIVE: 'Die ausgewählte Gesellschaft ist nicht aktiv. Der Vorgang kann daher nicht gespeichert / abgesendet werden.',
  chooseNetRegion: 'Zuerst Netzregion wählen',
  netRegion: 'Netzregion',
  serviceCenter: 'Servicecenter',
  PROPOSAL_LOCKED: 'Vorgang ist durch einen anderen Nutzer gesperrt',
  lockedByTrailing: '.',
  lockedBy: 'Der Vorgang ist für andere Bearbeiter gesperrt von',
  lockedByYou: 'Der Vorgang ist von Ihnen für andere Bearbeiter gesperrt.',
  formHasErrors: 'Das Formular enthält Fehler, bitte prüfen.',
  authorNotAllowed: 'Der Antragsteller darf nicht gleichzeitig der Genehmiger/Prüfer sein.',
  approverNeeded: 'Für einen der ausgewählten Artikel wird ein Genehmiger benötigt.',
  recipientNotAllowed: 'Der Zahlungsempfänger darf nicht gleichzeitig der  Genehmiger/Prüfer sein.',
  notAllowedWhileIncludedIn: 'Nutzer ist bereits in einer anderen Rolle definiert:',
  requiredFieldPleaseCheck: 'Nicht ausgefülltes Pflichtfeld. Bitte überprüfen Sie das Formular.',
  invalidFormPleaseCheck: 'Es liegen Fehler im Formular vor. Bitte prüfen Sie Ihre Eingabe.',
  formDashboard: 'Formulare',
  favoriteDashboard: 'Favoriten',
  templateDashboard: 'Vorlagen',
  noTemplatesForForm: 'Es existieren noch keine Vorlagen für das ausgewählte Formular.',
  adminDashboard: 'Administration',
  indexInfoText: 'Hier könnte Ihre Info stehen. Systemmeldungen leicht gemacht.',
  participantInfoText: 'Bitte fügen Sie die Teilnehmerliste unter "Dokumente anhängen" an.',
  missingInput: 'Bitte Eingabefelder prüfen.',
  greeting: i9e`Hallo ${'name'}`,
  language: 'Sprache',
  searchApprover: 'Suche Genehmiger',
  enableApprover: 'Genehmiger aktivieren',
  approvalComment: 'Kommentar',
  pickupOption: 'Abholung (keine Lieferung)',
  enterRoom: 'Raum',
  uploaded: 'bereits hochgeladen',
  '90DayWarning': 'Alle abgeschlossenen, zwischengespeicherten und abgelehnten Vorgänge werden 120 Tage nach der letzten Änderung automatisch gelöscht.',

  yearly: 'jährlich',
  monthly: 'monatlich',
  twoMonths: 'alle 2 Monate',
  quarterly: 'quartalsweise',
  fourMonths: 'alle 4 Monate',
  sixMonths: 'alle 6 Monate,',
  first: '1. des Monats',
  fifteenth: '15. des Monats',
  thirtieth: '30. des Monats',

  favoritCreate: 'als Favorit anlegen',
  favoritDelete: 'aus Favoriten löschen',
  showAll: 'alle anzeigen',
  showLess: 'weniger anzeigen',
  more: 'mehr',
  cameraScan: 'Kamerascan',
  addManual: 'Manuell erfassen',
  useTemplate: 'Vorlage verwenden',
  useDocument: 'Datei importieren',
  noEntry: 'Keine Einträge vorhanden',

  print: 'Drucken',
  company: 'Gesellschaft',
  selectCompany: 'Gesellschaft wählen',
  selectedCompany: 'ausgewählte Gesellschaft',
  order: 'Bestellung',
  position: 'Position',
  defaultCompany: 'Standardgesellschaft',
  newCompany: 'Neue Gesellschaft anlegen',
  shareholder: 'Gesellschafter',
  companyId: 'Buchungskreis',
  creditor: 'Kreditor',
  debitor: 'Debitor',
  blanketOrder: 'Rahmenbestellung',
  debitorSearch: 'Debitorsuche',
  debitorNumber: 'Debitor-Nr.',
  creditorNumber: 'Kreditor-Nr.',
  creditorSearch: 'Kreditorsuche',
  payeeNumber: 'Kreditor-Nr.',
  accountingSearch: 'Kontierungssuche',
  accountingNumber: 'Kontierungsnummer',
  ledgerAccountSearch: 'Sachkontosuche',
  ledgerAccountNumber: 'Sachkontonummer',
  userSearch: 'Nutzersuche',
  yourData: 'Adressdaten',
  billingAddress: 'Rechnungsadresse',
  orderAddress: 'Auftragsort',
  applyYourData: 'persönliche Daten übernehmen',
  billingAddressBasedOnCompany: 'Rechnungsanschrift anhand Gesellschaft',
  differingShippingAddress: 'abweichende Lieferadresse',
  companyShippingAddress: 'Lieferadresse aus Gesellschaft',
  shippingAddress: 'Lieferadresse / Leistungsort',
  myData: 'Meine Daten',
  applyFromPayee: 'Daten von Auftraggeber übernehmen',
  apply: 'übernehmen',
  close: 'schließen',
  backendClient: 'SAP Mandant',
  participantCompany: 'Gesellschaft',
  participantName: 'Name',
  roomName: 'Raumbezeichnung / -nummer',
  billingBasis: 'Abrechnungsgrundlage bei Leistungsaufträgen',
  miscPayout: 'Sonstige Auszahlung',
  loadBlanketOrder: 'Rahmenbestellung laden',

  noData: 'Keine Daten gefunden',
  totalCount: 'Gesamt',
  selected: 'ausgewählt',

  translations: 'Übersetzung',
  coverLetterText: 'Text des Anschreibens',

  contactDetails: 'Kontaktdaten',
  address: 'Adresse',
  salesInfo: 'Vertriebsdaten',
  salesOrg: 'Verkaufsorganisation',
  distributionChannel: 'Vertriebsweg',
  sector: 'Sparte',
  branchSalesOffice: 'Verkaufsbüro',
  procurementSection: 'Einkaufswerk',
  salutation: 'Anrede',
  salutations: 'Anreden',
  salutationID: 'Anrede FORM_ID',
  female: 'Frau',
  male: 'Herr',
  divers: 'Divers',
  name: 'Name',
  nameOrCompany: 'Name / Firma',
  shortName: 'Kürzel',
  nameAddition: 'Namensergänzung',
  firstName: 'Vorname',
  lastName: 'Nachname',
  streetNumber: 'Straße, Nr.',
  street: 'Straße',
  place: 'Ort',
  plz: 'PLZ',
  boxNumber: 'Postfach',
  boxNumberLocation: 'Postfach - PLZ, Ort',
  phone: 'Telefon',
  mailAdress: 'E-Mail Adresse',
  mail: 'E-Mail',
  iban: 'IBAN',
  bic: 'BIC',
  country: 'Land',
  department: 'Abteilung',
  bankDetails: 'Bankverbindung',
  bankData: 'Bankdaten',
  costCenter: 'Kostenstelle',
  fuVNr: 'FuV-Nr',
  user: 'Benutzer',
  collectionBy: 'Abholung durch...',
  deliveryPlace: 'Ort / Raum der Lieferung',
  selectRoom: 'Raum wählen...',
  noRoomInList: 'Raum nicht in Liste gefunden',
  alternativeRoom: 'Raum',
  particularities: 'Besonderheiten',
  empty: '',
  search: 'Suche',
  filterIt: 'filtern',
  searchUser: 'Suche Benutzer',
  headerData: 'Kopfdaten',
  coverLetter: 'Anschreiben',
  itemsList: 'Artikelliste',
  itemsListHint: 'Auswählbare Artikel sind abhängig vom Standort und Bewirtungsdatum',
  detailsAndPaymentAmount: 'Detailangaben und Auszahlungsbetrag',
  positionInformations: 'Positionsangaben',
  details: 'Detailangaben',
  approvalStages: 'Genehmigungsstufen',
  documentAttachments: 'Dokumente anhängen',
  dateTo: 'Datum bis:',
  activeSystemMessage: 'aktivieren',
  voucherDate: 'Belegdatum',
  voucherType: 'Belegart',
  bookingDateOriginal: 'Buchungsdatum',
  sapReference: 'Referenz',
  voucherHeadText: 'Belegkopftext',
  voucherAuthor: 'Erfasser',
  dateOfEntry: 'Erfassungsdatum',
  bookingDate: 'Buchungsdatum',
  transferText: 'Umbuchungstext',
  sendMail: 'E-Mail an Erfasser senden',
  mailText: 'E-Mail Inhalt',
  lastTryTime: 'Letzter Übertragungsversuch',
  queueRetry: 'Erneut einreihen',
  queueDelete: 'Eintrag entfernen',

  billingInterval: 'Abrechnungsintervall',
  billingDate: 'Abrechnungszeitpunkt',
  billingUntil: 'Abrechnung bis',
  serviceDescription: 'Leistungsbeschreibung',
  termsOfPayment: 'Zahlungsbedingungen',
  facts: 'Sachverhalt',
  visContractNumber: 'VIS-Vetragsnummer',
  servicePeriod: 'Leistungszeitraum',
  placeOfPerformance: 'Leistungsort',
  internalNote: 'interner Vermerk',
  additionalInfoOnInvoice: 'zusätzlicher Vermerk auf der Rechnung',
  dienArticle: 'DIEN-Artikel',
  recurringAccounting: 'wiederkehrende Rechnungslegung',

  proposalInfo: 'Vorgangsinformationen',
  referenceNumber: 'SAP-Buchungsnummer',
  billingNumber: 'SAP-Rechnungsnummer',

  agb: 'AGB',
  certificate: 'Zertifikate',
  downloadAgb: 'AGB herunterladen',
  info: 'Anmerkungen',
  dateReject: 'Datum der Ablehnung',
  deputizedFor: 'In Vertretung für',
  dateCatering: 'Datum der Bewirtung',
  dateEvent: 'Datum des Jubiläums',
  reason: 'Anlass der Bewirtung',
  location: 'Ort der Bewirtung',
  numberOfPersons: 'Anzahl der Teilnehmer',
  participants: 'Teilnehmerliste',
  leaflet: 'Hinweise zur Abrechnung',

  approver: 'Genehmiger',
  standardApprover: 'Standardgenehmiger (E-Mail)',
  technicalApprover: 'Fachlicher Genehmiger',
  confirmationPerson: 'Bestätigungsperson',
  inspector: 'Prüfer',
  editorDeputies: 'Fachlicher Vertreter',
  approverDeputies: 'Genehmigervertreter',

  representativesConfiguration: 'Vertreterkonfiguration',

  activationValue: 'Schwellwert für Aktivierung',
  activationCount: 'Stichprobenintervall',
  sortingNumber: 'Reihenfolge',

  INVOICE: 'Rechnung',
  PARTICIPANT_LIST: 'Teilnehmerliste',
  CONTRACT: 'Vertrag',
  OTHERS: 'Sonstiges',
  CREDIT: 'Gutschrift',
  OFFER: 'Angebot',
  RELEASE: 'Freistellungsbescheinigung',

  PDF_ONLY: 'nur PDFs',
  PDF_ONLYError: 'nur PDFs',
  NO_EXECUTABLES: 'keine ausführbaren Dateien',
  NO_EXECUTABLESError: 'keine ausführbaren Dateien',

  /*Dashboards*/
  throughputDashboard: 'Durchlaufzeit-Dashboard',
  proposalCountDashboard: 'Vorgangsanzahl-Dashboard',
  payoutDashboard: 'Auszahlungsbeträge-Dashboard',

  /*Button Labels*/
  reload: 'Neu Laden',
  save: 'Speichern',
  saveView: 'Ansicht speichern',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  overviewPage: 'Weiter',
  next: 'Weiter',
  back: 'Zurück',
  login: 'Anmelden',
  logout: 'Abmelden',
  category: 'Kategorie',
  fileDelete: 'Datei entfernen',
  attachFile: 'Datei anhängen',
  logo: 'Logo',
  term: 'Bezeichnung',
  description: 'Beschreibung',
  furtherDescription: 'weitere Beschreibung',
  declineLabel: 'Nein',
  acceptLabel: 'Ja',
  declineLabel2: 'Ablehnen',
  acceptLabel2: 'Akzeptieren',
  remove: 'entfernen',
  editCompany: 'Gesellschaft bearbeiten',
  from: 'von',
  until: 'bis',
  required: 'Pflichtfeld',
  editable: 'Bearbeitbar',
  visible: 'Sichtbar',
  changeableByApprover: 'Bearbeitbar durch Genehmiger',
  changeableByInspector: 'Bearbeitbar durch Prüfer',
  predefined: 'Vorausgefüllt',
  overwrite: 'Überschreiben',
  translationPlease: 'Bitte geben Sie eine Übersetzung an',
  noSelection: 'Nicht ausgewählt',
  cancelCatering: 'Bewirtung stornieren',
  cancel: 'Vorgang stornieren',

  properAccountList: 'Sachkontenliste',
  properAccountID: 'Sachkonto FORM_ID',
  taxRates: 'Steuersätze',
  billingInformation: 'Rechnungsangaben',
  voucherSelection: 'Belegauswahl',
  adjustingEntry: 'Umbuchungsbeleg',
  loadVoucherData: 'Beleg laden',
  loadDifferentVoucherData: 'anderen Beleg laden',
  loadOrderData: 'Rahmenbestellung laden',
  loadDifferentOrderData: 'andere Rahmenbestellung laden',
  client: 'Auftraggeber, -nehmer',
  detailInformation: 'Detailangaben',
  serviceRecipients: 'Leistungsempfänger, -erbringer',
  supplierContractor: 'Lieferant/Auftragnehmer',
  differentBillingAddress: 'Abweichende Rechnungsadresse',
  addAGB: 'AGB hinzufügen',
  addOtherAttachment: 'andere Datei hinzufügen',
  useCase: 'Verwendungszweck',
  groundNumber: 'Anlagennummer',
  ustId: 'Steuernummer',
  contract: 'Vertrag',
  attachments: 'Anlagen',
  getAccountingDataFromLine: 'Kontierung und Sachkonto aus der 1. Position übernehmen',
  getCompanyFromLine: 'Gesellschaft von 1. Teilnehmer übernehmen',
  payout: 'Auszahlungsbetrag',
  commentForApprover: 'Kommentar für Genehmiger',
  positionName: 'Name',
  netCost: 'Nettobetrag',
  taxRate: 'Steuersatz',
  taxAmount: 'Steuerbetrag',
  grossCost: 'Bruttobetrag',
  taxValue: 'Steuerbetrag',
  account: 'Kontierung',
  accountType: 'Kontierungstyp',
  ledgerAccount: 'Sachkonto',
  positions: 'Positionen',
  deselect: 'Auswahl aufheben',

  /*PositionTable Items*/
  item: 'Artikel',
  itemName: 'Artikelname',
  quantity: 'Menge',
  unit: 'Einheit',
  singlePrice: 'Einzelpreis',
  total: 'Summe',
  quotaEqualsActual: 'IST = SOLL',
  approvalNeeded: 'Bestätigungsperson',
  addAutomatically: 'automatisch hinzufügen',
  validFrom: 'gültig ab ',
  validUntil: 'gültig bis ',
  deliveryDate: 'Lieferungs-/Ausführungsdatum',

  summary: 'Übersicht',
  process: 'Vorgänge',
  approval: 'Freigabe',
  admin: 'Administration',
  catering: 'Bewirtung',
  formConfig: 'Formularkonfiguration',
  defaultValue: 'Standardwert',
  availableCategories: 'verfügbare Kategorien',
  requiredCategories: 'benötigte Kategorien',
  uploadType: 'erlaubte Dateitypen',
  categories: 'Kategorien',
  city: 'Stadt',

  deputyTemplate: 'Vorlagen der zu vertretenen Personen',
  noDeputyTemplate: 'Ihre zu vertretenen Personen haben keine Vorlagen erstellt oder Sie vertreten keine Person.',

  /*Footer*/
  faq: 'FAQ',
  glossary: 'Glossar',
  dataProtection: 'Datenschutz',
  legalDisclosure: 'Impressum',
  contactSupport: 'Kontakt aufnehmen',
  reportFault: 'Störung melden',

  /*Spaltennamen*/
  id: 'Vorgangs-Nr.',
  applicant: 'Antragsteller',
  author: 'Antragsteller',
  authorId: 'Antragsteller',
  created: 'Erstellt',
  state: 'Status',
  amount: 'Betrag',
  form: 'Formular',
  actions: 'Aktionen',
  clearFilters: 'Filter zurücksetzen',
  exportWithFilter: 'mit Filter exportieren',
  lastModified: 'letzte Änderungen',
  recipient: 'Empfänger',
  recipientName: 'Empfänger',
  sapReferenceNumber: 'SAP-Buchungsnr.',
  currentArrangerId: 'aktueller Bearbeiter',

  /* meine Vorgänge*/
  myActions: 'Meine Vorgänge',
  representedProcesses: 'zu vertretende Vorgänge',
  allOperations: 'alle Vorgänge',

  /* Bewirtungen */
  open: 'öffnen',
  openCatering: 'offene Bewirtungen',
  cateringInProgress: 'Bewirtungen in Arbeit',
  cateringAsDeputy: 'zu vertretende Bewirtungen',
  finishedCatering: 'abgeschlossene Bewirtungen',
  cateringStart: 'Bewirtungsbeginn',
  cateringEnd: 'Bewirtungsende',
  room: 'Raum',
  participantCount: 'Teilnehmeranzahl',
  collectionByAuthor: 'Abholung / Lieferung',
  collection: 'Abholung',
  delivery: 'Lieferung',
  articleUpdate: 'Die Artikel Ihrer Vorlage wurden aktualisiert. Bitte prüfen Sie die Einträge.',

  /* Freigabe*/
  myApprovals: 'meine offenen Freigaben',
  representedApprovals: 'offene zu vertretende Freigaben',
  finishedApprovals: 'abgeschlossene Freigaben',
  allApprovals: 'alle freizugebene Vorgänge',

  /*ToolTips & Titel*/
  laterVersion: 'Diese Funktion ist aktuell noch nicht verfügbar.',
  view: 'Ansehen',
  editFormConfig: 'Formularkonfiguration bearbeiten',
  infoIconText: 'weiterführende Informationen',
  selectStaffOutingAdvance: 'Auswahl Vorschuss',

  /*Meldungen*/
  severalIbans: 'Der Kreditor besitzt mehrere IBAN-Angaben. Bitte wählen Sie eine aus.',
  validationError: 'Bitte überprüfen Sie Ihre Eingaben.',
  sessionError: 'Sie sind nicht angemeldet.',
  unknownError: 'Es ist ein Fehler aufgetreten. Sollte dieses Problem wiederholt auftreten, wenden Sie sich bitte an Ihren' +
    ' Administrator.',
  formSaveError: 'Das Formular konnte nicht gespeichert werden, bitte prüfen.',
  formSendError: 'Das Formular konnte nicht gesendet werden.',
  formSaveSuccess: 'Das Formular wurde gespeichert.',
  formSendSuccess: 'Formular wurde abgesendet.',
  formConfigActionError: 'Fehler beim Laden der Formular-Konfiguration.',
  RequestSizeToLargeError: 'Aus technischen Gründen darf die Gesamtgröße aller neuen Anhänge 10MB nicht überschreiten. Bitte entfernen'
    + ' Sie Anhänge bis die Gesamtgröße 10MB unterschreitet und speichern Sie den Vorgang zwischen. Im Anschluss können Sie den Vorgang'
    + ' erneut öffnen und weitere Anhänge hinzufügen.',
  cancelProposalSuccess: 'Vorgang wurde storniert',
  deleteConfigItemSuccess: 'Konfigurationselement wurde erfolgreich gelöscht.',
  coreCompanyFormActionSaveSuccess: 'Gesellschaft erfolgreich gespeichert.',
  accountDeputiesSaveSuccessAction: 'Vertreter erfolgreich gespeichert',
  accountDeputiesLoadedActionError: 'Laden der Vertreter fehlgeschlagen',
  accountDeputiesSaveActionError: 'Speichern der Vertreter fehlgeschlagen',
  coreConfigLedgerAccountsSaveSuccess: 'Sachkonten erfolgreich gespeichert',
  coreConfigTaxRatesSaveSuccess: 'Steuersatz erfolgreich gespeichert',
  coreConfigSalutationsSaveSuccess: 'Anreden erfolgreich gespeichert',
  coreAccountFormActionSaveSuccess: 'Nutzerdaten gespeichert',
  coreAccountFormActionSaveError: 'Nutzerdaten konnten nicht gespeichert werden',
  coreAccountDeleteCostCenterSuccess: 'Kostenstelle gelöscht',
  coreAccountDeleteDefaultArrangerSuccess: 'Genehmiger entfernt',
  coreConfigCateringOrderDataSaveSuccess: 'Bestelldaten wurden erfolgreich gespeichert.',
  coreConfigFaqSaveSuccess: 'FAQs wurden erfolgreich gespeichert.',
  coreConfigCateringOrderDataSaveError: 'Beim Speichern der Bestelldaten ist ein Fehler aufgetreten.',
  coreConfigCateringOrderDataLoadError: 'Fehler beim Laden der Bestelldaten',
  tableConfigSaved: 'Tabelleneinstellungen wurden gespeichert',
  formConfigSaved: 'Formularkonfiguration wurde gespeichert',
  serviceCenterSaved: 'Service Center wurden gespeichert.',
  categoryInfoSaved: 'Kategorieinformation wurde gespeichert',
  missingFileUploadCategory: i9e`Bitte wählen Sie eine Kategorie. Es muss ein Anhang der folgenden Kategorie vorhanden sein: ${'categories'}`,
  wrongBeneficiaryMimeType: 'Nur PDFs können an den Leistungsempfänger gesendet werden.',
  invalidMimeType: i9e`${(t) => t['category'] ?
    'Für ' + t['category'] :
    'Es'} sind ${'allowed'} erlaubt, aktuell ist eine ${'type'} angehangen.`,
  maxTotalUploadSize: i9e`Die gesamt Größe aller neuen Anhänge darf ${'maxSize'} nicht überschreiten.`,
  maxPayoutHint: i9e`Der maximale Auszahlungsbetrag pro Mitarbeiter beträgt ${'maxPayoutPerPerson'} €.`,
  accountingContactHint: i9e`<b>Kontakt zum Bearbeiter im Rechnungswesen:</b> ${'contact'}`,
  emptyDashboardForm: 'Keine Formulare zur ausgewählten Kategorie gefunden.',
  favoriteActionSaveSuccess: 'Favorit wurde angelegt',
  templateActionSaveSuccess: 'Vorlage wurde angelegt',
  viewSaveSuccess: 'Ansicht gespeichert',
  favoriteActionDeleteSuccess: 'Favorit wurde gelöscht',
  templateActionDeleteSuccess: 'Vorlage wurde gelöscht',
  approvalNotAllowed: 'Sie sind nicht berechtigt, dieses Formular freizugeben oder abzulehnen.',
  formRejectSuccess: 'Formular wurde abgelehnt.',
  formApprovedSuccess: 'Formular wurde freigegeben',
  templateSaveSuccess: 'Vorlage erfolgreich gespeichert',
  deleteProposalData: 'Vorgang wurde gelöscht',
  sendCommentSuccess: 'Kommentar gespeichert',
  coreConfigSystemMessageAdminLoadSuccess: 'Systemmeldung wurde geladen',
  coreConfigSystemMessageAdminLoadError: 'Fehler beim Laden der Systemmeldung',
  coreConfigSystemMessageSaveSuccess: 'Systemmeldung wurde gespeichert',
  coreConfigSystemMessageSaveError: 'Systemmeldung konnte nicht gespeichert werden.',
  coreConfigLocationRoomSaveSuccess: 'Raumliste wurde erfolgreich gespeichert',
  coreConfigLocationArticleSaveSuccess: 'Artikelliste wurde erfolgreich gespeichert',
  formConfigCompanyDeleteSuccess: 'Gesellschaft für dieses Formular gelöscht',
  maxPayoutError: i9e`Der max. Betrag liegt bei ${'maxPayout'}€`,
  recallProposalSuccess: 'Vorgang wurde storniert.',
  cancelWfsSuccess: 'Vorgang wurde storniert.',
  ibanInvalidError: 'IBAN ist ungültig.',
  mandatoryError: 'Bitte füllen Sie das Pflichtfeld aus.',
  requiredError: 'Bitte füllen Sie das Pflichtfeld aus.',
  regexError: 'Bitte das Format beachten',
  minError: i9e`Minimal zulässiger Wert ist ${'min'}`,
  maxError: i9e`Maximal zulässiger Wert ist ${'max'}`,
  maxLengthError: i9e`Es sind nur max. ${'maxLength'} Zeichen erlaubt`,
  weekendsNotAllowedError: 'Wochenenden sind nicht zulässig',
  maxDateError: 'Datum überschreitet das erlaubte Enddatum', // TODO: I18n von Datumsen?
  minDateError: 'Datum unterschreitet das erlaubte Startdatum', // TODO: I18n von Datumsen?
  maxDateCurrentDateError: 'Datum darf nicht nach dem aktuellem Datum liegen',
  minDateCurrentDateError: 'Datum darf nicht vor dem aktuellem Datum liegen',
  fuvNoPrefixError: 'Das FuV-Präfix fehlt',
  fuvCimdIdMissingError: 'Die CIMD fehlt',
  fuvCimdIdMismatchError: 'Die CIMD passt nicht zur ausgewählten Gesellschaft',
  fuvYearError: 'Jahresangabe fehlt, Bsp.: ' + ('' + (new Date()).getFullYear()).slice(2),
  fuvLastNumberError: 'Die laufende Nummer ist nicht 6-Stellig',
  fuvOld: 'Datenübernahme nur aus FuV 2.0 möglich',
  creditDebitNotEqual: 'Soll ungleich Haben',
  creditDebitEqual: 'Soll gleich Haben',
  noDateError: 'Nur Datumseingaben erlaubt.',
  deactivatedAmsError: 'Geben Sie einen Grund an warum AMS nicht benötigt wird',
  maxLengthHint: i9e`${'length'} / ${'maxLength'}`,
  articleNotAvailable: 'Der ausgewählte Artikel ist nicht mehr verfügbar',

  formU1VoucherDataNotFoundError: 'Beleg wurde nicht gefunden',
  formU1VoucherDataLoadError: 'Fehler beim Laden des Belegs',
  formK3OrderDataNotFoundError: 'Rahmenbestellung wurde nicht gefunden',
  formK3OrderDataLoadError: 'Fehler beim Laden der Rahmenbestellung',
  voucherAuthorNoMail: 'E-Mail-Adresse des Erfassers konnte nicht ermittelt werden. Benachrichtigung nicht möglich.',
  /*Dialogtexte*/
  k1Title: 'Bitte beachten',
  deleteTaxRateTitle: 'Steuersatz löschen',
  deleteTaxRateText: 'Wollen Sie wirklich diesen Steuersatz löschen?',
  deleteSalutationTitle: 'Anrede löschen',
  deleteSalutationText: 'Wollen Sie diese Anrede wirklich löschen?',
  deleteLedgerAccountTitle: 'Sachkonto löschen',
  deleteLedgerAccountText: 'Wollen Sie wirklich dieses Sachkonto löschen?',
  deleteCompanyTitle: 'Gesellschaft löschen',
  deleteCompanyText: 'Wollen Sie diese Gesellschaft wirklich löschen?',
  deleteTemplateTitle: 'Vorlage löschen',
  deleteTemplateText: 'Wollen Sie die Vorlage wirklich löschen?',
  removeCostCenterTitle: 'Kostenstelle löschen',
  removeCostCenterText: 'Wollen Sie diese Kostenstelle wirklich löschen?',
  removeArrangerTitle: 'Genehmiger löschen',
  removeArrangerText: 'Wollen Sie diesen Genehmiger wirklich löschen?',
  deleteProposalTitle: 'Vorgang löschen',
  deleteProposalText: 'Wollen Sie den Vorgang wirklich löschen?',
  rejectProcess: 'Vorgang ablehnen',
  showComment: 'Kommentar anzeigen',
  rejectProcessMessage: 'Bitte geben Sie eine Begründung an, warum Sie diesen Vorgang ablehnen möchten.',
  rejectProcessMultipleMessage: i9e`Wollen Sie wirklich Vorgänge im Wert von ${'amount'} ablehnen?
  Bitte geben Sie eine Begründung an, warum Sie diese Vorgänge ablehnen möchten.
  Der eingegebene Kommentar wird für alle ausgewählten Vorgänge verwendet.`,
  approveProcess: 'Vorgang freigeben',
  approveProcessMessage: 'Wollen Sie den Vorgang wirklich freigeben?',
  approveProcessMultipleMessage: i9e`Wollen Sie wirklich Vorgänge im Wert von ${'amount'} freigeben?`,
  infoApproveNoRejectMessage: 'Bitte geben Sie eine Begründung an.',
  rejectDialogTitle: 'Begründung der letzten Ablehnung',
  rejectHistoryTitle: 'Ablehnungshistorie',
  backendErrorMessageTitle: 'SAP-Fehlermeldung',
  saveTemplateProcess: 'Vorlage speichern',
  saveTemplateProcessMessage: 'Bitte geben Sie einen Namen für die Vorlage ein.',
  saveTemplateDialogTitel: 'Als Vorlage speichern',
  cateringCancelTitle: 'Bewirtung stornieren',
  proposalCancelTitle: 'Vorgang stornieren',
  cateringCancelText: 'Wollen Sie die Bewirtung wirklich stornieren?',
  proposalCancelText: 'Wollen Sie den Vorgang wirklich stornieren?',
  userNotUnique: 'Nutzer ist bereits vorhanden',
  needsToBeEmployeeInParticipantList: 'Nutzer muss als Mitarbeiter in der Teilnehmerliste enthalten sein.',
  minStartInFutureError: 'muss später beginnen, frühestens',
  minRangeLengthError: 'muss später enden, frühestens',
  maxRangeLengthError: 'muss früher enden, spätestens',
  maxStartInFutureError: 'muss früher beginnen, spätestens',
  timeMin: 'minimal auswählbare Start-Tageszeit (HH:mm)',
  timeMax: 'maximal auswählbare Start-Tageszeit (HH:mm)',
  timeMinError: 'Startzeit zu früh, frühestens',
  timeMaxError: 'Startzeit zu spät, spätestens',
  weekendsActiveError: 'Wochenenden sind nicht erlaubt',
  startMandatoryError: 'Startzeitpunkt ist eine Pflichteingabe',
  endMandatoryError: 'Endzeitpunkt ist eine Pflichteingabe',
  endBeforeStartError: 'Der Endzeitpunkt muss nach dem Startzeitpunkt liegen',
  endTimeAfter4Pm: 'Sollten Bewirtungen nach 16:00 Uhr stattfinden, dann wird eine Servicepauschale mit angerechnet.',

  productGroupNotAllowedError: 'Diese Warengruppe ist für den Kleinbestellschein nicht erlaubt. Verwenden Sie hierfür den geeigneten'
    + ' Bestellprozess.',

  searchPersonToRegister1: 'Sie haben die Person nicht gefunden?',
  searchPersonToRegister2: ' kontaktieren, damit diese sich registriert.',
  here: 'Hier',
  registerAtEasy: 'Registrierung im EASy-Portal',
  registerAtEasyText: 'Eine Person versucht, Sie über die Personensuche innerhalb des EASY-Portals zu finden. '
    + 'Bitte registrieren Sie sich im EASY-Portal unter folgendem Link, um gefunden zu werden: ',

  mailReportFaultSubject: 'EASy4All: Fehlermeldung',
  mailReportFault: 'Meldung einer Störung der Anwendung / des Prozesses im EASY-Portal \r\n\r\n' +
    'Fehlerzeitpunkt (Datum und Uhrzeit): \r\n' +
    'Geben Sie Ihren Nutzernamen an: \r\n' +
    'Welche Fehlermeldung wird ausgegeben: \r\n' +
    'Welcher Browser wird verwendet und welche Version davon: \r\n' +
    'Bei welcher Anwendung/Prozess ist der Fehler aufgetreten: \r\n' +
    'Haben Sie noch weitere Anwendungen geöffnet? Welche: \r\n' +
    'Wie lange ist die aktuelle Sitzung geöffnet: \r\n' +
    'Wie ist der Fehler entstanden (ggf. Klickpfad angeben): \r\n' +
    'URL/Screenshot von der gesamten Anwendung: \r\n',

  editNewConfig: 'neue Konfiguration bearbeiten',
  deleteSelected: 'ausgewählte löschen',
  hasNoForms: 'Aktuell gibt es keine Formulare.',
  add: 'Hinzufügen',
  saveAndSendLater: 'Zwischenspeichern',
  cancelProcess: 'Bewirtung stornieren',
  saveAndApproveLater: 'Speichern & später genehmigen',
  saveTemplate: 'Vorlage speichern',
  send: 'Absenden',
  sendToBeneficiary: 'an den Leistungsempfänger senden',
  payee: 'Zahlungsempfänger',
  invoiceDetails: 'Rechnungsangaben',
  approve: 'Freigeben',
  reject: 'Ablehnen',
  check: 'Prüfen',
  ocrTitle: 'Rechnung automatisch erfassen',
  ocrGuidelines: 'Hier haben Sie die Möglichkeit, eine vorhandene Rechnung automatisch vorerfassen zu lassen. <br/><br/>' +
    'Bitte beachten Sie hierbei, dass nicht alle Felder ausgefüllt werden können. <br/>' +
    'Überprüfen Sie vor dem Absenden bitte die Eingaben genau und korrigieren Sie eventuelle Fehler. <br/>',
  ocrUploadButton: 'Rechnung hochladen...',
  ocrScanButton: 'Scannen',
  ocrAbortButton: 'Abbrechen',
  ocrScanFailed: 'Die Rechnung konnte leider nicht ausgelesen werden. Bitte geben Sie Ihre Rechnungsdaten manuell ein.',
  ocrScanSuccess: 'Scan erfolgreich abgeschlossen. Bitte Vervollständigen Sie das Formular.',
  ocrScreenshot: 'Bild aufnehmen...',
  ocrRetakeScreenshot: 'Bild erneut aufnehmen',
  ocrCamError: 'Kamerazugriff fehlgeschlagen. Bitte überprüfen Sie Ihre Einstellungen.',
  fuvInAcquisition: 'Der Vorgang ist im FuV-Tool noch nicht abgeschlossen, ggfs könnten noch Änderungen entstehen.',
  FUV_NOT_FOUNDError: 'Der angegebene FuV-Vorgang wurde nicht gefunden.',
  USER_NOT_ALLOWEDError: 'Sie sind nicht berechtigt diesen FuV-Vorgang aufzurufen.',
  UNKNOWNError: 'Beim abrufen des FuV-Vorgangs ist ein Fehler aufgetreten.',
  fuvInfoTitle: 'FuV-Abrechnung',
  fuvInfoText: 'Für weitere Fragen rund um das Thema FuV-Tool wenden Sie sich bitte an <a href="mailto:taxdirect@eon.com">taxdirect@eon.com</a>',

  fackq: 'FAQ pflegen',
  section: 'Abschnitt / Überschrift',
  question: 'Frage',
  answer: 'Antwort',

  inheritFrom: 'Erben von',

  createReceipt: 'Gutschrift erstellen',
  taxNumber: 'Steuernummer',
  district: 'Ortsteil',
  timePeriod: 'gewünschter Zeitraum',

  /*Formularkonfiguration */
  decimalPlacesPlaceholder: 'Max Nachkommastellen (-1 = unendlich)',
  type: 'Typ',
  ARBITRARY: 'beliebig',
  PRE_DEFINED: 'vordefiniert',
  GROUPED: 'gruppiert',

  CACHED: 'Zwischengespeichert',
  SAVED: 'Gespeichert',
  IN_APPROVAL: 'Zur Freigabe',
  IN_INSPECT: 'Zur Prüfung',
  IN_FURTHER_PROCESSING: 'In Weiterbearbeitung',
  WAITING: 'Wartend',
  WAITING_FOR_DEDUCTION: 'Warten auf Endabrechnung',
  WAITING_FOR_SIGNATURE: 'Warten auf Unterschrift',
  WAITING_FOR_BILLING: 'Warten auf Rechnungslegung',
  RECALLED: 'Vorgang storniert',
  APPROVED: 'Freigegeben',
  SEND_FAILED: 'Senden fehlgeschlagen',
  SAP_ERROR: 'Datenfehler',
  DECLINED: 'Abgelehnt',
  FINISHED: 'Abgeschlossen',
  INITIAL: 'Angelegt',
  CATERING_APPROVAL: 'Genehmigung durch Caterer',
  CATERING_IN_PROGRESS: 'Bewirtung in Arbeit',
  CATERING_DECLINED: 'Bewirtung abgelehnt',
  CATERING_COMPLAINED: 'Bewirtung reklamiert',
  CATERING_APPROVAL_BY_AUTHOR: 'Genehmigung durch Antragssteller',
  CATERING_COMPLETE: 'Bewirtung abgeschlossen',
  CATERING_CANCELED: 'Catering storniert',
  CANCELED_BY_CATERER: 'Abgebrochen durch Caterer',
  TEMPLATE_SAVE: 'Vorgang gespeichert',

  SAP_SEARCH: 'SAP-Suche',
  SELECTION: 'Liste aus Admin-Bereich',
  PREDEFINED: 'Vorausgefüllt',

  COST_CENTER: 'Kostenstelle',
  ORDER: 'Auftrag',
  PSP_ELEMENT: 'PSP-Element',
  FIXED_ASSET: 'Anlage',

  /* Infotexte zu AF17*/
  deduction: '• Aus buchungstechnischen Gründen werden vom Vorschussanspruch 5,00 € abgezogen.',
  refund: '• Bei Abrechnung des Vorschusses erfolgt die Erstattung der 5,00 €.',

  /*Texte für K2*/
  productGroupMissing: 'Warengruppe nicht mehr vorhanden. Bitte pflegen Sie diese nach.',
  fkInfoTitledetailsAndPaymentAmount: 'Erläuterung zu den Positionsdetails',
  fkInfoMessagedetailsAndPaymentAmount: `<p style="margin-top:0"><b>AMS relevant?</b></p><p> Der Haken wird bei Erfordernis automatisch gesetzt. Bei der Beschaffung von gefahrgeneigten Tätigkeiten wird ein zertifiziertes Arbeitsschutzmanagementsystem (AMS) von den Dienstleistern gefordert. Bei manuellem Entfernen des Hakens ist eine Begründung obligatorisch.</p>
<p><b>Datenschutzrelevanz</b></p><p> Der Haken ist zu setzen, wenn der Beschaffungsvorgang datenschutzrelevant gemäß OR 1-70 ist. Es sind alle erforderlichen Dokumente unter Anlagen hinzuzufügen.</p>
<p><b>ISMS relevant</b></p><p> Der Haken ist zu setzen, für Beschaffungsvorgänge im Bereich der Prozessdatenverarbeitung (PDV-ISMS) inklusive SMGA (Smart Meter Gateway Administration).</p>
<p><b>Dienstleistung</b></p><p> Der Haken ist zu setzen, wenn es sich um eine Dienstleistung handelt, die mit einem Abnahmeprotokoll bzw. einer Leistungserfassung bestätigt wird.</p>`,

  /*Texte für A4*/
  invoiceAttachmentMissing: 'Rechnung als Anhang nicht vorhanden. Bitte pflegen Sie diese nach.',

  /*Texte für U1*/
  voucherInfo: 'Bitte geben Sie im Schritt: "Belegauswahl" das Geschäftsjahr und die Belegnummer an.',

  serviceCenterMaintenance: 'Service Center pflegen',
  categoryInfos: 'Kategorieinformation pflegen',

  /*Systemmeldung-Infotexte */
  systemMessage: 'Systemmeldung',
  systemMessageDate: '<br><b>Sie haben hier die Möglichkeit eine Systemmeldung anzulegen.</b>'
    + '<br><br>Mit aktivieren der Checkbox, wird Ihre Systemmeldung auf der Startseite des Easy-Portals angezeigt.'
    + '<br><br>Desweiteren haben Sie hier die Möglichkeit anzugeben, wie lange Ihre Systemmeldung angezeigt werden soll. <br><br>',

  /*Systemmeldung-Infotexte */
  ruleForCatering: 'Bedingungen für Bewirtung',
  ruleForCateringInfo: '<br><b>1.</b> Eine Bestellung muss mindestens 18h vorher erstellt werden.'
    + '<br><b>2.</b> Der Beginn einer Bewirtung, muss zwischen 8:00 Uhr und 16:00 Uhr sein.<br><br>',

  /*Formularkonfiguration-Infotexte */
  fkInfoTitlepayeeNumber: 'Kreditor- / Debitornummer',
  fkInfoMessagepayeeNumber: '<br>Die Kreditor- / Debitornummer kann hier fest hinterlegt werden, so dass sie vom Nutzer nicht verändert werden kann.'
    + '<br> Sie kann allerdings auch manuell vom Nutzer hinzugefügt werden, oder es wird die Suche innerhalb des Formulars verwendet.'
    + '<br><br>',
  fkInfoTitlesalutation: 'Anrede',
  fkInfoMessagesalutation: '<br>Es stehen verschiedenen Anreden zur Auswahl.'
    + '<br> Die Anreden können innerhalb der Administration, unter dem Punkt "Anreden" '
    + 'verändert, gelöscht oder hinzugefügt werden.'
    + '<br><br>',
  fkInfoTitlename: 'Name',
  fkInfoMessagename: '<br>In dieses Feld sollten Vor- und Nachname eingetragen werden. Dies kann manuell erfolgen, '
    + 'oder man verwendet die Nutzersuche innerhalb des Formulars.'
    + '<br> Verwendet man die Nutzersuche, werden automatisch alle Felder, für die Informationen '
    + 'zu diesem Nutzer vorhanden sind, gefüllt.'
    + '<br><br>',
  fkInfoTitlestreet: 'Straße',
  fkInfoMessagestreet: '<b>Straße:</b>'
    + '<br>In dieses Feld sollte die Straße eingetragen werden.'
    + '<br><br>',
  fkInfoTitlezipCode: 'Postleitzahl',
  fkInfoMessagezipCode: '<br>In dieses Feld sollte die Postleitzahl eingetragen werden.'
    + '<br><br>',
  fkInfoTitlecity: 'Stadt',
  fkInfoMessagecity: '<br>In dieses Feld sollte die Stadt eingetragen werden.'
    + '<br><br>',
  fkInfoTitlecountry: 'Land',
  fkInfoMessagecountry: '<br>Hier sollte das Land ausgewählt werden. Dafür kann man mit Hilfe der Liste ein Land auswählen.'
    + '<br>Als Standardwert verwenden Sie bitte die ISO Alpha-2 Länderkennungen, z.B.: <br>'
    + '<ul>'
    + '<li>DE - Deutschland</li>'
    + '<li>FR - Frankreich</li>'
    + '<li>GB - Großbritannien</li>'
    + '</ul>'
    + '<br><br>',
  fkInfoTitleemail: 'E-Mail',
  fkInfoMessageemail: '<br>In dieses Feld sollte die E-Mail eingetragen werden.'
    + '<br><br>',
  fkInfoTitleiban: 'IBAN',
  fkInfoMessageiban: '<br>In dieses Feld sollte die IBAN eingetragen werden. Ist die IBAN keine deutsche IBAN (DE am Anfang), '
    + 'wird die BIC automatisch zum Pflichtfeld und muss angegeben werden.'
    + '<br><br>',
  fkInfoTitlebic: 'BIC',
  fkInfoMessagebic: '<br>In dieses Feld sollte die BIC eingetragen werden. Ist die IBAN keine deutsche IBAN (DE am Anfang), '
    + 'wird die BIC automatisch zum Pflichtfeld und muss angegeben werden.'
    + '<br><br>',
  fkInfoTitleuseCase: 'Verwendungszweck',
  fkInfoMessageuseCase: '<br>In dieses Feld sollte der Verwendungszweck eingetragen werden.'
    + '<br><br>',
  fkInfoTitlegroundNumber: 'Anlagennummer',
  fkInfoMessagegroundNumber: '<br>In dieses Feld soll die Anlagennummer eingetragen werden.'
    + '<br><br>',
  fkInfoTitledescription: 'Beschreibung',
  fkInfoMessagedescription: '<br>In dieses Feld sollte die Beschreibung eingetragen werden.'
    + '<br><br>',
  fkInfoTitleevent: 'Auslage für Veranstaltungen',
  fkInfoMessageevent: '<br>Wenn "Auslage für Veranstaltungen" angehakt wurde, ist die FUV-Nummer automatisch Pflicht und muss mit angegeben werden.'
    + '<br><br>',
  fkInfoTitlefuvNumber: 'FUV-Nummer',
  fkInfoMessagefuvNumber: '<br>Wenn "Auslage für Veranstaltungen" angehakt wurde, ist die FUV-Nummer automatisch Pflicht und muss mit angegeben werden.'
    + '<br><br>',
  fkInfoTitlepositions: 'Positionen',
  fkInfoMessagepositions: 'Hier kann die Positionstabelle eingestellt werden.'
    + '<br><br>',
  fkInfoTitlepositionName: 'Positionsbeschreibung',
  fkInfoMessagepositionName: '<br>In dieses Feld sollte die Positionsbeschreibung eingetragen werden.'
    + '<br><br>',
  fkInfoTitlecommentForApprover: 'Kommentar für Genehmiger',
  fkInfoMessagecommentForApprover: '<br>In dieses Feld kann ein Kommentar für den Genehmiger eingegeben werden.',
  fkInfoTitlecoverLetterText: 'Text des Anschreibens',
  fkInfoMessagecoverLetterText: '<br>Innerhalb der Formularkonfiguration kann ein Text hinterlegt werden, '
    + 'welcher innerhalb des Dokuments angezeigt wird.',
  fkInfoTitleaddCoverLetter: 'Checkbox Anschreiben',
  fkInfoMessageaddCoverLetter: '<br>Eine Checkbox die angibt, ob man ein Anbschreiben anfügen möchte.'
    + ' Im Formular öffnet sich nach betätigen der Checkbox ein Texteditor.',
  fkInfoTitlequantity: 'Menge',
  fkInfoMessagequantity: '<br>In dieses Feld sollte die Menge eingetragen werden.'
    + '<br><br>',
  fkInfoTitlenetCost: 'Netto',
  fkInfoMessagenetCost: '<br>In dieses Feld sollte der Nettobetrag eingetragen werden. '
    + '<br>Wurde der Netto Betrag zuerst gesetzt, so rechnet sich der Bruttobetrag aus dem Netto und dem angegebenen Steuersatz. '
    + '<br>Wurde der Bruttobetrag zuerst gesetzt, dann berechnet sich der Nettobetrag aus dem Brutto und dem angegebenen Steuersatz.'
    + '<br><br>',
  fkInfoTitletaxRate: 'Steuersatz',
  fkInfoMessagetaxRate: '<br>Hier kann man einen Steuersatz auswählen. '
    + '<br>Einen neuen Steuersatz hinzufügen oder einen bestehenden ändern, '
    + 'kann man innerhalb des Administrationsbereichs unter "Steuersätze". <br>Vom Steuersatz aus werden andere Felder berechnet. '
    + '<br>Wurde der Netto Betrag zuerst gesetzt, so rechnet sich der Bruttobetrag aus dem Netto und dem angegebenen Steuersatz. '
    + '<br>Wurde der Bruttobetrag zuerst gesetzt, dann berechnet sich der Nettobetrag aus dem Brutto und dem angegebenen Steuersatz.'
    + '<br><br>',
  fkInfoTitlegrossCost: 'Brutto',
  fkInfoMessagegrossCost: '<b>Brutto:</b>'
    + '<br>In dieses Feld sollte der Bruttobetrag eingetragen werden. '
    + '<br>Wurde der Bruttobetrag zuerst gesetzt, dann berechnet sich der Nettobetrag aus dem Brutto und dem angegebenen Steuersatz. '
    + '<br>Wurde der Netto Betrag zuerst gesetzt, so rechnet sich der Bruttobetrag aus dem Netto und dem angegebenen Steuersatz.'
    + '<br><br>',
  fkInfoTitletaxValue: 'Steuerbetrag',
  fkInfoMessagetaxValue: '<br>In dieses Feld sollte der Steuerbetrag eingetragen werden. '
    + '<br>Wurde der Bruttobetrag und der Steuersatz gesetzt, errechnet sich der Steuerbetrag automatisch aus den angegebenen Werten. '
    + '<br>Wird der Steuersatz dann nachträglich verändert, so wird der Bruttobetrag neu berechnet.'
    + '<br><br>',
  fkInfoTitleaccount: 'Kontierung',
  fkInfoMessageaccount: '<br>In diesem Feld sollte die Kontierung eingetragen werden. '
    + '<br>Es wird automatisch eine Suche stattfinden, nachdem man etwas in dieses Feld eingegeben hat. '
    + 'Wurde etwas gefunden, erscheint unter dem Feld eine Auswahlliste. Aus dieser Liste kann man die passende Kontierung auswählen.'
    + '<br><br>',
  fkInfoTitleledgerAccount: 'Sachkonto',
  fkInfoMessageledgerAccount: '<br>Hier hat man die Möglichkeit ein Sachkonto auszuwählen. '
    + '<br>Ein Sachkonto hinzufügen, ändern oder löschen, ist innerhalb der '
    + 'Administration möglich, unter dem Punkt "Sachkonto". Dort werden alle bisherigen Sachkonten aufgelistet.'
    + '<br><br>',
  fkInfoTitlepayout: 'Auszahlungsbetrag',
  fkInfoMessagepayout: '<br>Der Auszahlungsbetrag wird automatisch aus den Angaben der Tabelle berechnet.'
    + '<br><br>',
  fkInfoTitleapprover: 'Genehmiger',
  fkInfoMessageapprover: '<br>Hier können Genehmiger festgelegt werden. <br>Es ist möglich mehrere Genehmiger festzulegen. '
    + 'Zudem hat man die Möglichkeit, einen Schwellwert für einen Genehmiger anzugeben, '
    + 'd.h. ab einem bestimmten Auszahlungsbetrag/Bruttowert/Nettowert, wird ein bestimmter Genehmiger automatisch aktiviert und eingetragen.'
    + '<br> Auch die Reihenfolge der Genehmiger kann hier festgelegt werden.'
    + '<br><br>',
  fkInfoTitleinspector: 'Prüfer',
  fkInfoMessageinspector: '<br>Hier können Prüfer festgelegt werden. <br>Es ist möglich mehrere Prüfer festzulegen. '
    + 'Zudem hat man die Möglichkeit, einen Schwellwert für einen Prüfer festzulegen, d.h. ab einem bestimmten Auszahlungsbetrag/Bruttowert/Nettowert, '
    + 'wird ein bestimmter Prüfer automatisch aktiviert und eingetragen. '
    + '<br>Auch die Reihenfolge der Prüfer kann hier festgelegt werden.'
    + '<br><br>',
  fkInfoTitlefile: 'Dokument anhängen',
  fkInfoMessagefile: '<br>Hier können erforderliche Dokumente hochgeladen werden .'
    + '<br>Es kann festgelegt werden, welche und wie viele Dokumente erforderlich sind, bzw. maximal angegeben werden dürfen. '
    + 'Das heißt die jeweiligen Katergorien lassen sich hier festlegen.'
    + '<br><br>',
  fkInfoTitledateRange: 'Start/End-Zeitpunkt',
  fkInfoMessagedateRange: '<br>Der Nutzer kann Start- und Endzeitpunkt anhand von Datum und Zeit angeben. '
    + '<br>Hier kann konfiguriert werden, welche Relationen zwischen den Zeitpunkten gelten. '
    + '<br><br>',
  fkInfoTitletaxNumber: 'Steuernummer',
  fkInfoMessagetaxNumber: 'Hier kann die Steuernummer festgelegt werden.' +
    '<br> Es ist fest hinterlegt, dass entweder eine Ust-Id angeben werden muss, oder eine Steuernummer.' +
    '<br> Dies kann nicht konfiguriert werden. Eines der beiden Felder muss ausgefüllt werden.',
  fkInfoTitleustId: 'Ust-Id',
  fkInfoMessageustId: 'Hier kann die Ust-Id festgelegt werden.' +
    '<br> Es ist fest hinterlegt das entweder eine Ust-Id angeben werden muss, oder eine Steuernummer.' +
    '<br> Dies kann nicht konfiguriert werden. Eines der beiden Felder muss ausgefüllt werden.',
  fkInfoTitleassignment: 'Zuordnung',
  fkInfoMessageassignment: 'Das Feld Zuordnung kann durch einen Prüfer gepflegt werden.'
    + ' Dazu muss das Feld als \'Sichtbar\' definiert sein und für die entsprechende Rolle als bearbeitbar definiert werden.',
  fkInfoTitledifferentBillingAddress: 'Abweichende Rechnungsadresse',
  fkInfoMessagedifferentBillingAddress: 'Hier kann eingestellt werden ob es eine abweichende Rechnungsadresse gibt.',
  fkInfoTitlenameBA: 'Name der abweichenden Rechnungsadresse',
  fkInfoMessagenameBA: 'Hier kann der Name der abweichenden Rechnungsadresse eingetragen werden.',
  fkInfoTitlenameAdditionBA: 'Namenszusatz der abweichenden Rechnungsadresse',
  fkInfoMessagenameAdditionBA: 'Hier kann der Namenszusatz der abweichenden Rechnungsadresse konfiguriert werden.',
  fkInfoTitlestreetBA: 'Straße der abweichenden Rechnungsadresse',
  fkInfoMessagestreetBA: 'Hier kann die Straße der abweichenden Rechnungsadresse konfiguriert werden.',
  fkInfoTitlezipCodeBA: 'Postleitzahl der abweichenden Rechnungsadresse',
  fkInfoMessagezipCodeBA: 'Hier kann die Postleitzahl der abweichenden Rechnungsadresse konfiguriert werden.',
  fkInfoTitlecityBA: 'Ort der abweichenden Rechnungsadresse',
  fkInfoMessagecityBA: 'Hier kann der Ort der abweichenden Rechnungsadresse konfiguriert werden.',
  fkInfoTitlelocation: 'Standort',
  fkInfoMessagelocation: 'Hier kann der Standort festgelegt werden.',
  fkInfoTitleparticipantCount: 'Teilnehmeranzahl',
  fkInfoMessageparticipantCount: 'Hier kann z.B. die maximale Teilnehmeranzahl angegeben werden.',
  fkInfoTitleparticipants: 'Liste der Teilnehmer',
  fkInfoMessageparticipants: 'Hier können Einstellungen zur Liste der Teilnehmer, vorgenommen werden.',
  fkInfoTitleparticipantName: 'Name des Teilnehmers',
  fkInfoMessageparticipantName: 'Hier kann der Name der Teilnehmer konfiguriert werden.',
  fkInfoTitleparticipantCompany: 'Firma des Teilnehmers der ein Mitarbeiter ist',
  fkInfoMessageparticipantCompany: 'Hier kann die Firma des Teilnehmers konfiguriert werden.',
  fkInfoTitleparticipantCompanyName: 'Firma des Teilnehmers der kein Mitarbeiter ist',
  fkInfoMessageparticipantCompanyName: 'Hier kann die Firma des Teilnehmers, der kein Mitarbeiter ist, konfiguriert werden.',
  fkInfoTitleisEmployee: 'Flag für Mitarbeiter',
  fkInfoMessageisEmployee: 'Checkbox die definiert, ob ein Teilnehmer ein Mitarbeiter ist.',
  fkInfoTitleemployeeInfo: 'Hinweistext für externe Teilnehmer',
  fkInfoMessageemployeeInfo: 'Hinweistext der angezeigt wird, wenn <ol><li>es möglich ist Teilnehmer als Mitarbeiter zu Kennzeichnen (Feld'
    + ' "isEmployee" ist als "sichtbar" gekennzeichnet)</li><li>diese Feld als "sichtbar" gekennzeichnet ist.</li></ol>Der'
    + ' unter "Standardwert" hinterlegte Text wird für die Anzeige verwendet. Alle weiteren Konfigurationen werden ignoriert. <br><br>Der'
    + ' Text kann in begrenzten Rahmen formatiert werden. Wird ein Teil des Textes als "&lt;i&gt;Text&lt;/i&gt;" angegeben wird dieser dann'
    + ' <i>kursiv</i> formatiert. Mithilfe von "&lt;b&gt;Text&lt;/b&gt;" kann ein Text <b>fett</b> angezeigt. Es ist ebenso möglich'
    + ' beides zu kombinieren &lt;b&gt;&lt;i&gt;Text&lt;/i&gt;&lt;/b&gt;" damit der Text <i><b>kursiv fett</b></i> dargestellt wird.',
  fkInfoTitleparticipantUser: 'Name des Teilnehmers der Mitarbeiter ist',
  fkInfoMessageparticipantUser: 'Nutzersuche für Teilnehmer die Mitarbeiter sind.',
  fkInfoTitleroom: 'Raum',
  fkInfoMessageroom: 'Hier kann der Raum zu einem Standort konfiguriert werden.',
  fkInfoTitledeliveryType: 'Lieferart',
  fkInfoMessagedeliveryType: 'Hier kann die Lieferart konfiguriert werden. Die Lieferart wird im UI als DropDown dargestellt, wird '
    + 'im Hintergrund aber als Flag &bdquo;Abholung durch Autor&rdquo;, mit den Werten Ja / Nein, behandelt.'
    + '<br>Vordefinierte Werte werden wie folgt interpretiert:'
    + '<ul><li><em>collection</em> -&gt; &bdquo;Abholung&rdquo; bzw. &bdquo;Ja&rdquo;</li>'
    + '<li><em>delivery</em> und alle anderen Eingaben -&gt; &bdquo;Lieferung&rdquo; bzw. &bdquo;Nein&rdquo;</li></ul>',
  fkInfoTitlearticles: 'Artikel',
  fkInfoMessagearticles: 'Hier werden die Artikel angezeigt.',
  fkInfoTitlearticleName: 'Artikelname',
  fkInfoMessagearticleName: 'Hier kann der Artikelname konfiguriert werden.',
  fkInfoTitleamount: 'Anzahl',
  fkInfoMessageamount: 'Hier kann die Anzahl der Artikel konfiguriert werden.',
  fkInfoTitleorderedAmount: 'Bestellte Anzahl',
  fkInfoMessageorderedAmount: 'Hier kann die bestellte Anzahl der Artikel eingestellt werden.',
  fkInfoTitledelivery: 'Lieferzeitpunkt',
  fkInfoMessagedelivery: 'Zeitpunkt zu dem die Lieferung stattfinden soll.',
  fkInfoTitledeliveryAmount: 'Gelieferte Anzahl',
  fkInfoMessagedeliveryAmount: 'Hier kann die gelieferte Anzahl der Artikel konfiguriert werden.',
  fkInfoTitleunit: 'Einheit',
  fkInfoMessageunit: 'Hier kann die Einheit für die Artikel eingestellt werden.',
  fkInfoTitleprice: 'Einzelpreis',
  fkInfoMessageprice: 'Hier kann der Einzelpreis für die Artikel konfiguriert werden.',
  fkInfoTitlesum: 'Summe',
  fkInfoMessagesum: 'Hier kann die Summe der Artikel eingestellt werden.',
  fkInfoTitledeliverySum: 'Summe gelieferter Artikel',
  fkInfoMessagedeliverySum: 'Hier kann die Summe der gelieferter Artikel konfiguriert werden.',
  fkInfoTitleorderedSum: 'Summe bestellter Artikel',
  fkInfoMessageorderedSum: 'Hier kann die Summe der bestellter Artikel konfiguriert werden.',
  fkInfoTitlenameAddition: 'Namenszusatz',
  fkInfoMessagenameAddition: 'Hier kann der Namenszusatz eingestellt werden.',
  fkInfoTitledate: 'Datum',
  fkInfoMessagedate: 'Hier kann das Datum konfiguriert werden.',
  fkInfoTitlenumberOfPersons: 'Anzahl der Personen',
  fkInfoMessagenumberOfPersons: 'Hier kann die Anzahl der Personen eingestellt werden.',
  fkInfoTitleleaflet: 'Hinweise zur Abrechnung',
  fkInfoMessageleaflet: 'Hier können Einstellungen zum PDF: Hinweise zur Abrechnung, gemacht werden.',
  fkInfoTitlemaxPayoutPerPerson: 'Maximaler Auszahlungsbetrag pro Person',
  fkInfoMessagemaxPayoutPerPerson: 'Wird verwendet um die Obergrenze des Auszahlungsbetrages (payout) zu berechnen. Hierbei ist'
    + ' nur wichtig das im Feld "Maxium" ein Wert hinterlegt ist. Alle anderen Einstellungen werden ignoriert.',
  fkInfoTitlesubject: 'Betreff',
  fkInfoMessagesubject: 'Der Betreff ist Abweichend vom Verwendungszweck.',
  fkInfoTitleadditionalSubject: 'Zusätzlicher Betreff',
  fkInfoMessageadditionalSubject: 'Ergänzung zum eigentlichen Betreff.',
  fkInfoTitlecontactPerson: 'Ansprechpartner',
  fkInfoMessagecontactPerson: 'Person die im Falle von Rückfragen kontaktiert werden soll.',
  fkInfoTitlecontractNumber: 'Vertragsnummer',
  fkInfoMessagecontractNumber: 'Dies ist die Identifikationsnummer eines Vertrages.',
  fkInfoTitlephoneNr: 'Kontakt-Telefonnummer',
  fkInfoMessagephoneNr: 'Die Telefonnummer der Kontaktperson.',
  fkInfoTitledueDate: 'Fälligkeitsdatum',
  fkInfoMessagedueDate: 'Das Datum der Fälligkeit.',
  fkInfoTitleinvoiceDate: 'Rechnungsdatum',
  fkInfoMessageinvoiceDate: 'Datum an dem die Rechnung erstellt wurde.',
  fkInfoTitlebookedOn: 'Buchungsdatum',
  fkInfoMessagebookedOn: 'Buchungsdatum kann durch einen Prüfer bzw. Genehmiger gepflegt werden.'
    + ' Dazu muss das Feld als \'Sichtbar\' definiert sein und für die entsprechende Rolle als bearbeitbar definiert werden.',
  fkInfoTitleaccountingContact: 'Kontaktperson im Rechnungswesen',
  fkInfoMessageaccountingContact: 'Wird verwendet um im Hilfetext eine Kontaktperson zu hinterlegen. Hierfür wird das Feld'
    + ' "Standardwert" verwendet. Mit dem Schalter "Sichtbar" kann der Hinweistext ausgeblendet werden. Alle weiteren'
    + ' Einstellungen werden ignoriert.',
  fkInfoTitleserviceDescription: 'Leistungsbeschreibung',
  fkInfoMessageserviceDescription: 'Die Beschreibung zur Leistung.',
  fkInfoTitletermsOfPayment: 'Zahlungsbedingungen',
  fkInfoMessagetermsOfPayment: 'Eine fest hinterlegte Liste mit allen Zahlungsbedingungen. <br>' +
    ' Wenn ein Standardwert gesetzt werden soll, so dürfen nur die jeweiligen Z-Nummern oder "NOST" angegeben werden.' +
    '<br><br>' +
    '• Z001 - 10Tage nach ME+RE, ohne Abzug <br>' +
    '• Z004 - 14Tage nach ME+RE ohne Abzug <br>' +
    '• Z007 - 30Tage nach ME+RE, ohne Abzug <br>' +
    '• Z016 - unverzüglich nach ME+RE, ohne Abzug <br>' +
    '• Z019 - unverzüglich nach Rechnungseingang netto <br>' +
    '• Z039 - durch Verrechnung <br>' +
    '• Z107 - 21Tage nach Rechnungseingang ohne Abzug <br>' +
    '• Z136 - die Rechnung wurde bereits bezahlt <br>' +
    '• Z166 - 14+2Tage, ohne Abzug per Bankeinzug <br>' +
    '• ZN05 - Energiehandel <br>' +
    '• ZU12 - Buchungsdatum <br>' +
    '• Z152 - am 10. des ME+RE folg.Monats netto <br>' +
    '• Z158 - 12Tage nach Rgdatum <br>' +
    '• Z041 - Bankeinzug Miete <br>' +
    '• Z052 - 60 Tage nach ME+RE ohne Abzug <br>' +
    '• NOST - keine Angabe <br>',
  fkInfoTitlefacts: 'Sachverhaltsliste',
  fkInfoMessagefacts: 'Hier kann ein Standardwert aus einer fest hinterlegten Liste eingetragen werden. ' +
    'Der Standardwert muss exakt so benannt werden, wie in der Liste vorhanden. ' +
    'Ist kein Standardwert gesetzt, kann der Nutzer im Formular selbst wählen.' +
    '<br> <br>Die Liste der Sachverhalte:' +
    '<br><br>' +
    '• Versorgungs- und Netzverträge - Einzelverträge <br>' +
    '• Versorgungs- und Netzverträge - Zuarbeiten zu Dauerverträgen <br>' +
    '• Energiehandel Verkauf <br>' +
    '• Netznutzungsabschlag <br>' +
    '• Sperrung <br>' +
    '• Sperrversuch <br>' +
    '• Sponsoring <br>' +
    '• Straßenbeleuchtung <br>' +
    '• Stromsteuerfreier Betrag <br>' +
    '• Trommelleihzeit <br>' +
    '• Wartung <br>' +
    '• Zertifizierung <br>' +
    '• Zinsberechnung <br>' +
    '• Sonstiges <br>' +
    '• Schäden <br>' +
    '• Bewirtung <br>' +
    '• e-Portal <br>' +
    '• Fuhrpark <br>' +
    '• Personal <br>' +
    '• Porto <br>' +
    '• Störungsaufklärung <br>' +
    '• Schäden - Ausbuchung <br>' +
    '• Schäden - Gewährleistung/Mängelrügen <br>' +
    '• Schäden - Kasko <br>' +
    '• Schäden - Kundenforderungen <br>' +
    '• Anlagenverkauf <br>',
  fkInfoTitlevisContractNumber: 'VIS-Vetragsnummer',
  fkInfoMessagevisContractNumber: 'Eine eindeutige Nummer aus dem VIS-Tool welche auf der Rechnung wieder gegeben wird.',
  fkInfoTitleservicePeriod: 'Leistungszeitraum',
  fkInfoMessageservicePeriod: 'Der Zeitraum in der die Leistung erbracht wurde.',
  fkInfoTitleplaceOfPerformance: 'Leistungsort',
  fkInfoMessageplaceOfPerformance: 'Der Ort an dem die Leistung erbracht wurde.',
  fkInfoTitleinternalNote: 'Interner Vermerk',
  fkInfoMessageinternalNote: 'Ein interner Vermerk.',
  fkInfoTitleadditionalInfoOnInvoice: 'Zusätzliche Angaben auf der Rechnung',
  fkInfoMessageadditionalInfoOnInvoice: 'zusätzliche Angaben die auf der Rechnung erscheinen sollen.',
  fkInfoTitledienArticle: 'DIEN-Artikel',
  fkInfoMessagedienArticle: 'Hier kann der DIEN-Artikel konfiguriert werden',
  fkInfoTitlerecurringAccounting: 'wiederkehrende Rechnungslegung',
  fkInfoMessagerecurringAccounting: 'Eine Checkbox ob es eine wiederkehrende Rechnungslegung gibt oder nicht. ' +
    '<br>Wenn Sichtbar hat der Nutzer die Möglichkeit die Felder Abrechnungsintervall, Abrechnungszeitpunkt und Abrechnung bis... einzutragen.',
  fkInfoTitlebillingInterval: 'Abrechnungsintervall',
  fkInfoMessagebillingInterval: 'Eine feste Liste bestehend aus:' +
    '<br>•jährlich' +
    '<br>•monatlich' +
    '<br>•quartalweise',
  fkInfoTitlebillingDate: 'Abrechnungszeitpunkt',
  fkInfoMessagebillingDate: 'Eine feste Liste bestehend aus:' +
    '<br>• 1. des Monats' +
    '<br>• 15. des Monats' +
    '<br>• 30. des Monats',
  fkInfoTitlebillingUntil: 'Abrechnung bis...',
  fkInfoMessagebillingUntil: 'Bis wann soll die Abrechnung erfolgen?',
  fkInfoTitledepartment: 'Abteilung',
  fkInfoMessagedepartment: 'Die Abteilung kann mit "persönliche Daten übernehmen, direkt von "Meinen Daten" übernommen werden.',
  fkInfoTitletelephone: 'Telefon',
  fkInfoMessagetelephone: 'Die Telefonnummer kann mit "persönliche Daten übernehmen, direkt von "Meinen Daten" übernommen werden.',
  fkInfoTitledifferentDeliveryAddress: 'Abweichende Lieferadresse',
  fkInfoMessagedifferentDeliveryAddress: 'Hier kann angegeben werden, ob es eine abweichende Lieferadresse gibt.',
  fkInfoTitlenameDA: 'Name bei abweicheneder Lieferadresse',
  fkInfoMessagenameDA: 'Hier kann der Name bei abweicheneder Lieferadresse konfiguriert werden.',
  fkInfoTitlenameAdditionDA: 'Namenszusatz bei abweicheneder Lieferadresse',
  fkInfoMessagenameAdditionDA: 'Hier kann der Namenszusatz bei abweicheneder Lieferadresse konfiguriert werden.',
  fkInfoTitlestreetDA: 'Straße bei abweicheneder Lieferadresse',
  fkInfoMessagestreetDA: 'Hier kann die Straße bei abweicheneder Lieferadresse konfiguriert werden.',
  fkInfoTitlezipCodeDA: 'Postleitzahl bei abweicheneder Lieferadresse',
  fkInfoMessagezipCodeDA: 'Hier kann die PLZ bei abweicheneder Lieferadresse konfiguriert werden.',
  fkInfoTitlecityDA: 'Ort bei abweicheneder Lieferadresse',
  fkInfoMessagecityDA: 'Hier kann der Ort bei abweicheneder Lieferadresse konfiguriert werden.',
  fkInfoTitlephoneDA: 'Telefon bei abweicheneder Lieferadresse',
  fkInfoMessagephoneDA: 'Hier kann die Telefonnummer bei abweicheneder Lieferadresse konfiguriert werden.',
  fkInfoTitlecontactPersonDA: 'Kontaktperson bei abweicheneder Lieferadresse',
  fkInfoMessagecontactPersonDA: 'Hier kann die Kontaktperson bei abweicheneder Lieferadresse konfiguriert werden.',
  fkInfoTitleemailMyData: 'E-Mail aus Meine Daten',
  fkInfoMessageemailMyData: 'Hier können Einstellungen zum Feld E-mail Adresse aus "meinen Daten" vorgenommen werden, die innerhalb des Formulares angezeigt wird.',
  fkInfoTitleproductGroup: 'Warengruppe',
  fkInfoMessageproductGroup: 'Hier  können Einstellungen zu dem Feld Warengruppe vorgenommen werden. Bitte beachten Sie auch den Hinweistext, bevor Sie den Kleinbestellschein öffnen können.',
  fkInfoTitlestreetOA: 'Bestelladresse Straße',
  fkInfoMessagestreetOA: 'Hier können Einstellungen zur Straße der Bestelladresse gemacht werden.',
  fkInfoTitlezipCodeOA: 'Bestelladresse PLZ',
  fkInfoMessagezipCodeOA: 'Hier können Einstellungen zur PLZ der Bestelladresse gemacht werden.',
  fkInfoTitlecityOA: 'Bestelladresse Ort',
  fkInfoMessagecityOA: 'Hier können Einstellungen zum Ort der Bestelladresse gemacht werden.',
  fkInfoTitledistrictOA: 'Bestelladresse Ortteil',
  fkInfoMessagedistrictOA: 'Hier können Einstellungen zum Ortsteil der Bestelladresse gemacht werden.',
  fkInfoTitletimeOA: 'Bestelladresse Bestellzeitpunkt',
  fkInfoMessagetimeOA: 'Hier können Einstellungen zum Bestellzeitpunkt der Bestelladresse gemacht werden.',
  fkInfoTitlepurchasingGroup: 'Einkäufergruppe',
  fkInfoMessagepurchasingGroup: 'Hier können Einstellungen zur Einkäufergruppe getätigt werden.',
  fkInfoTitleservice: 'Dienstleistung',
  fkInfoMessageservice: 'Hier können Einstellungen zur Dienstleistung getätigt werden.',
  fkInfoTitlespecialReferences: 'besondere Hinweise',
  fkInfoMessagespecialReferences: 'Hier können Einstellungen zu besonderen Hinweisen, die der Nutzer eintragen möchte, getätigt werden.',
  fkInfoTitleadditionalTechnicalConditions: 'technische Zusatzbedingungen',
  fkInfoMessageadditionalTechnicalConditions: 'Hier können Einstellungen zu technischen Zusatzbedingungen, die der Nutzer eintragen möchte, getätigt werden.',
  fkInfoTitlemasterAgreementSelected: 'Rahmenvertrag Checkbox',
  fkInfoMessagemasterAgreementSelected: 'Hier kann eingestellt werden, ob der Nutzer die Checkbox "Rahmenvertrag" sieht, bzw. Pflicht ist.',
  fkInfoTitlemasterAgreement: 'Rahmenvertrag',
  fkInfoMessagemasterAgreement: 'Hier können Einstellungen zum Feld "Rahmenvertrag" gemacht werden.',
  fkInfoTitlemasterAgreementPosition: 'Rahmenvertrag Position',
  fkInfoMessagemasterAgreementPosition: 'Hier können Einstellungen zu der Position des Rahmenvertrag vorgenommen werden.',
  fkInfoTitleattachedOfferSelected: 'beigefügtes Angebot',
  fkInfoMessageattachedOfferSelected: 'Hier können Einstellungen zur Checkbox vorgenommen werden.',
  fkInfoTitledeliveryDate: 'Lieferungs- bzw. Ausführungsdatum',
  fkInfoMessagedeliveryDate: 'Hier können Einstellungen zum Lieferungs- bzw. Ausführungsdatum gemacht werden. ' +
    'Das Feld befindet sich innerhalb der Positionstabelle.',

  supervisoryBoardMeatingCompansationFilename: i9e`Gutschrift-${'publicId'}.pdf`,
  energyTradePurchaseFilename: i9e`Gutschrift-Energyhandel-Einkauf-${'publicId'}.pdf`,

  // DateRangePicker / Datum / Uhrzeiten
  weekendsActive: 'Wochenenden auswählbar',
  minDate: 'min. Datum',
  maxDate: 'max. Datum',
  minDateCurrentDate: 'min. Datum aktuelles Datum',
  maxDateCurrentDate: 'max. Datum aktuelles Datum',
  predefineWithCurrentDate: 'Mit aktuellem Datum vorbelegen',
  startDateVisible: 'Start-Datum sichtbar',
  startTimeVisible: 'Start-Zeit sichtbar',
  endDateVisible: 'End-Datum sichtbar',
  endTimeVisible: 'End-Zeit sichtbar',
  maxRangeLength: 'max. Zeit (Sekunden) zwischen Start/End',
  minRangeLength: 'min. Zeit (Sekunden) zwischen Start/End',
  minStartInFuture: 'min. Start in Zukunft (Sekunden)',
  maxStartInFuture: 'max. Start in Zukunft (Sekunden)',
  startPredefinedFromNow: 'Start Standardwert ab Erstellung (±Sekunden)',
  endPredefinedFromNow: 'End Standardwert ab Erstellung (±Sekunden)',
  typeString: 'Text',
  typeDate: 'Datum',
  typeDaterange: 'Start/End-Zeitpunkt',
  typeAccounting: 'Kontierung',
  typeNumber: 'Zahl',
  typeList: 'Liste',
  typeArranger: 'Bearbeiterauswahl',
  typeArrangerList: 'Bearbeiterliste',
  typeTextarea: 'Textarea',
  typeCheckbox: 'Checkbox',
  typeFileUpload: 'Dateien',
  typeLedgerAccount: 'Sachkonto',
  typeListItem: 'Listeneintrag',

  downloadExcelTemplateHeader: 'Excel-Vorlage herunterladen',
  downloadExcelTemplateInfo: 'Hier können Sie für bestimmte Formulare eine Excel-Tabelle herunterladen, befüllen und wieder hochladen.'
    + ' Daraus werden automatisch Vorgänge generiert und in den nächsten möglichen Zustand versetzt (z.B. "zur Freigabe" oder "Abgeschlossen")',
  downloadExcelTemplateProcessLabel: 'Für folgendes Formular sollen automatisiert Aufforderungen generiert werden:',
  downloadExcelTemplateCountLabel: 'Anzahl anzulegender Vorgänge',
  downloadExcelTemplateButtonLabel: 'Excel-Vorlage herunterladen',

  uploadExcelTemplateHeader: 'Vorgänge als Excel hochladen',
  uploadExcelTemplateInfo: 'Hier können Sie eine gefüllte Excel zur automatischen Erstellung von Vorgängen hochladen.',
  uploadExcelTemplateHint: `<p><strong>Hinweis:</strong></p>
              <div>
                <ul>
                  <li>Die Excel muss zwingend dem vorgegebenen Schema entsprechen. Bitte ggf. die vorgefertigte Excel dazu herunterladen.
                  </li>
                  <li>Absenderdaten müssen gefüllt werden</li>
                  <li>Bitte prüfen Sie bei der Einzelformulareingabe des entsprechenden Vorgangs, welche Dokumente und Angaben Pflicht sind.
                    Diese Pflichtangaben gelten auch für die MDV.
                  </li>
                  <li>Schlägt die Verarbeitung fehl, werden Sie per E-Mail benachrichtigt. Korrigieren Sie bitte daraufhin Ihre Excel und
                    laden Sie diese erneut hoch.
                  </li>
                </ul>
              </div>`,
  uploadExcelTemplateButtonLabel: 'Excel absenden',

  duplication: 'Doppelter Vorgang',
  duplicationInfo: 'Es gibt bereits einen Vorgang im EASy mit den gleichen Daten. Bitten prüfen.',

  /*Massendaten*/
  massDataExcelError: 'Excel Dokument konnte nicht erzeugt werden.',
  massDataSuccess: 'Massendatenverarbeitung erfolgreich gestartet. Der Status wird Ihnen separat per E-Mail mitgeteilt',
  massDataError: 'Massendatenverarbeitung konnte nicht gestartet werden.',
};


const en: TranslationLanguage = {
  UNKNOWN: 'An error occurred.',
  COMPANY_INACTIVE: 'The selected company is no longer active. The proposal can therefore no longer be sent or saved.',
  chooseNetRegion: 'first choose network region',
  netRegion: 'Netregion',
  serviceCenter: 'Servicecenter',
  PROPOSAL_LOCKED: 'Proposal locked by another user.',
  lockedByTrailing: '.',
  lockedBy: 'The proposal is locked for other editors by',
  lockedByYou: 'The proposal is locked for other editors by yourself.',
  formHasErrors: 'The form has errors, please check.',
  authorNotAllowed: 'The applicant must not be the approver/inspector at the same time.',
  approverNeeded: 'A selected article requires a approver to be defined.',
  recipientNotAllowed: 'The payee must not be the approver/inspector at the same time.',
  notAllowedWhileIncludedIn: 'User is already defined as',
  formDashboard: 'Forms',
  requiredFieldPleaseCheck: 'Not filled a required field. Please check the form.',
  invalidFormPleaseCheck: 'There are errors in the form. Please correct them.',
  favoriteDashboard: 'Favorites',
  templateDashboard: 'Templates',
  noTemplatesForForm: 'There are no templates for the selected form.',
  adminDashboard: 'Administration',
  indexInfoText: 'This could be your Information. Easy System-Messages',
  participantInfoText: 'Please add the list of participants at "Attach documents".',
  missingInput: 'Please check your input.',
  greeting: i9e`Hello ${'name'}`,
  language: 'Language',
  favoritCreate: 'create as a favorite',
  favoritDelete: 'remove from favorites',
  showAll: 'show all',
  showLess: 'show less',
  more: 'more',
  cameraScan: 'camera scan',
  addManual: 'record manually',
  useTemplate: 'use template',
  useDocument: 'import document',
  searchApprover: 'search approver',
  enableApprover: 'enable approver',
  approvalComment: 'comment',
  pickupOption: 'pickup (no delivery)',
  selectRoom: 'select room...',
  noRoomInList: 'room not found in list',
  enterRoom: 'room',
  uploaded: 'already uploaded',
  '90DayWarning': 'Completed proposals get removed 90 days after the last change.',
  noEntry: 'no entry exists',

  yearly: 'yearly',
  monthly: 'monthly',
  twoMonths: 'every 2 Months',
  fourMonths: 'every 4 Months',
  sixMonths: 'every 6 Months,',
  quarterly: 'quarterly',
  first: '1st of the month',
  fifteenth: '15th of the month',
  thirtieth: '30th of the month',

  agb: 'general business terms',
  certificate: 'certificate',
  downloadAgb: 'download',
  print: 'print',
  company: 'Company',
  selectCompany: 'Select company',
  selectedCompany: 'selected company',
  order: 'order',
  position: 'position',
  defaultCompany: 'default Company',
  newCompany: 'create a new Company',
  shareholder: 'shareholder',
  companyId: 'company code',
  creditor: 'payee',
  debitor: 'debitor',
  blanketOrder: 'blanket order',
  creditorSearch: 'payee search',
  debitorSearch: 'debitor search',
  payeeNumber: 'creditor number',
  creditorNumber: 'creditor number',
  debitorNumber: 'debitor number',
  accountingSearch: 'accounting search',
  accountingNumber: 'accounting number',
  ledgerAccountSearch: 'ledger account search',
  ledgerAccountNumber: 'ledger account number',
  userSearch: 'User search',
  backendClient: 'SAP client',
  participantCompany: 'participant company',
  participantName: 'participant name',
  availableCategories: 'available categories',
  requiredCategories: 'required categories',
  uploadType: 'allowed file types',
  categories: 'categories',
  roomName: 'room name / number',
  billingBasis: 'billing basis for service contracts',
  miscPayout: 'miscellaneous payout',
  loadBlanketOrder: 'load blanket order',

  translations: 'Translation',
  coverLetterText: 'Text of the cover letter',

  contactDetails: 'contact Details',
  address: 'address',
  salesInfo: 'sales info',
  salesOrg: 'sales organisation',
  distributionChannel: 'distribution channel',
  sector: 'sector',
  branchSalesOffice: 'branch sales office',
  procurementSection: 'procurement section',
  salutation: 'salutation',
  salutations: 'salutations',
  salutationID: 'salutation FORM_ID',
  female: 'Mrs.',
  male: 'Mr.',
  divers: 'Divers',
  name: 'name',
  nameOrCompany: 'name / company',
  shortName: 'shortcut',
  nameAddition: 'name completion',
  firstName: 'name',
  lastName: 'surname',
  streetNumber: 'street, number',
  street: 'street',
  place: 'place',
  plz: 'postal Code',
  boxNumber: 'box number',
  boxNumberLocation: 'box number - postal code, place',
  phone: 'telephon number',
  mailAdress: 'mail',
  mail: 'mail',
  iban: 'IBAN',
  bic: 'BIC',
  country: 'country',
  department: 'department',
  bankDetails: 'bank account',
  bankData: 'bank account',
  costCenter: 'cost center',
  fuVNr: 'FuV-Nr.',
  user: 'user',
  collectionBy: 'collection by...',
  deliveryPlace: 'place / room of delivery',
  alternativeRoom: 'room',
  particularities: 'particularities',
  empty: '',
  search: 'search',
  filterIt: 'filter',
  searchUser: 'search user',
  yourData: 'address data',
  billingAddress: 'billing adress',
  orderAddress: 'order location address',
  applyYourData: 'apply your data',
  billingAddressBasedOnCompany: 'billing address based on company',
  differingShippingAddress: 'differing Shipping Address',
  companyShippingAddress: 'company shipping address',
  shippingAddress: 'shipping address/place of service',
  myData: 'my data',
  applyFromPayee: 'apply from payee',
  apply: 'apply',
  close: 'close',
  headerData: 'purpose data',
  coverLetter: 'cover letter',
  itemsList: 'items list',
  itemsListHint: 'Selectable articles are dependent on selected location and start of catering',
  detailsAndPaymentAmount: 'details and payment amount',
  positionInformations: 'position information',
  details: 'details',
  approvalStages: 'approval stages',
  documentAttachments: 'document attachments',
  noSelection: 'none selected',
  city: 'city',
  dateTo: 'Date to:',
  activeSystemMessage: 'active',
  voucherDate: 'voucher date',
  voucherType: 'voucher type',
  bookingDateOriginal: 'booking Date',
  sapReference: 'sap reference',
  voucherHeadText: 'voucher text',
  voucherAuthor: 'voucher author',
  dateOfEntry: 'date of entry',
  bookingDate: 'booking date',
  transferText: 'transferText',
  sendMail: 'send mail to author',
  mailText: 'mail text',

  billingInterval: 'billing interval',
  billingDate: 'billing time',
  billingUntil: 'billing until',
  serviceDescription: 'service description',
  termsOfPayment: 'terms of payment',
  facts: 'facts',
  visContractNumber: 'vis-contractnumber',
  servicePeriod: 'service period',
  placeOfPerformance: 'place of performance',
  internalNote: 'internal note',
  additionalInfoOnInvoice: 'additional info of the invoice',
  dienArticle: 'DIEN-Article',
  recurringAccounting: 'recurring billing',

  noData: 'No data to display',
  totalCount: 'total',
  selected: 'selected',

  info: 'info',
  dateReject: 'rejectionDate',
  deputizedFor: 'deputized for',
  dateCatering: 'service date',
  dateEvent: 'event date',
  reason: 'reason',
  location: 'location',
  numberOfPersons: 'number of persons',
  participants: 'participants',
  leaflet: 'Notes on billing',

  proposalInfo: 'proposal informations',
  referenceNumber: 'SAP reference number',
  billingNumber: 'SAP billing number',

  /*PositionTable Items*/
  item: 'item',
  itemName: 'item name',
  quantity: 'quantity',
  unit: 'unit',
  singlePrice: 'single price',
  total: 'total',
  quotaEqualsActual: 'actual equals quota',
  approvalNeeded: 'confirmation person',
  addAutomatically: 'add automatically',
  validFrom: 'valid from ',
  validUntil: 'valid until ',
  deliveryDate: 'deliverydate',

  approver: 'approver',
  currentArrangerId: 'approver',
  standardApprover: 'standard approver (E-Mail)',
  technicalApprover: 'technical approver',
  confirmationPerson: 'confirmation Person',
  inspector: 'inspector',
  editorDeputies: 'technical deputy',
  approverDeputies: 'approver deputy',

  representativesConfiguration: 'deputy configuration',

  formConfig: 'form configuration',
  defaultValue: 'default value',

  activationValue: 'Schwellwert für Aktivierung',
  activationCount: 'Stichprobenintervall',
  sortingNumber: 'Reihenfolge',

  lastTryTime: 'last retry',
  queueRetry: 'retry proposal',
  queueDelete: 'delete queue entry',

  INVOICE: 'invoice',
  PARTICIPANT_LIST: 'participant list',
  CONTRACT: 'contract',
  OTHERS: 'other',
  CREDIT: 'credit',
  OFFER: 'offer',
  RELEASE: 'written release',

  PDF_ONLY: 'PDF only',
  PDF_ONLYError: 'only PDFs are',
  NO_EXECUTABLES: 'no executables',
  NO_EXECUTABLESError: 'are no executables',

  /*Dashboards*/
  throughputDashboard: 'Throughput Dashboard',
  proposalCountDashboard: 'Poposal Count Dashboard',
  payoutDashboard: 'Payout Dashboard',

  /*Button Labels*/
  reload: 'reload',
  save: 'save',
  saveView: 'save view',
  edit: 'edit',
  delete: 'delete',
  back: 'back',
  next: 'next',
  overviewPage: 'to overview page',
  login: 'login',
  logout: 'logout',
  category: 'category',
  fileDelete: 'delete file',
  attachFile: 'attach file',
  logo: 'logo',
  term: 'term',
  description: 'description',
  furtherDescription: 'further description',
  declineLabel: 'No',
  acceptLabel: 'Yes',
  declineLabel2: 'Decline',
  acceptLabel2: 'Accept',
  remove: 'remove',
  editCompany: 'edit Company',
  from: 'from',
  until: 'until',
  required: 'required',
  editable: 'editable',
  visible: 'visible',
  changeableByApprover: 'editable by approver',
  changeableByInspector: 'editable by inspector',
  translationPlease: 'please enter a translation',
  cancelCatering: 'cancel Catering',
  cancel: 'cancel proposal',

  predefined: 'predefined',
  overwrite: 'overwrite',

  properAccountList: 'proper account list',
  properAccountID: 'proper account FORM_ID',
  taxRates: 'tax rates',
  differentBillingAddress: 'different billing address',
  addAGB: 'add agb',
  addOtherAttachment: 'add other attachment',
  billingInformation: 'Billing information',
  voucherSelection: 'voucher selection',
  adjustingEntry: 'adjusting entry',
  loadVoucherData: 'load voucher',
  loadDifferentVoucherData: 'load different voucher',
  loadOrderData: 'load blanket order',
  loadDifferentOrderData: 'load different blanket order',
  client: 'client',
  detailInformation: 'detail information',
  serviceRecipients: 'Service recipients and providers',
  supplierContractor: 'Supplier / contractor',
  ustId: 'tax number',
  useCase: 'purpose',
  groundNumber: 'ground number',
  contract: 'contract',
  attachments: 'attachments',
  getAccountingDataFromLine: 'copy position 1 to other positions',
  getCompanyFromLine: 'copy 1. company to other participants',
  payout: 'payout',
  positionName: 'name',
  commentForApprover: 'comment for approver',
  netCost: 'net cost',
  taxRate: 'tax rate',
  taxAmount: 'tax amount',
  taxValue: 'tax value',
  grossCost: 'gross cost',
  account: 'account',
  accountType: 'account type',
  ledgerAccount: 'ledgerAccount',
  positions: 'positions',
  deselect: 'deselect',

  deputyTemplate: 'template of deputies',
  noDeputyTemplate: 'The persons you act in place of have no templates or you advocate nobody',


  /*Footer*/
  faq: 'FAQ',
  glossary: 'glossary',
  dataProtection: 'data protection',
  legalDisclosure: 'legal disclosure',
  contactSupport: 'contact Support',
  reportFault: 'report fault',

  /*PopUp Texts*/
  k1Title: 'please read carefully',
  deleteTaxRateTitle: 'Delete tax rate',
  deleteTaxRateText: 'Are you sure you want to delete that tax rate there?',
  deleteSalutationTitle: 'Delete salutation',
  deleteSalutationText: 'Are you sure you want to delete that salutation there?',
  deleteLedgerAccountTitle: 'Delete ledger account',
  deleteLedgerAccountText: 'Are you sure you want to delete that ledger account there?',
  deleteCompanyTitle: 'Delete Company',
  deleteCompanyText: 'Do you want to delete this company?',
  deleteTemplateTitle: 'Delete Template',
  deleteTemplateText: 'Do you want to delete this template?',
  deleteProposalTitle: 'Delete Proposal',
  deleteProposalText: 'Do you want to delete this proposal?',
  rejectProcess: 'reject the process',
  showComment: 'show comment',
  rejectProcessMessage: 'Please give a reason why you would like to decline this process.',
  rejectProcessMultipleMessage: 'Please provide a reason why you want to decline this process. ' +
    'The comment entered will be used for all selected operations.',
  approveProcess: 'approve the process',
  approveProcessMessage: 'Are you sure you want to approve the process?',
  approveProcessMultipleMessage: i9e`Are you sure you want to approve the processes in the value of ${'amount'} ?`,
  infoApproveNoRejectMessage: 'Please give a reason why.',
  rejectDialogTitle: 'Latest reason for refusal',
  rejectHistoryTitle: 'rejection history',
  backendErrorMessageTitle: 'SAP error message',
  saveTemplateProcess: 'save template',
  saveTemplateProcessMessage: 'Please enter a name for the template.',
  saveTemplateDialogTitel: 'save as template',
  cateringCancelTitle: 'cancel catering',
  proposalCancelTitle: 'proposal cancel',
  cateringCancelText: 'Do you want to cancel this catering?',
  proposalCancelText: 'Do you want to cancel this proposal?',

  /*ToolTips & Titles*/
  laterVersion: 'This feature is currently not available.',
  view: 'View',
  editFormConfig: 'edit formula configuration',
  infoIconText: 'further information',
  selectStaffOutingAdvance: 'select advance',

  /* Meldungen */
  unknownError: 'Unkown Error. Please Contact your administrator',
  severalIbans: 'The vendor has several IBAN details. Please select one.',
  validationError: 'Please check your input.',
  sessionError: 'You are not logged in.',
  deleteConfigItemSuccess: 'element successfully deleted.',
  coreConfigLedgerAccountsSaveSuccess: 'ledger account save success',
  coreCompanyFormActionSaveSuccess: 'company save success.',
  coreConfigTaxRatesSaveSuccess: 'tax rate save success',
  coreConfigSalutationsSaveSuccess: 'salutation save success',
  accountDeputiesSaveSuccessAction: 'Deputies save success',
  accountDeputiesLoadedActionError: 'Deputies could not be loaded',
  accountDeputiesSaveActionError: 'Deputies could not be saved',
  coreAccountFormActionSaveSuccess: 'user data saved',
  coreAccountFormActionSaveError: `user data couldn't be saved`,
  coreAccountDeleteCostCenterSuccess: 'cost center was deleted.',
  coreAccountDeleteDefaultArrangerSuccess: 'approver was deleted',
  coreConfigCateringOrderDataSaveSuccess: 'Order data saved successfully.',
  coreConfigFaqSaveSuccess: 'FAQ saved successfully.',
  coreConfigCateringOrderDataSaveError: `Order data couldn't be saved.`,
  coreConfigCateringOrderDataLoadError: 'An error occurred while loading',
  tableConfigSaved: 'table configuration was saved',
  favoriteActionSaveSuccess: 'favorite was created',
  templateActionSaveSuccess: 'template was created',
  viewSaveSuccess: 'view saved',
  favoriteActionDeleteSuccess: 'favorite was removed',
  templateActionDeleteSuccess: 'template was removed',
  formConfigSaved: 'form configuration has been saved',
  serviceCenterSaved: 'service center have been saved',
  categoryInfoSaved: 'category infos have been saved',
  formSaveError: 'The form could not be saved, please check.',
  formSendError: 'The form could not be send.',
  formSaveSuccess: 'The form has been saved.',
  formSendSuccess: 'The form was send.',
  formConfigActionError: 'Error loading form configuration',
  cancelProposalSuccess: 'The proposal has been canceled.',
  missingFileUploadCategory: i9e`Please choose a category. There must be an attachment with the following category: ${'categories'}.`,
  wrongBeneficiaryMimeType: 'Only PDFs can be sent to the beneficiary.',
  invalidMimeType: i9e`${(t) => t['category'] ? 'For ' + t['category'] : 'Here'} ${'allowed'} is allowed, but there is a ${'type'}.`,
  maxTotalUploadSize: i9e`Total upload size of all new attachments can not exceed ${'totalSize'}.`,
  approvalNotAllowed: 'You have no permission to approve or reject this proposal',
  RequestSizeToLargeError: 'Because of technical limitation the total size of all new attachments is not allowed to exceed 10MB. Please'
    + ' delete attachments until the total size is below that limit and cache the proposal. Following that you can re-open it and add'
    + ' additional attachments.',
  formRejectSuccess: 'Proposal rejected.',
  formApprovedSuccess: 'Proposal approved.',
  deleteProposalData: 'Process deleted.',
  sendCommentSuccess: 'Comment saved',
  coreConfigLocationRoomSaveSuccess: 'rooms were saved successfully',
  coreConfigLocationArticleSaveSuccess: 'Item list was saved successfully',
  formConfigCompanyDeleteSuccess: 'Company deleted for this form',
  maxPayoutError: i9e`The largest amount is ${'maxPayout'}€`,
  maxPayoutHint: i9e`The largest amount per employee is ${'maxPayoutPerPerson'} €.`,
  accountingContactHint: i9e`<b>Contact the processor in accounting:</b> ${'contact'}`,
  recallProposalSuccess: 'proposal was canceled',
  cancelWfsSuccess: 'proposal was canceled',
  ibanInvalidError: 'IBAN is invalid.',
  mandatoryError: 'please fill out the required field.',
  requiredError: 'please fill out the required field.',
  regexError: 'please regard the format',
  minError: i9e`minimal allowed value is ${'min'}`,
  maxError: i9e`maximum allowed value is ${'max'}`,
  maxLengthError: i9e`maximum allowed length is ${'maxLength'}`,
  weekendsNotAllowedError: 'weekends are not valid',
  maxDateError: 'Date is after the allowed end date', // TODO: I18n von Datumsen?
  minDateError: 'Date is before the allowed start date', // TODO: I18n von Datumsen?
  maxDateCurrentDateError: 'Date is not allowed to be after today',
  minDateCurrentDateError: 'Date is not allowed to be before today',
  fuvNoPrefixError: 'FuV-prefix missing',
  fuvCimdIdMissingError: 'CIMD missing',
  fuvCimdIdMismatchError: 'CIMD not matching selected company',
  fuvYearError: 'declaration of year missing, like: ' + ('' + (new Date()).getFullYear()).slice(2),
  fuvLastNumberError: 'The last number is not 6 digits long',
  fuvOld: 'data acquisition only with FuV 2.0 possible.',
  noDateError: 'only date inputs allowed.',
  maxLengthHint: i9e`${'length'} / ${'maxLength'}`,
  creditDebitNotEqual: 'credit not equal to debit',
  creditDebitEqual: 'credit equal to debit',
  deactivatedAmsError: 'Please provide a reason why AMS is not required',
  voucherAuthorNoMail: `Sending an e-mail is not possible as voucher author e-mail couldn't be resolved.`,
  articleNotAvailable: 'The selected article is no longer available',

  userNotUnique: 'user is already defined',
  needsToBeEmployeeInParticipantList: 'user has to be marked as employee in the participant list',
  minStartInFutureError: 'must start later, at least',
  minRangeLengthError: 'must end later, at least',
  maxRangeLengthError: 'must end sooner, at max',
  maxStartInFutureError: 'must start sooner, at max',
  timeMin: 'min start time (HH:mm)',
  timeMax: 'max start time (HH:mm)',
  timeMinError: 'start time too early, at least',
  timeMaxError: 'start time too late, at max',
  weekendsActiveError: 'weekends not allowed',
  startMandatoryError: 'start is mandatory',
  endMandatoryError: 'end is mandatory',
  endTimeAfter4Pm: 'If catering takes place after 4 p.m., a service flat rate will be added.',
  endBeforeStartError: 'End date must be after start date.',
  formU1VoucherDataNotFoundError: 'Document was not found.',
  formU1VoucherDataLoadError: 'Error loading the receipt.',
  formK3OrderDataNotFoundError: 'blanket order could not be found',
  formK3OrderDataLoadError: 'error loading the blanket order',
  productGroupNotAllowedError: 'This product group is not allowed for the small order form. Use the appropriate ordering process for this.',

  /*Spaltennamen*/
  id: 'Proposal ID',
  author: 'author',
  authorId: 'author',
  created: 'Created At',
  state: 'State',
  amount: 'amount',
  form: 'Form',
  actions: 'actions',
  clearFilters: 'clear filters',
  exportWithFilter: 'export with filters',
  lastModified: 'last changes',
  recipient: 'recipient',
  recipientName: 'recipient',
  sapReferenceNumber: 'SAP-Referencenumber',

  /* meine Vorgänge*/
  myActions: 'My proposals',
  representedProcesses: 'Deputy proposals',
  allOperations: 'alle Vorgänge',

  /* Bewirtungen */
  open: 'open',
  openCatering: 'open catering requests',
  cateringInProgress: 'catering in progress',
  cateringAsDeputy: 'deputy catering',
  finishedCatering: 'finished catering requests',
  cateringStart: 'catering start',
  cateringEnd: 'catering end',
  room: 'room',
  participantCount: 'participant count',
  collectionByAuthor: 'collection by author',
  collection: 'collection',
  delivery: 'delivery',
  articleUpdate: 'The Articles of your template where updated. Please check your entries',

  /* Freigabe*/
  myApprovals: 'Approvals',
  representedApprovals: 'Deputy approvals',
  finishedApprovals: 'Finished approvals',
  allApprovals: 'All approvals',

  emptyDashboardForm: 'No forms for choosen category',

  editNewConfig: 'edit new configuration',
  deleteSelected: 'delete selected',
  hasNoForms: 'Currently there are no forms.',
  add: 'Add',
  saveAndSendLater: 'save & send later',
  cancelProcess: 'cancel catering',
  saveAndApproveLater: 'save & approve later',
  saveTemplate: 'save Template',
  send: 'Send',
  sendToBeneficiary: 'send to beneficiary',
  payee: 'Payee',
  invoiceDetails: 'Invoice details',
  approve: 'Approve',
  reject: 'Reject',
  check: 'Check',
  inheritFrom: 'Inherit from',

  ocrTitle: 'record invoice',
  ocrGuidelines: 'Here you have the option of automatically having an existing invoice scanned into an process. <br/><br/>' +
    'Please note that not all fields will be filled. <br/>' +
    'Check the entries carefully and correct any error <br/>',
  ocrUploadButton: 'upload invoice...',
  ocrScanButton: 'scan',
  ocrAbortButton: 'abort',
  ocrScanFailed: 'Scan failed. Please fill your form manually.',
  ocrScanSuccess: 'Scan successful. Please check your form.',
  ocrScreenshot: 'take photo',
  ocrRetakeScreenshot: 'retake photo',
  ocrCamError: 'Error while accessing cam. Please check your configuration.',
  fuvInAcquisition: 'The proposal is not finished within the FuV-Tool. There may be changes with the data.',
  FUV_NOT_FOUNDError: `The FuV-Process couldn't be found.`,
  USER_NOT_ALLOWEDError: 'You are not allowed to load the FuV-Process',
  UNKNOWNError: 'Could not load the FuV-Process.',
  fuvInfoTitle: 'FuV-decution',
  fuvInfoText: 'For further information about the FuV-Tool please contact <a href="mailto:taxdirect@eon.com">taxdirect@eon.com</a>',

  fackq: 'FAQ maintenance',
  section: 'section / heading',
  question: 'question',
  answer: 'answer',

  CACHED: 'Cached',
  SAVED: 'Saved',
  IN_APPROVAL: 'In approval',
  IN_INSPECT: 'In inspect',
  IN_FURTHER_PROCESSING: 'In further processing',
  WAITING: 'Waiting',
  WAITING_FOR_DEDUCTION: 'Waiting for deduction',
  WAITING_FOR_SIGNATURE: 'Waiting for signature',
  WAITING_FOR_BILLING: 'Waiting for billing',
  RECALLED: 'proposal recalled',
  APPROVED: 'Approved',
  SEND_FAILED: 'Send failed',
  SAP_ERROR: 'data error',
  DECLINED: 'Declined',
  FINISHED: 'Finished',
  INITIAL: 'Initial',
  CATERING_APPROVAL: 'approval by caterer',
  CATERING_IN_PROGRESS: 'catering in progress',
  CATERING_DECLINED: 'catering declined',
  CATERING_COMPLAINED: 'catering complined',
  CATERING_APPROVAL_BY_AUTHOR: 'approval by author',
  CATERING_COMPLETE: 'catering complete',
  CATERING_CANCELED: 'catering canceled',
  CANCELED_BY_CATERER: 'canceled by caterer',
  TEMPLATE_SAVE: 'Template saved',

  SAP_SEARCH: 'SAP-Search',
  SELECTION: 'List from Admin section',
  PREDEFINED: 'Predefined',

  COST_CENTER: 'cost center',
  ORDER: 'order',
  PSP_ELEMENT: 'PSP element',
  FIXED_ASSET: 'fixed asset',

  searchPersonToRegister1: 'You did not find the person?',
  searchPersonToRegister2: ' contact them to register.',
  here: 'Here',
  registerAtEasy: 'Registration in the EASy portal',
  registerAtEasyText: 'A person tries to find you through the people search within the EASY portal. '
    + 'Please register in the EASY portal at the following link to be found: ',

  mailReportFaultSubject: 'EASy4All: report a malfunction',
  mailReportFault: 'Report a malfunction of the application / process in the EASY portal \r\n\r\n' +
    'Error time (date and time): \r\n' +
    'Enter your username: \r\n' +
    'Which error message is displayed: \r\n' +
    'Which browser is used and which version of it: \r\n' +
    'With which application / process the error occurred: \r\n' +
    'Do you have any other applications open? Which: \r\n' +
    'How long is the current session open: \r\n' +
    'How did the error arise (specify click path if necessary): \r\n' +
    'URL / screenshot of the entire application: \r\n',

  serviceCenterMaintenance: 'service center maintenance',
  categoryInfos: 'category info maintenance',

  /*Systemmeldung-Infotexte */
  systemMessage: 'System message',
  systemMessageDate: '<br><b>You have the option of creating a system message here.</b>'
    + '<br><br>When you activate the checkbox, your system message is displayed on the start page of the Easy Portal.'
    + '<br><br>Furthermore, you have the option of specifying how long your system message should be displayed. <br><br>',

  /*Systemmeldung-Infotexte */
  ruleForCatering: 'Rules for Catering',
  ruleForCateringInfo: '<br><b>1.</b> An order must be placed at least 18 hours in advance.'
    + '<br><b>2.</b> The start of the catering must be between 8:00 a.m. and 4:00 p.m.<br><br>',

  /*Formularkonfiguration-Infotexte */
  fkInfoTitlepayeeNumber: 'Creditor / debitor number',
  fkInfoMessagepayeeNumber: '<b>Creditor / debitor number:</b>'
    + '<br>The vendor number can be stored here so that it cannot be changed by the user. '
    + '<br>It can also be added manually by the user, or the search within the form is used.'
    + '<br><br>',
  fkInfoTitlesalutation: 'Salutation',
  fkInfoMessagesalutation: '<b>Salutation:</b>'
    + '<br>There are various salutations to choose from.'
    + '<br>The salutations can be changed, deleted or added within the administration under the heading "Salutations". '
    + '<br><br>',
  fkInfoTitlename: 'Name',
  fkInfoMessagename: '<b>Name:</b>'
    + '<br>First and last name should be entered in this field. '
    + 'This can be done manually, or you can use the user search within the form.'
    + '<br> If you use the user search, all fields for which information about this '
    + 'user is available are automatically filled.'
    + '<br><br>',
  fkInfoTitlestreet: 'Street',
  fkInfoMessagestreet: '<b>Street:</b>'
    + '<br>The street should be entered in this field.'
    + '<br><br>',
  fkInfoTitlezipCode: 'Postcode',
  fkInfoMessagezipCode: '<b>Postcode:</b>'
    + '<br>The postcode should be entered in this field.'
    + '<br><br>',
  fkInfoTitlecity: 'City',
  fkInfoMessagecity: '<b>City:</b>'
    + '<br>The city should be entered in this field.'
    + '<br><br>',
  fkInfoTitlecountry: 'Country',
  fkInfoMessagecountry: '<b>Country:</b>'
    + '<br>The country should be selected here. To do this, you can select a country using the list.'
    + '<br>As default value please use the ISO Alpha-2 country codes. e.q.: <br>'
    + '<ul>'
    + '<li>DE - Germany</li>'
    + '<li>FR - France</li>'
    + '<li>GB - Great Britain</li>'
    + '</ul>'
    + '<br><br>',
  fkInfoTitleemail: 'E-Mail',
  fkInfoMessageemail: '<b>E-Mail:</b>'
    + '<br>The email should be entered in this field.'
    + '<br><br>',
  fkInfoTitleiban: 'IBAN',
  fkInfoMessageiban: '<b>IBAN:</b>'
    + '<br>The IBAN should be entered in this field. If the IBAN is not a German IBAN (DE at the beginning), '
    + 'the BIC automatically becomes a mandatory field and must be entered.'
    + '<br><br>',
  fkInfoTitlebic: 'BIC',
  fkInfoMessagebic: '<b>BIC:</b>'
    + '<br>The BIC should be entered in this field. If the IBAN is not a German IBAN (DE at the beginning), '
    + 'the BIC automatically becomes a mandatory field and must be entered.'
    + '<br><br>',
  fkInfoTitleuseCase: 'Usage',
  fkInfoMessageuseCase: '<b>Usage:</b>'
    + '<br>The intended use should be entered in this field.'
    + '<br><br>',
  fkInfoTitlegroundNumber: 'ground Number',
  fkInfoMessagegroundNumber: '<b>ground number:</b>'
    + '<br>The intended ground number should be entered in this field.'
    + '<br><br>',
  fkInfoTitledescription: 'Description',
  fkInfoMessagedescription: '<b>Description:</b>'
    + '<br>The description should be entered in this field.'
    + '<br><br>',
  fkInfoTitleevent: 'Display for events',
  fkInfoMessageevent: '<b>Display for events:</b>'
    + '<br>If "Display for events" has been checked, the FUV number is automatically required and must be specified.'
    + '<br><br>',
  fkInfoTitlefuvNumber: 'FUV-Number',
  fkInfoMessagefuvNumber: '<b>FUV-Number:</b>'
    + '<br>If "Display for events" has been checked, the FUV number is automatically required and must be specified.'
    + '<br><br>',
  fkInfoTitlepositions: 'Positions',
  fkInfoMessagepositions: 'The position table can be set here.'
    + '<br><br>',
  fkInfoTitlepositionName: 'Position Description',
  fkInfoMessagepositionName: '<b>position Description:</b>'
    + '<br>The position description should be entered in this field.'
    + '<br><br>',
  fkInfoTitlecommentForApprover: 'Comment for Approver',
  fkInfoMessagecommentForApprover: '<br>A comment for the approver can be entered in this field.',
  fkInfoTitlecoverLetterText: 'Text cover letter',
  fkInfoMessagecoverLetterText: '<br>A text can be stored within the form configuration, '
    + 'which is displayed within the document.',
  fkInfoTitleaddCoverLetter: 'Checkbox cover letter',
  fkInfoMessageaddCoverLetter: '<br>A checkbox that indicates whether you want to add a cover letter.'
    + ' After activating the checkbox, a text editor opens in the form.',
  fkInfoTitlequantity: 'Quantity',
  fkInfoMessagequantity: '<b>Quantity:</b>'
    + '<br>The quantity should be entered in this field.'
    + '<br><br>',
  fkInfoTitlenetCost: 'Net amount',
  fkInfoMessagenetCost: '<b>Net amount:</b>'
    + '<br>The net amount should be entered in this field. '
    + '<br>If the net amount was set first, the gross amount is calculated from the net and the specified tax rate. '
    + '<br>If the gross amount was set first, then the net amount is calculated from the gross and the specified tax rate.'
    + '<br><br>',
  fkInfoTitletaxRate: 'tax rate',
  fkInfoMessagetaxRate: '<b>tax rate:</b>'
    + '<br>Here you can select a tax rate.'
    + '<br>You can add a new tax rate or change an existing one in the administration area under "Tax rates".'
    + '<br>Other fields are calculated from the tax rate. '
    + '<br>If the net amount was set first, the gross amount is calculated from the net and the specified tax rate. '
    + '<br>If the gross amount was set first, then the net amount is calculated from the gross and the specified tax rate.'
    + '<br><br>',
  fkInfoTitlegrossCost: 'Gross',
  fkInfoMessagegrossCost: '<b>Gross:</b>'
    + '<br>The gross amount should be entered in this field. '
    + '<br>If the gross amount was set first, then the net amount is calculated from the gross and the specified tax rate. '
    + '<br>If the net amount was set first, the gross amount is calculated from the net and the specified tax rate.'
    + '<br><br>',
  fkInfoTitletaxValue: 'tax amount',
  fkInfoMessagetaxValue: '<b>tax amount:</b>'
    + '<br>The tax amount should be entered in this field. '
    + '<br>If the gross amount and the tax rate have been set, the tax amount is automatically calculated from the specified values. '
    + '<br>If the tax rate is subsequently changed, the gross amount is recalculated.'
    + '<br><br>',
  fkInfoTitleaccount: 'Assignment',
  fkInfoMessageaccount: '<b>Assignment:</b>'
    + '<br>The account assignment should be entered in this field. '
    + '<br>A search will automatically take place after you have entered something in this field. '
    + 'If something is found, a selection list appears below the field, from which the appropriate account assignment can be selected.'
    + '<br><br>',
  fkInfoTitleledgerAccount: 'Ledger',
  fkInfoMessageledgerAccount: '<b>Ledger:</b>'
    + '<br>Here you have the option to select a general ledger account. '
    + '<br>Adding, changing or deleting a general ledger account is possible within the administration, '
    + 'under the point "ledger account". All previous ledger accounts are listed there.'
    + '<br><br>',
  fkInfoTitlepayout: 'Payout',
  fkInfoMessagepayout: '<b>Payout:</b>'
    + '<br>The payment amount is automatically calculated from the information in the table.'
    + '<br><br>',
  fkInfoTitleapprover: 'Approver',
  fkInfoMessageapprover: '<b>Approver:</b>'
    + '<br>Approvers can be specified here. <br> It is possible to define several approvers. '
    + 'In addition, you have the option of setting a threshold for an approver, '
    + 'i.e. from a certain payment amount, a certain approver is automatically activated and entered.'
    + '<br> The order of the approvers can also be specified here.'
    + '<br><br>',
  fkInfoTitleinspector: 'Inspector',
  fkInfoMessageinspector: '<b>Inspector:</b>'
    + '<br>Inspector can be specified here. <br> It is possible to define several inspector.'
    + 'In addition, you have the option of setting a threshold for an inspector, '
    + 'i.e. from a certain payment amount, a certain inspector is automatically activated and entered.'
    + '<br> The order of the inspector can also be specified here.'
    + '<br><br>',
  fkInfoTitlefile: 'Attach documents',
  fkInfoMessagefile: '<b>Attach documents:</b>'
    + '<br>Required documents can be uploaded here. '
    + '<br>You can also specify which documents are required. That means the respective categories can be defined here.'
    + '<br><br>',
  fkInfoTitledateRange: 'date range',
  fkInfoMessagedateRange: '<b>time range:</b>'
    + '<br>start date, start time, end date and end time can be configured here. '
    + '<br><br>',
  fkInfoTitletaxNumber: 'TaxNumber',
  fkInfoMessagetaxNumber: 'The taxnumber can be configured here.',
  fkInfoTitleustId: 'Ust-Id',
  fkInfoMessageustId: 'The Ust-Id can be configured here.',
  fkInfoTitleassignment: 'Assignment',
  fkInfoMessageassignment: 'The assignment field can be maintained by an auditor.'
    + ' To do this, the field must be defined as \'Visible\' and defined as editable for the relevant role. ',
  fkInfoTitledifferentBillingAddress: 'Different Billing Address',
  fkInfoMessagedifferentBillingAddress: 'Here you can set whether there is a different billing address.',
  fkInfoTitlenameBA: 'Name of the different billing address',
  fkInfoMessagenameBA: 'The name of the different billing address can be entered here.',
  fkInfoTitlenameAdditionBA: 'Additional name of the different billing address',
  fkInfoMessagenameAdditionBA: 'Here the name addition of the different billing address can be configured',
  fkInfoTitlestreetBA: 'Street of the different billing address',
  fkInfoMessagestreetBA: 'The street of the different billing address can be configured here.',
  fkInfoTitlezipCodeBA: 'Postcode of the different billing address',
  fkInfoMessagezipCodeBA: 'The postal code of the different billing address can be configured here.',
  fkInfoTitlecityBA: 'Location of the different billing address',
  fkInfoMessagecityBA: 'The location of the different billing address can be configured here.',
  fkInfoTitlelocation: 'Location',
  fkInfoMessagelocation: 'The location can be configured here.',
  fkInfoTitleparticipantCount: 'Participant Count',
  fkInfoMessageparticipantCount: 'The maximum number of participants can be specified.',
  fkInfoTitleparticipants: 'Participants',
  fkInfoMessageparticipants: 'Settings for the list of participants can be made here.',
  fkInfoTitleparticipantName: 'Name of participants',
  fkInfoMessageparticipantName: 'The name of the participants can be configured here.',
  fkInfoTitleparticipantCompany: 'Company of participants',
  fkInfoMessageparticipantCompany: 'Company of participants if they are a employee.',
  fkInfoTitleparticipantCompanyName: 'Company of participants',
  fkInfoMessageparticipantCompanyName: 'The participant company can be configured here.',
  fkInfoTitleisEmployee: 'Is participant an employee?',
  fkInfoMessageisEmployee: 'Checkbox that defines if the participant is a employee',
  fkInfoTitleemployeeInfo: 'Hint for external participants',
  fkInfoMessageemployeeInfo: 'Hint, which gets display if it is possible to mark a participant as employee. The text defind as "default"'
    + ' will be displayed.',
  fkInfoTitleparticipantUser: 'name of participant if employee',
  fkInfoMessageparticipantUser: 'user search für participants that are employees',
  fkInfoTitleroom: 'Room',
  fkInfoMessageroom: 'The room can be configured for a location here.',
  fkInfoTitledeliveryType: 'Delivery Type',
  fkInfoTitledelivery: 'Delivery',
  fkInfoMessagedelivery: 'Time at which the delivery should take place.',
  fkInfoMessagedeliveryType: 'The delivery type can be configured here.',
  fkInfoTitlearticles: 'Article',
  fkInfoMessagearticles: 'The articles are displayed here.',
  fkInfoTitlearticleName: 'Articlename',
  fkInfoMessagearticleName: 'The article name can be configured here.',
  fkInfoTitleamount: 'Amount',
  fkInfoMessageamount: 'The number of articles can be configured here.',
  fkInfoTitleorderedAmount: 'Ordered Amount',
  fkInfoMessageorderedAmount: 'The ordered number of items can be set here.',
  fkInfoTitledeliveryAmount: 'Delivery Amount',
  fkInfoMessagedeliveryAmount: 'The number of articles delivered can be configured here.',
  fkInfoTitleunit: 'Unit',
  fkInfoMessageunit: 'The unit for the articles can be set here.',
  fkInfoTitleprice: 'Unit price',
  fkInfoMessageprice: 'The unit price for the items can be configured here.',
  fkInfoTitlesum: 'Sum',
  fkInfoMessagesum: 'The sum of the articles can be set here.',
  fkInfoTitledeliverySum: 'Sum delivery Article',
  fkInfoMessagedeliverySum: 'The total of the delivered articles can be configured here.',
  fkInfoTitleorderedSum: 'Sum ordered Articel',
  fkInfoMessageorderedSum: 'The total of the ordered items can be configured here.',
  fkInfoTitlenameAddition: 'Name Addition',
  fkInfoMessagenameAddition: 'The additional name can be set here.',
  fkInfoTitledate: 'Date',
  fkInfoMessagedate: 'The date can be configured here.',
  fkInfoTitlenumberOfPersons: 'Number of Persons',
  fkInfoMessagenumberOfPersons: 'The number of people can be set here.',
  fkInfoTitleleaflet: 'Notes on billing',
  fkInfoMessageleaflet: 'Settings for the information sheet can be made here.',
  fkInfoTitlemaxPayoutPerPerson: 'Maximum payout per person',
  fkInfoMessagemaxPayoutPerPerson: 'These setting get used to calculated the maximum of payout field. It is only importat that the'
    + ' value "maximum" is filled. All other setting are ignored.',
  fkInfoTitlesubject: 'Subject',
  fkInfoMessagesubject: 'The subject is different from the intended use.',
  fkInfoTitleadditionalSubject: 'Additional subject',
  fkInfoMessageadditionalSubject: 'Supplement to the actual subject',
  fkInfoTitlecontactPerson: 'Contact Person',
  fkInfoMessagecontactPerson: 'Person to be contacted in case of queries.',
  fkInfoTitlecontractNumber: 'Contract number',
  fkInfoMessagecontractNumber: 'Identification number of a contract',
  fkInfoTitlephoneNr: 'Contact Phone Number',
  fkInfoMessagephoneNr: 'Contact person phone number',

  fkInfoTitledueDate: 'Due Date',
  fkInfoMessagedueDate: 'Due date',
  fkInfoTitleinvoiceDate: 'Date of invoice',
  fkInfoMessageinvoiceDate: 'Date on which the invoice was created.',
  fkInfoTitlebookedOn: 'Booked on date',
  fkInfoMessagebookedOn: 'This daten can be set by approver or inspector.'
    + ' For that to be possible the field needs to be defined as \'visible\' and as editable for the respective role.',
  fkInfoTitleaccountingContact: 'Contact person in accounting',
  fkInfoMessageaccountingContact: 'Is used to store a contact person in the help text. '
    + 'The "Default value" field is used for this. The information text can be hidden with '
    + 'the "Visible" switch. All other settings are ignored.',

  fkInfoTitleserviceDescription: 'service description',
  fkInfoMessageserviceDescription: 'Description of the service',
  fkInfoTitletermsOfPayment: 'terms of payment',
  fkInfoMessagetermsOfPayment: 'A fixed list with all payment terms. <br>' +
    ' If a standard value is to be set, only the respective Z numbers or "NOST" may be specified.' +
    '<br><br>' +
    '• Z001 - 10 days after ME + RE, without deduction <br>' +
    '• Z004 - 14 days after ME + RE without deduction<br>' +
    '• Z007 - 30 days after ME + RE, without deduction <br>' +
    '• Z016 - immediately after ME + RE, without deduction <br>' +
    '• Z019 - immediately after receipt of the invoice <br>' +
    '• Z039 - by offsetting <br>' +
    '• Z107 - 21 days after receipt of invoice without deduction <br>' +
    '• Z136 - the bill has already been paid<br>' +
    '• Z166 - 14 + 2 days, without direct debit <br>' +
    '• ZN05 - Energy trading <br>' +
    '• ZU12 - Posting date <br>' +
    '• Z152 - on the 10th of the ME + RE following month net <br>' +
    '• Z158 - 12 days after the date of the contract <br>' +
    '• Z041 - Direct debit rent <br>' +
    '• Z052 - 60 days after ME + RE without deduction <br>' +
    '• NOST - no information <br>',
  fkInfoTitlefacts: 'Facts',
  fkInfoMessagefacts: 'A standard value from a fixed list can be entered here. If no default value is set,' +
    ' the user can choose in the form.' +
    '<br> <br>The list of facts:' +
    '<br><br>' +
    '• Utilities and network contracts - individual contracts <br>' +
    '• Supply and network contracts - preparation of long-term contracts <br>' +
    '• Energy trading sales <br>' +
    '• Network usage discount <br>' +
    '• Blocking <br>' +
    '• Blocking attempt <br>' +
    '• Sponsorship <br>' +
    '• Street lighting <br>' +
    '• Electricity tax free amount <br>' +
    '• Drum loan period <br>' +
    '• maintenance <br>' +
    '• Certification <br>' +
    '• Interest calculation <br>' +
    '• Others <br>' +
    '• Damage <br>' +
    '• Catering <br>' +
    '• e-portal <br>' +
    '• Fleet <br>' +
    '• personal <br>' +
    '• postage <br>' +
    '• Trouble shooting <br>' +
    '• Claims - Derecognition <br>' +
    '• Damages - Warranty/complaints <br>' +
    '• Damages - Hull <br>' +
    '• Losses - customer claims <br>' +
    '• Asset sale <br>',
  fkInfoTitlevisContractNumber: 'VIS-ContractNumber',
  fkInfoMessagevisContractNumber: 'A unique number from the VIS-Tool which is shown on the invoice.',
  fkInfoTitleservicePeriod: 'service period',
  fkInfoMessageservicePeriod: 'The period during which the service was provided.',
  fkInfoTitleplaceOfPerformance: 'place of performance',
  fkInfoMessageplaceOfPerformance: 'The place where the service was provided.',
  fkInfoTitleinternalNote: 'internal note',
  fkInfoMessageinternalNote: 'a internal note',
  fkInfoTitleadditionalInfoOnInvoice: 'Additional information on the invoice',
  fkInfoMessageadditionalInfoOnInvoice: 'Additional information on the invoice',
  fkInfoTitledienArticle: 'DIEN-Article',
  fkInfoMessagedienArticle: 'DIEN-Article',
  fkInfoTitlerecurringAccounting: 'recurring accounting',
  fkInfoMessagerecurringAccounting: 'Checkbox if there is a recurring accounting or not. ' +
    '<br>If Visible, the user has the option of selecting the fields Billing interval, Billing date and Billing to... fields.',
  fkInfoTitlebillingInterval: 'billing interval',
  fkInfoMessagebillingInterval: 'a fixed list consisting of:' +
    '<br>•annually' +
    '<br>•monthly' +
    '<br>•quarterly',
  fkInfoTitlebillingDate: 'Settlement date',
  fkInfoMessagebillingDate: 'A fixed list consisting of:' +
    '<br>• 1. of the month' +
    '<br>• 15. of the month' +
    '<br>• 30. of the month',
  fkInfoTitlebillingUntil: 'Settlement by',
  fkInfoMessagebillingUntil: 'By when should the settlement take place',
  fkInfoTitledepartment: 'Department',
  fkInfoMessagedepartment: 'The department can be taken over directly from "My data" with "Take over personal data".',
  fkInfoTitletelephone: 'Telephonenumber',
  fkInfoMessagetelephone: 'The telephonenumber can be taken over directly from "My data" with "Take over personal data".',
  fkInfoTitledifferentDeliveryAddress: 'Differing Shipping Address',
  fkInfoMessagedifferentDeliveryAddress: 'Here you can specify whether there is a different delivery address.',
  fkInfoTitlenameDA: 'Name of Differing Shipping Address',
  fkInfoMessagenameDA: 'The name can be configured here if the delivery address is different.',
  fkInfoTitlenameAdditionDA: 'name suffix of Differing Shipping Address',
  fkInfoMessagenameAdditionDA: 'The addition to the name can be configured here if the delivery address differs.',
  fkInfoTitlestreetDA: 'street of Differing Shipping Address',
  fkInfoMessagestreetDA: 'The street can be configured here if the delivery address differs.',
  fkInfoTitlezipCodeDA: 'postcode of Differing Shipping Address',
  fkInfoMessagezipCodeDA: 'The postcode can be configured here if the delivery address differs.',
  fkInfoTitlecityDA: 'City of Differing Shipping Address',
  fkInfoMessagecityDA: 'The city can be configured here if the delivery address differs.',
  fkInfoTitlephoneDA: 'Telephonenumber of Differing Shipping Address',
  fkInfoMessagephoneDA: 'The telephonenumber can be configured here if the delivery address differs.',
  fkInfoTitlecontactPersonDA: 'Contact person of Differing Shipping Address',
  fkInfoMessagecontactPersonDA: 'The contact person can be configured here if the delivery address differs.',
  fkInfoTitleemailMyData: 'E-Mail personal Data',
  fkInfoMessageemailMyData: 'Here you can make settings for the field e-mail address from "my data", which is displayed within the form.',
  fkInfoTitleproductGroup: 'Product Group',
  fkInfoMessageproductGroup: 'Settings for the product group field can be made here. Please also note the text before you can open the small order form.',
  fkInfoTitlestreetOA: 'Orderadress street',
  fkInfoMessagestreetOA: 'Settings for the street of the order address can be made here.',
  fkInfoTitlezipCodeOA: 'Orderadress postcode',
  fkInfoMessagezipCodeOA: 'Settings for the postcode of the order address can be made here.',
  fkInfoTitlecityOA: 'Orderadress city',
  fkInfoMessagecityOA: 'Settings for the city of the order address can be made here.',
  fkInfoTitledistrictOA: 'Orderadress district',
  fkInfoMessagedistrictOA: 'Settings for the district of the order address can be made here.',
  fkInfoTitletimeOA: 'Orderadress ordertime',
  fkInfoMessagetimeOA: 'Settings for the ordertime of the order address can be made here.',
  fkInfoTitlepurchasingGroup: 'Purchasinggroup',
  fkInfoMessagepurchasingGroup: 'Settings for the purchasing group can be made here.',
  fkInfoTitleservice: 'Service',
  fkInfoMessageservice: 'Settings for the service can be made here.',
  fkInfoTitlespecialReferences: 'special references',
  fkInfoMessagespecialReferences: 'Settings for special information that the user would like to enter can be made here.',
  fkInfoTitleadditionalTechnicalConditions: 'technical conditions',
  fkInfoMessageadditionalTechnicalConditions: 'Settings for additional technical conditions that the user would like to enter can be made here.',
  fkInfoTitlemasterAgreementSelected: 'Master agreement Checkbox',
  fkInfoMessagemasterAgreementSelected: 'Here you can set whether the user sees the "Framework Agreement" checkbox or whether it is mandatory.',
  fkInfoTitlemasterAgreement: 'master agreement',
  fkInfoMessagemasterAgreement: 'Settings for the "Framework Agreement" field can be made here.',
  fkInfoTitlemasterAgreementPosition: 'master agreement Position',
  fkInfoMessagemasterAgreementPosition: 'Settings for the position of the framework agreement can be made here.',
  fkInfoTitleattachedOfferSelected: 'attached offer',
  fkInfoMessageattachedOfferSelected: 'Settings for the checkbox can be made here.',
  fkInfoTitledeliveryDate: 'deliverydate',
  fkInfoMessagedeliveryDate: 'Settings for the delivery or execution date can be made here. ' +
    'The field is located within the position table.',

  supervisoryBoardMeatingCompansationFilename: i9e`credit-${'publicId'}.pdf`,
  energyTradePurchaseFilename: i9e`credit-energy-trading-${'publicId'}.pdf`,

  weekendsActive: 'weekends selectable',
  minDate: 'min. date',
  maxDate: 'max. date',
  minDateCurrentDate: 'min. date current date',
  maxDateCurrentDate: 'max. Datum current date',
  predefineWithCurrentDate: 'predifine with current date',
  startDateVisible: 'start date visible',
  startTimeVisible: 'start time visible',
  endDateVisible: 'end date visible',
  endTimeVisible: 'end time visible',
  maxRangeLength: 'max. time between start/end (seconds)',
  minRangeLength: 'min. time between start/end (seconds)',
  minStartInFuture: 'min. start in future (seconds)',
  maxStartInFuture: 'max. start in future (seconds)',
  startPredefinedFromNow: 'start default value from creation (±seconds)',
  endPredefinedFromNow: 'end default value from creation (±seconds)',

  summary: 'summary',
  process: 'process',
  approval: 'approval',
  admin: 'administration',
  catering: 'catering',
  applicant: 'applicant',
  templateSaveSuccess: 'template successful saved',
  coreConfigSystemMessageAdminLoadSuccess: 'system message not loaded',
  coreConfigSystemMessageAdminLoadError: 'error loading system message',
  coreConfigSystemMessageSaveSuccess: 'system message saved',
  coreConfigSystemMessageSaveError: 'system message not saved',
  removeCostCenterTitle: 'remove cost center',
  removeCostCenterText: 'Do you really want to delete the cost center?',
  removeArrangerTitle: 'delete arranger',
  removeArrangerText: 'Do you really want to delete this arranger?',
  decimalPlacesPlaceholder: 'Max Decimal Places (-1 = inf.)',

  createReceipt: 'create receipt',
  taxNumber: 'tax number',
  district: 'district',
  timePeriod: 'desired time',

  type: 'type',
  ARBITRARY: 'arbitrary',
  PRE_DEFINED: 'predefind',
  GROUPED: 'grouped',

  /* Infotexte zu AF17*/
  deduction: '• For booking reasons, 5.00 € will be deducted from the right to advance payment.',
  refund: '• When the advance is settled, the € 5.00 will be reimbursed.',


  /*Texte für K2*/
  productGroupMissing: 'Productgroup is missing. Please edit the proposal.',
  fkInfoTitledetailsAndPaymentAmount: 'Erläuterung zu den Positionsdetails',
  fkInfoMessagedetailsAndPaymentAmount: ``,

  /*Texte für A4*/
  invoiceAttachmentMissing: 'Invoice attachment is missing. Please edit the proposal.',

  /*Texte für U1*/
  voucherInfo: 'Please enter the fiscal year and the document number in step: "Document selection".',

  typeString: 'text',
  typeDate: 'date',
  typeDaterange: 'start/end date time',
  typeAccounting: 'accounting',
  typeNumber: 'number',
  typeList: 'list',
  typeArranger: 'arranger selection',
  typeArrangerList: 'arranger list',
  typeTextarea: 'textarea',
  typeCheckbox: 'checkbox',
  typeFileUpload: 'files',
  typeLedgerAccount: 'ledger account',
  typeListItem: 'list item',

  downloadExcelTemplateHeader: 'Download Excel template',
  downloadExcelTemplateInfo: 'For certain form you can download, fill out and upload an excle sheet. Based on this sheet new proposals'
    + ' will be generated and set to the next possile state ( e.q. \'in approval\' or \'finished\')',
  downloadExcelTemplateProcessLabel: 'Generate proposal templates for the selected form:',
  downloadExcelTemplateCountLabel: 'number of proposal templates that should be generated',
  downloadExcelTemplateButtonLabel: 'Download Excel template',

  uploadExcelTemplateHeader: 'Upload Proposal as excel sheet',
  uploadExcelTemplateInfo: 'Here you can upload an excel sheet that is then used to create proposals',
  uploadExcelTemplateHint: `<p><strong>Disclaimer:</strong></p>
              <div>
                <ul>
                  <li>The excel sheet must comply to the given specification. Please use a excel sheet that was generate via the download function.
                  </li>
                  <li>Sender information must be filled</li>
                  <li>Fields that are mandatory in the normale creation process are also mandatory when a proposal is created via mass data processing.
                  </li>
                  <li>You will be notified via e-mail if it is not possible to create a proposal based on the uploaded excel sheet. Please correct the error mentioned in that e-mail and upload the excel sheet again.
                  </li>
                </ul>
              </div>`,
  uploadExcelTemplateButtonLabel: 'upload excel sheet',

  duplication: 'duplicated proposal',
  duplicationInfo: 'An identical proposal has already been created.Please check the proposal.',

  /*Massendaten*/
  massDataExcelError: 'excel could not be created.',
  massDataSuccess: 'massdata processing startet. We will inform you about the state separately with an email.',
  massDataError: 'massdata processing could not be started.',
};


export const translations = {
  de,
  en,
};

export type TranslationKeys = keyof typeof de;
