import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FormControlState, NgrxValueConverter } from 'ngrx-forms';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import { ApiService } from '../../../core-lib/services/api.service';
import { SapInfoModel } from '../../models/sap-info.model';
import { SearchModalBaseData } from '../../utils/search-modal-base';
import { FormAccountingModalComponent } from '../form-accounting-modal/form-accounting-modal.component';

@Component({
  selector: 'lib-common-form-accounting-input',
  templateUrl: './form-accounting-input.component.html',
  styleUrls: ['./form-accounting-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FormAccountingInputComponent implements OnChanges, OnDestroy {
  @Input() controlState: FormControlState<string>;
  @Input() configName: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() modalDebounce: number; // modal debounce in ms
  @Input() modalMinLength: number; // modal minLength in chars
  @Input() valueConverter: NgrxValueConverter<string, unknown>;
  @Input() placeholder: string;
  @Input() selectedCompany: CompanyDataDtoModel;
  @Input() waitForTouch = true;
  @Input() disabledOverride: boolean;
  @Input() type: 'ORDER' | 'COST_CENTER' | 'PSP_ELEMENT';

  modelChangedEmitter = new EventEmitter<SapInfoModel>();
  @Output() modelChanged: Observable<SapInfoModel> = this.modelChangedEmitter.pipe(
    filter(() => !this.waitForTouch || this.isTouched),
  );

  isTouched = false;
  filteredOptions = new EventEmitter<SapInfoModel[]>();
  value: SapInfoModel;
  tempValueWaitingForSelectedCompany: SapInfoModel;
  showSpinner: boolean;
  onDestroy$ = new EventEmitter<void>();
  filterChanged$ = new EventEmitter<string>();

  constructor(private api: ApiService, private dialog: MatDialog) {
    this.filterChanged$.pipe(
      takeUntil(this.onDestroy$),
      tap(() => this.showSpinner = true),
      tap(() => this.filteredOptions.emit([])),
      switchMap((input) => this.getData(input).pipe(
        catchError(e => {
          this.showSpinner = false;
          console.error(e);
          return of([]);
        }),
      )),
      tap(() => this.showSpinner = false),
    ).subscribe(filteredOptions => this.filteredOptions.emit(filteredOptions));
  }

  displayFn = (info: SapInfoModel) => {
    if (!info?.description) {
      return info ? info.id : undefined;
    } else {
      return info ? `${info.id} - ${info.description}` : undefined;
    }
  };

  ngOnChanges() {
    if (this.tempValueWaitingForSelectedCompany && this.selectedCompany) {
      this.valueChanged$(this.tempValueWaitingForSelectedCompany).subscribe((accountingInfo) => {
        this.filterChanged(accountingInfo);
      });
      this.tempValueWaitingForSelectedCompany = undefined;
    }
  }

  getEmitValueFn = (accountingInfo: SapInfoModel) => !!accountingInfo ? accountingInfo : undefined;

  ngOnDestroy(): void {
    this.onDestroy$.emit();
    this.onDestroy$.complete();
  }

  filterChanged($event: string) {
    this.filterChanged$.emit($event);
  }

  valueChanged$($event: SapInfoModel) {
    if (!this.selectedCompany) {
      this.tempValueWaitingForSelectedCompany = $event;
      return;
    }

    const changed$ = this.getData($event.id).pipe(
      tap((filteredOptions) => this.filteredOptions.emit(filteredOptions)),
      map(accountingInfos => accountingInfos.length > 1 ? $event : accountingInfos.length > 0 ? accountingInfos[0] : undefined),
      catchError(e => {
        console.error(e);
        return of(undefined);
      }),
    );
    changed$.subscribe(accountingInfo => {
      this.value = accountingInfo;
    });
    return changed$;
  }

  touched() {
    this.isTouched = true;
  }

  subModelChanged($event: unknown) {
    const value = <SapInfoModel | string>$event;
    if (typeof value === 'object') {
      this.modelChangedEmitter.emit(value);
    }
  }

  openDialog($event: Event) {
    $event.stopPropagation();
    this.dialog.open<FormAccountingModalComponent, SearchModalBaseData, SapInfoModel>(
      FormAccountingModalComponent,
      {
        minWidth: '40vw',
        data: {
          debounce: this.modalDebounce,
          minLength: this.modalMinLength,
          selectedCompany: this.selectedCompany,
        },
      },
    ).afterClosed().pipe(
      takeUntil(this.onDestroy$),
      filter(creditor => !!creditor),
    ).subscribe((creditor) => {
      this.subModelChanged(creditor);
      this.value = creditor;
      this.filteredOptions.emit([creditor]);
    });
  }

  getData(input) {
    let data: Observable<SapInfoModel[]>;
    if (this.type) {
      data = this.api.postSearchAccounting(this.selectedCompany, {searchTerm: input, type: this.type});
    } else {
      data = this.api.getValidateAccounting(this.selectedCompany, input);
    }

    return data.pipe(map(value => value.filter(entry => entry.id !== '')));

  }
}
