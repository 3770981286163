import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-easy-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBar: MatSnackBar) {}

  public closeSnackbar() {
    this.snackBar.dismiss();
  }
}
