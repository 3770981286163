import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppConfigService } from '../services/app-config.service';

@Injectable()
export class AppConfigGuard  {

  constructor(private store$: Store<any>, private appConfigService: AppConfigService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const configProp: string = route.data.configProp || '';

    return this.appConfigService.config[configProp] || false;
  }
}
