import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgrxFormsModule } from 'ngrx-forms';
import { CommonMaterialModule } from '../../../common-material/src/lib/common-material.module';
import { CoreLibModule } from '../core-lib/core-lib.module';
import { ApproverInspectorComponent } from './components/approver-inspector/approver-inspector.component';
import { ApproverListComponent } from './components/approver-list/approver-list.component';
import { ArticleListComponent } from './components/article-list/article-list.component';
import { DeclineReasonDisplayComponent } from './components/decline-reason-display/decline-reason-display.component';
import { DisplayFormNameComponent } from './components/display-form-name/display-form-name.component';
import { FormAccountingInputComponent } from './components/form-accounting-input/form-accounting-input.component';
import { FormAccountingModalComponent } from './components/form-accounting-modal/form-accounting-modal.component';
import { FormActionsComponent } from './components/form-actions/form-actions.component';
import { FormApproveActionsComponent } from './components/form-approve-actions/form-approve-actions.component';
import { FormCommentBoxComponent } from './components/form-comment-box/form-comment-box.component';
import { FormCompanySelectorComponent } from './components/form-company-selector/form-company-selector.component';
import { FormConfirmActionsComponent } from './components/form-confirm-actions/form-confirm-actions.component';
import { FormCrediDebiTorInputComponent } from './components/form-credi-debi-tor-input/form-credi-debi-tor-input.component';
import { FormCrediDebiTorModalComponent } from './components/form-credi-debi-tor-modal/form-credi-debi-tor-modal.component';
import { FormCreditorDebitorIbanComponent } from './components/form-creditor-debitor-iban/form-creditor-debitor-iban.component';
import { FormFieldCheckboxComponent } from './components/form-field-checkbox/form-field-checkbox.component';
import { FormFieldDateComponent } from './components/form-field-date/form-field-date.component';
import { FormFieldInputComponent } from './components/form-field-input/form-field-input.component';
import { FormFieldSelectComponent } from './components/form-field-select/form-field-select.component';
import { FormInnerMarginComponent } from './components/form-inner-margin/form-inner-margin.component';
import { FormLedgerAccountModalComponent } from './components/form-ledger-account-modal/form-ledger-account-modal.component';
import { FormLedgerAccountSelectorComponent } from './components/form-ledger-account-selector/form-ledger-account-selector.component';
import { FormLockedDisplayComponent } from './components/form-locked-display/form-locked-display.component';
import { FormRecurringAccountingComponent } from './components/form-recurring-accounting/form-recurring-accounting.component';
import { FormSapAutocompleteInputComponent } from './components/form-sap-autocomplete/form-sap-autocomplete-input.component';
import { FormStepAdditionalsComponent } from './components/form-step-additionals/form-step-additionals.component';
import { FormTaxrateSelectorComponent } from './components/form-taxrate-selector/form-taxrate-selector.component';
import { LeafletLinkComponent } from './components/leaflet-link/leaflet-link.component';
import { ParticipantListComponent } from './components/participant-list/participant-list.component';
import { PositionAccountingComponent } from './components/position-accounting/position-accounting.component';
import { PositionBANFComponent } from './components/position-banf/position-banf.component';
import { PositionComponent } from './components/position/position.component';
import { ProcessDuplicateInformationComponent } from './components/process-duplicate-information/process-duplicate-information.component';
import { ProcessInformationDisplayComponent } from './components/process-information-display/process-information-display.component';
import { ProposalPreselectionComponent } from './components/proposal-preselection/proposal-preselection.component';
import { RegionServiceCenterSelectorComponent } from './components/region-service-center-selector/region-service-center-selector.component';


@NgModule({
  declarations: [
    FormActionsComponent,
    FormConfirmActionsComponent,
    FormApproveActionsComponent,
    FormFieldCheckboxComponent,
    FormFieldInputComponent,
    FormFieldDateComponent,
    FormFieldSelectComponent,
    FormInnerMarginComponent,
    ApproverListComponent,
    FormTaxrateSelectorComponent,
    FormLedgerAccountSelectorComponent,
    FormCompanySelectorComponent,
    FormAccountingInputComponent,
    FormSapAutocompleteInputComponent,
    FormCrediDebiTorInputComponent,
    FormLockedDisplayComponent,
    FormCommentBoxComponent,
    FormStepAdditionalsComponent,
    PositionComponent,
    FormCrediDebiTorModalComponent,
    FormAccountingModalComponent,
    FormLedgerAccountModalComponent,
    ArticleListComponent,
    ParticipantListComponent,
    ProcessInformationDisplayComponent,
    ProposalPreselectionComponent,
    DisplayFormNameComponent,
    DeclineReasonDisplayComponent,
    RegionServiceCenterSelectorComponent,
    PositionAccountingComponent,
    ApproverInspectorComponent,
    ProcessDuplicateInformationComponent,
    FormCreditorDebitorIbanComponent,
    LeafletLinkComponent,
    PositionBANFComponent,
    FormRecurringAccountingComponent,
  ],
  exports: [
    FormActionsComponent,
    FormConfirmActionsComponent,
    FormApproveActionsComponent,
    CoreLibModule,
    CommonMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgrxFormsModule,
    FormFieldCheckboxComponent,
    FormFieldInputComponent,
    FormFieldDateComponent,
    FormFieldSelectComponent,
    FormInnerMarginComponent,
    ApproverListComponent,
    FormTaxrateSelectorComponent,
    FormLedgerAccountSelectorComponent,
    FormCompanySelectorComponent,
    FormAccountingInputComponent,
    FormCrediDebiTorInputComponent,
    FormLockedDisplayComponent,
    FormCommentBoxComponent,
    FormStepAdditionalsComponent,
    PositionComponent,
    ArticleListComponent,
    ProcessInformationDisplayComponent,
    ParticipantListComponent,
    DisplayFormNameComponent,
    DeclineReasonDisplayComponent,
    RegionServiceCenterSelectorComponent,
    PositionAccountingComponent,
    ProcessDuplicateInformationComponent,
    ApproverInspectorComponent,
    FormCreditorDebitorIbanComponent,
    LeafletLinkComponent,
    PositionBANFComponent,
    FormRecurringAccountingComponent,
  ],
  imports: [
    CommonModule,
    CoreLibModule,
    CommonMaterialModule,
    MatSlideToggleModule,
    NgxDatatableModule,
    A11yModule,
  ],
})
export class FormsLibModule {
}
