import { Directive, Host, Input, OnInit, Optional } from '@angular/core';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { MatLegacyFormField as MatFormField } from '@angular/material/legacy-form-field';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import * as moment from 'moment';
import { AbstractControlState } from 'ngrx-forms';
import { ApproverListComponent } from '../../forms-lib/components/approver-list/approver-list.component';
import { FormAccountingInputComponent } from '../../forms-lib/components/form-accounting-input/form-accounting-input.component';
import { FormFieldCheckboxComponent } from '../../forms-lib/components/form-field-checkbox/form-field-checkbox.component';
import { FormSapAutocompleteInputComponent } from '../../forms-lib/components/form-sap-autocomplete/form-sap-autocomplete-input.component';
import { CurrencyInputComponent } from '../components/currency-input/currency-input.component';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { UserSearchInputComponent } from '../components/user-search-input/user-search-input.component';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[requiredWhen]',
})
export class RequiredWhenDirective<TValue> implements OnInit {
  element: MatInput
    | MatSelect
    | CurrencyInputComponent
    | FileUploadComponent
    | ApproverListComponent
    | UserSearchInputComponent
    | FormSapAutocompleteInputComponent
    | FormFieldCheckboxComponent;

  shouldBeRequired: boolean;

  constructor(
    @Host() @Optional() private input: MatInput,
    @Host() @Optional() private momentInput: MatDatepickerInput<moment.Moment>,
    @Host() @Optional() private select: MatSelect,
    @Host() @Optional() private currencyInput: CurrencyInputComponent,
    @Host() @Optional() private fileUpload: FileUploadComponent,
    @Host() @Optional() private userSearchInput: ApproverListComponent,
    @Host() @Optional() private accountingInfoInput: FormAccountingInputComponent,
    @Host() @Optional() private sapAutocompleteInput: FormSapAutocompleteInputComponent,
    @Host() @Optional() private checkBox: FormFieldCheckboxComponent,
    @Host() @Optional() private userSearchInputComponent: UserSearchInputComponent,
  ) {
    const args = [...arguments];
    const lastDefined = args.reduce(((prev, curr) => prev || curr), undefined);
    if (!lastDefined) {
      console.warn('RequiredWhenDirective: no defined @Host():', arguments);
    }
    this.element = lastDefined;
  }

  @Input() formField: MatFormField;

  @Input() ngrxFormControlState: AbstractControlState<any>;

  @Input()
  set requiredWhen(input: AbstractControlState<boolean> | boolean) {
    // Todo: Wenn in config mandatory, hier nicht weiter beachten
    this.shouldBeRequired =
      (input instanceof Object ? input.value : input) || this.ngrxFormControlState?.userDefinedProperties.easyConfig?.mandatory;
    this.updateRequired();
  }


  updateRequired() {
    const isRequired = !!(this.element && this.element.required);
    if (isRequired !== this.shouldBeRequired && this.element && this.shouldBeRequired != null /* undefined and null check */) {
      this.setMandatory(this.shouldBeRequired);
      // Hack: angular material not updating required state
      // may be https://github.com/angular/components/issues/2574
      if ('ngOnChanges' in this.element) {
        this.element.ngOnChanges({});
      } else if (this.formField) {
        setTimeout(() => {
          const children: HTMLCollection = this.formField._inputContainerRef.nativeElement.children;
          for (let i = 0; i < children.length; i++) {
            const child = children.item(i);
            (<any>child).focus();
            (<any>child).blur();
          }
        });
      }
    }
  }

  get requiredWhen() {
    return undefined;
  }

  setMandatory(mandatory: boolean) {
    if (this.element && !this.element.disabled) {
      this.element.required = mandatory;
    }
  }

  setElement() {
    this.element =
      this.input
      || this.checkBox
      || this.select
      || this.currencyInput
      || this.userSearchInput
      || this.userSearchInputComponent
      || this.sapAutocompleteInput;

    if (!this.element) {
      console.warn(`RequiredWhenDirective: element is ${this.element}`);
    }
  }

  ngOnInit(): void {
    this.setElement();
    this.updateRequired();
  }
}
