import { createSelector, MemoizedSelectorWithProps } from '@ngrx/store';
import { FormArrayState, FormGroupState, FormState } from 'ngrx-forms';
import { ConfigurationItems } from '../../../core-lib/models/config-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../../core-lib/models/config-item-number-configuration-dto.model';
import { ProposalState } from '../../../core-lib/models/proposal-head.model';
import { getCompaniesState, getConfigTaxRatesControlsArrayState } from '../../../core/ngrx/reducers/core.store';
import { getEasyFormsArrayState } from '../../../core/ngrx/reducers/easy-forms.reducer';
import { FormBaseFeatureState, FormBaseState } from '../../../forms-lib/models/base-state.model';
import { FormPositionDtoModel } from '../../../forms-lib/models/form-position-dto.model';
import { getSessionUser } from '../../../session/reducers/session.reducer';


export interface FormBaseSelectorsProps {
  featureName: string;
  controlId?: string;
}

export interface FormBaseFieldVisibilityProps extends FormBaseSelectorsProps {
  fieldName?: string;
}

export interface FormBaseFieldStateProps extends FormBaseFieldVisibilityProps {
  stepName: string;
}

export class FormBaseSelectors<T extends FormBaseState, C extends ConfigurationItems = ConfigurationItems> {
  // dynamic createFeatureSelector based on props e.g. createFeatureSelector<FormBaseFeatureState>(props.featureName);
  getFormBaseAlikeFeatureState = createSelector(
    (store, props: FormBaseSelectorsProps): FormBaseFeatureState<T, C> => store[props.featureName] ,
    (store) => store as FormBaseFeatureState<T, C>,
  );

  getFormBaseFormState = createSelector(
    this.getFormBaseAlikeFeatureState,
    s => s?.form,
  );

  getFormBaseFormValue = createSelector(
    this.getFormBaseFormState,
    s => s?.value,
  );

  getFormBaseIsDeputy = createSelector(
    this.getFormBaseFormValue,
    (formValue) => formValue?.isDeputy,
  );

  getFormBaseStateValue = createSelector(
    this.getFormBaseFormValue,
    (state) => state?.state,
  );

  getFormBaseFormIdentifier = createSelector(
    this.getFormBaseFormState,
    s => s?.id,
  );

  getFormBaseFormDefinition = createSelector(
    this.getFormBaseFormIdentifier,
    getEasyFormsArrayState,
    (identifier, easyFormsArray) =>
      easyFormsArray?.find(formConfig => formConfig.identifier === identifier),
  );

  getFormBaseFormCompanies = createSelector(
    this.getFormBaseFormDefinition,
    (formDefinition) => (formDefinition && formDefinition.companies) || [],
  );

  getFormBaseFormResolvedCompanies = createSelector(
    this.getFormBaseFormCompanies,
    getCompaniesState,
    (companyIds, companies) => companyIds.map(id => companies.find(c => c.id === id)).filter(c => !!c),
  );

  getFormBaseFormResolvedActiveCompanies = createSelector(
    this.getFormBaseFormResolvedCompanies,
    (companies) => companies.filter(c => c.active),
  );

  getFormBaseStep1State = createSelector<any, any, any, FormGroupState<T['step1']>>(
    this.getFormBaseFormState,
    s => s?.controls.step1,
  );

  getFormBaseStep2State = createSelector(
    this.getFormBaseFormState,
    s => s?.controls.step2,
  );

  getFormBaseStep3State = createSelector<any, any, any, FormGroupState<T['step3']>>(
    this.getFormBaseFormState,
    s => s?.controls.step3,
  );

  getFormBaseStep3StatePositions = createSelector<any, any, any, FormArrayState<FormPositionDtoModel>>(
    this.getFormBaseStep3State,
    s => s?.controls.positions,
  );

  getFormBaseStep3StatePositionsSum = createSelector(
    this.getFormBaseStep3StatePositions,
    s => s?.controls.reduce((acc, cur: FormState<FormPositionDtoModel>) => acc + (cur.value.grossCost || 0), 0),
  );

  getFormBaseStep3PositionTaxRate = createSelector(
    this.getFormBaseStep3StatePositions,
    s => (position: number) => s?.controls?.[position]?.controls?.taxRate,
  );

  getFormBaseStep3PositionTaxRateResolved = createSelector(
    getConfigTaxRatesControlsArrayState,
    this.getFormBaseStep3PositionTaxRate,
    (taxRates, getTaxRate) => (position) => taxRates.find(r => r?.value?.id === getTaxRate(position)?.value?.id),
  );

  getFormBaseStep1CompanyState = createSelector(
    this.getFormBaseStep1State,
    s => s?.controls?.company,
  );

  getFormBaseStep1CompanyResolvedState = createSelector(
    this.getFormBaseStep1CompanyState,
    getCompaniesState,
    (company, companies) => companies.find(c => c?.id === company?.value),
  );

  getFormBaseInitializedState = createSelector(
    this.getFormBaseAlikeFeatureState,
    s => s?.initialized,
  );

  getFormBaseFuvErrorState = createSelector(
    this.getFormBaseAlikeFeatureState,
    s => s?.fuvError,
  );

  getFormBaseDataState = createSelector(
    this.getFormBaseAlikeFeatureState,
    s => s.dataState,
  );

  getFormBaseConfigurationItemsState = createSelector(
    this.getFormBaseAlikeFeatureState,
    s => s?.config?.configurationItems,
  );

  getFormBaseControlByNgrxId = createSelector(
    this.getFormBaseFormState,
    (formState, props) => {
      const controlIds = props?.controlId?.split('.') || [];
      let currentControl = formState;
      for (const controlIdPart of controlIds) {
        const part = Number(controlIdPart) || controlIdPart;
        if (typeof part === 'number' && part >= 0 || part) {
          currentControl = currentControl?.controls?.[part];
        }
      }
      return currentControl;
    },
  );

  getFormBaseConfigurationItemsStateByNgrxId = createSelector(
    this.getFormBaseControlByNgrxId,
    this.getFormBaseConfigurationItemsState,
    (control, configs) => {
      const configName = control?.userDefinedProperties['easyConfigName'];
      return configs?.[configName];
    },
  );

  getFormBasePositionFieldsConfigs = createSelector(
    // netCost|grossCost|taxValue|taxRate
    this.getFormBaseStep3StatePositions,
    this.getFormBaseConfigurationItemsState,
    (s, configs) => {
      const easyConfigName = 'easyConfigName';
      return {
        netCost:
          configs[s?.controls?.[0]?.controls?.netCost?.userDefinedProperties?.[easyConfigName]] as ConfigItemNumberConfigurationDtoModel,
        grossCost:
          configs[s?.controls?.[0]?.controls?.grossCost?.userDefinedProperties?.[easyConfigName]] as ConfigItemNumberConfigurationDtoModel,
        taxValue:
          configs[s?.controls?.[0]?.controls?.taxValue?.userDefinedProperties?.[easyConfigName]] as ConfigItemNumberConfigurationDtoModel,
        taxRate:
          configs[s?.controls?.[0]?.controls?.taxRate?.userDefinedProperties?.[easyConfigName]] as ConfigItemNumberConfigurationDtoModel,
      };
    },
  );

  getFormBaseCurrentStep = createSelector(
    this.getFormBaseAlikeFeatureState,
    s => s?.currentStep,
  );

  getIsCurrentUserAuthor = createSelector(
    this.getFormBaseFormValue,
    getSessionUser,
    (form, user) => form?.authorId === user?.id,
  );

  getFormBaseCurrentApproverUserId = createSelector(
    this.getFormBaseFormValue,
    (form) => form?.currentApproverId,
  );

  getFormBaseCurrentInspectorUserId = createSelector(
    this.getFormBaseFormValue,
    (form) => form?.currentInspectorId,
  );

  getFormBaseCurrentUserIsAuthorizedApprover = createSelector(
    getSessionUser,
    this.getFormBaseCurrentApproverUserId,
    this.getFormBaseIsDeputy,
    this.getFormBaseStateValue,
    (user, currentApproverUserId, isDeputy, state) =>
      ((!!user && user.id === currentApproverUserId) || isDeputy) && state === ProposalState.IN_APPROVAL,
  );

  getFormBaseCurrentUserIsAuthorizedInspector = createSelector(
    getSessionUser,
    this.getFormBaseCurrentInspectorUserId,
    this.getFormBaseIsDeputy,
    this.getFormBaseStateValue,
    (user, currentInspectorUserId, isDeputy, state) =>
      ((!!user && user.id === currentInspectorUserId) || isDeputy) && state === ProposalState.IN_INSPECT,
  );

  getFormBaseCurrentUserIsAuthorizedArranger = createSelector(
    this.getFormBaseCurrentUserIsAuthorizedApprover,
    this.getFormBaseCurrentUserIsAuthorizedInspector,
    (isApprover, isInspector) => (isApprover || isInspector),
  );

  getFormBaseCurrentUserCanEdit = createSelector(
    this.getFormBaseStateValue,
    this.getFormBaseConfigurationItemsState,
    this.getFormBaseCurrentUserIsAuthorizedApprover,
    this.getFormBaseCurrentUserIsAuthorizedInspector,
    this.getFormBaseIsDeputy,
    (
      formState,
      config,
      currentUserIsAuthorizedApprover,
      currentUserIsAuthorizedInspector,
      isDeputy,
    ) => {
      let propName;

      if (currentUserIsAuthorizedApprover) {
        propName = 'changeableByApprover';
      } else if (currentUserIsAuthorizedInspector) {
        propName = 'changeableByInspector';
      }

      if (isDeputy) {
        if (formState === ProposalState.IN_APPROVAL) {
          propName = 'changeableByApprover';
        } else if (formState === ProposalState.IN_INSPECT) {
          propName = 'changeableByInspector';
        }
      }

      return !!propName && Object.values(config).reduce((editable, configItem) => editable || configItem[propName], false);
    },
  );

  getFormBaseMessage = createSelector(
    this.getFormBaseFormValue,
    (s) => s?.message,
  );

  getFormBaseMessageHistory = createSelector(
    this.getFormBaseFormValue,
    (s) => s?.declineHistory,
  );

  getFormBaseTemplateValuesApplied = createSelector(
    this.getFormBaseAlikeFeatureState,
    (s) => s?.templateValuesApplied,
  );

  getFormBaseOpen = createSelector(
    this.getFormBaseAlikeFeatureState,
    (s) => s?.open,
  );

  getFormBaseLockedUserId = createSelector(
    this.getFormBaseAlikeFeatureState,
    this.getFormBaseOpen,
    (featureState, sessionUser, open) => open ? featureState.lockUserId : undefined,
  );

  getFormBaseIsLockedByCurrentUser = createSelector(
    this.getFormBaseLockedUserId,
    getSessionUser,
    (lockedUserId, sessionUser) => sessionUser && lockedUserId === sessionUser.id,
  );

  getFormBaseComment = createSelector(
    this.getFormBaseFormState,
    (formState) => formState && formState.controls.comment,
  );

  getFormBaseIsLockable = createSelector(
    this.getFormBaseFormState,
    (formState) => !!(formState && formState.value.id),
  );

  getFormBaseShowApprovalButtons = createSelector(
    this.getFormBaseIsDeputy,
    this.getFormBaseCurrentUserIsAuthorizedApprover,
    this.getFormBaseCurrentUserIsAuthorizedInspector,
    this.getFormBaseFormValue,
    (isDeputy, currentUserIsAuthorizedApprover, currentUserIsAuthorizedInspector, state) =>
      (currentUserIsAuthorizedApprover || currentUserIsAuthorizedInspector || isDeputy)
      && !([ProposalState.APPROVED, ProposalState.DECLINED, ProposalState.FINISHED, ProposalState.SAP_ERROR].includes(state?.state)),
  );

  getFormBaseStepFieldStateFactory: MemoizedSelectorWithProps<any, FormBaseFieldStateProps, any> = createSelector(
    this.getFormBaseFormState,
    (
      s,
      { stepName, fieldName }: FormBaseFieldStateProps,
    ) => (<FormGroupState<any> | FormArrayState<any>>s?.controls?.[stepName])?.controls?.[fieldName],
  );

  getFormBaseStepFieldValueFactory: MemoizedSelectorWithProps<any, FormBaseFieldStateProps, any> = createSelector(
    this.getFormBaseStepFieldStateFactory,
    s => s?.value,
  );

  getFormBaseFieldVisibilityFactory: MemoizedSelectorWithProps<any, FormBaseFieldVisibilityProps, any> = createSelector(
    this.getFormBaseConfigurationItemsState,
    (s, { fieldName }: FormBaseFieldVisibilityProps) => s?.[fieldName]?.visible,
  );

  getFormBaseVisibilityByArrangerChangeableFactory: MemoizedSelectorWithProps<any, FormBaseFieldVisibilityProps, any> = createSelector(
    this.getFormBaseConfigurationItemsState,
    this.getFormBaseCurrentUserIsAuthorizedApprover,
    this.getFormBaseCurrentUserIsAuthorizedInspector,
    (s, currentUserIsAuthorizedApprover, currentUserIsAuthorizedInspector, { fieldName }: FormBaseFieldVisibilityProps) =>
      s?.[fieldName]?.visible && (
        (currentUserIsAuthorizedApprover && s?.[fieldName]?.changeableByApprover)
        || (currentUserIsAuthorizedInspector && s?.[fieldName]?.changeableByInspector)
      ),
  );

}

export const formBaseSelectors = new FormBaseSelectors();
