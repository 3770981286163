import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { QueryPromptMessageActionsModel } from '../../models/query-prompt-message-actions.model';
import { QueryPromptMessageReturnEventTypes } from '../../models/query-prompt-message-return-event-types.model';
import { QueryPromptMessageReturnModel } from '../../models/query-prompt-message-return.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-query-prompt-message-actions',
  templateUrl: './query-prompt-message-actions.component.html',
  styleUrls: ['./query-prompt-message-actions.component.scss']
})
export class QueryPromptMessageActionsComponent {
  title: string;
  text: string;
  message: string;
  replacements: { [key: string]: string | number };
  info: string;
  declineLabel: string;
  acceptLabel: string;

  eventTypes = QueryPromptMessageReturnEventTypes;
  textarea = false;

  constructor(public dialogRef: MatDialogRef<QueryPromptMessageActionsComponent, QueryPromptMessageReturnModel>,
              @Inject(MAT_DIALOG_DATA) public data: QueryPromptMessageActionsModel) {
    this.title = data.title || '';
    this.text = data.text || '';
    this.message = data.message || '';
    this.info = data.info || '';
    this.replacements = data.replacements || {};
    this.declineLabel = data.declineLabel || 'declineLabel';
    this.acceptLabel = data.acceptLabel || 'acceptLabel';
    this.textarea = data.textarea || false;
  }

  close(event: QueryPromptMessageReturnEventTypes): void {
    this.dialogRef.close({message: this.message, event: event});
  }
}
